import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQueryWithReauth } from "./baseQuery";

export const manageLibraryAPI = createApi({
  reducerPath: "manageLibraryAPI",
  baseQuery: baseQueryWithReauth,
  endpoints: (builder) => ({
    getLibraryTable: builder.query({
      query: (params) => {
        return {
          url: `api/product/products_catalogue/get_library_products/`,
          params,
        };
      },
    }),
    getLibraryExcel: builder.query({
      query: (params) => {
        return {
          url: `api/product/products_catalogue/download_products_excel/`,
          params,
        };
      },
    }),
    addNewFile: builder.mutation({
      query: ({ file, is_update }) => {
        const formData = new FormData();
        formData.append("file", file);
        if (is_update) {
          formData.append("is_update", is_update);
        }

        return {
          url: "api/product/products_catalogue/upload_product_listing/",
          method: "POST",
          body: formData,
        };
      },
    }),

    uploadImages: builder.mutation({
      query: (imageFile) => {
        const formData = new FormData();
        for (let i = 0; i <= imageFile.length - 1; i++) {
          formData.append("images", imageFile[i]);
        }
        return {
          url: "api/product/products_catalogue/upload_product_images/",
          method: "POST",
          body: formData,
        };
      },
    }),
    uploadImagesZip: builder.mutation({
      query: (imageFile) => {
        const formData = new FormData();
        for (let i = 0; i <= imageFile.length - 1; i++) {
          formData.append("file", imageFile[i]);
        }
        return {
          url: "/api/product/products_catalogue/upload_product_image/",
          method: "POST",
          body: formData,
        };
      },
    }),
    editProductImage: builder.mutation({
      query: (params) => {
        const formData = new FormData();
        formData.append("image", params.image);
        formData.append("product", params.product);
        return {
          url: "api/product/products_catalogue/upload_product_image/",
          method: "POST",
          body: formData,
        };
      },
    }),
    deleteFile: builder.mutation({
      query: (file) => {
        const formData = new FormData();

        formData.append("file", file);

        return {
          url: "api/product/products_catalogue/delete_products/",
          method: "DELETE",
          body: formData,
        };
      },
    }),
    activateTable: builder.mutation({
      query: (params) => ({
        url: `api/product/products_catalogue/${params.product_id}/update_product/`,
        method: "PATCH",
        body: params,
      }),
    }),
    deleteEditProductImage: builder.mutation({
      query: (imageId) => ({
        url: `api/product/products_catalogue/delete_product_image/`,
        method: "DELETE",
        body: imageId,
      }),
    }),
    getEditProductDetail: builder.query({
      query: (id) => ({
        url: `api/product/products_catalogue/${id}/get_product_details/`,
      }),
    }),
    updateProductDetails: builder.mutation({
      query: ({ id, data }) => ({
        url: `api/product/products_catalogue/${id}/update_product/`,
        method: "PATCH",
        body: data,
      }),
    }),
    deleteProductRow: builder.mutation({
      query: (params) => ({
        url: `api/product/products_catalogue/delete_product/`,
        method: "DELETE",
        body: params,
      }),
    }),
    getFlushCategories: builder.query({
      query: (params) => {
        return {
          url: `api/home/flush_categories/`,
          params,
        };
      },
    }),
  }),
});

export const {
  useLazyGetLibraryTableQuery,
  useLazyGetLibraryExcelQuery,
  useAddNewFileMutation,
  useUploadImagesMutation,
  useUploadImagesZipMutation,
  useEditProductImageMutation,
  useDeleteFileMutation,
  useActivateTableMutation,
  useDeleteEditProductImageMutation,
  useGetEditProductDetailQuery,
  useLazyGetEditProductDetailQuery,
  useUpdateProductDetailsMutation,
  useDeleteProductRowMutation,
  useLazyGetFlushCategoriesQuery,
} = manageLibraryAPI;

export default manageLibraryAPI;
