import React from "react";
import { Modal } from "antd";
// import { ReactComponent as BackIcon } from "../../../assets/icon/chevron-left.svg";

import ProjectsCreateModal from "./ProjectsCreateModal";

const AddNewProjectAddressModal = ({
  isMobile,
  IsModalVisible,
  setuserAddressDetails,
  userAddressDetails,
  handleUserAddressCreation,
  addressType,
  setAddressType,
  creatingAddress,
  handleCancel,
  setFile,
  setFileSize,
  addAddressFlag,
  SetaddAddressFlag,
  isSubmitClick,  
  cityDisabled,
  setcityDisabled,
  stateDisabled,
  setstateDisabled,
  footer,
  seller_id,
  file
}) => {
  return (
    <>
      <Modal
        title="Create New Project"
        visible={IsModalVisible}
        onCancel={handleCancel}
        footer={footer}
        className="editAddressInfoModalParent"
      >
        <ProjectsCreateModal
          setUserNewAddress={setuserAddressDetails}
          userNewAddress={userAddressDetails}
          addressType={addressType}
          setAddressType={setAddressType}
          // userStoredAddress={userStoredAddress}
          setFile={setFile}
          setFileSize={setFileSize}
          addAddressFlag={addAddressFlag}
          SetaddAddressFlag={SetaddAddressFlag}
          isSubmitClick={isSubmitClick}
          cityDisabled={cityDisabled}
          setcityDisabled={setcityDisabled}
          stateDisabled={stateDisabled}
          setstateDisabled={setstateDisabled}
          seller_id={seller_id}
          file={file}
        />
      </Modal>
    </>
  );
};

export default AddNewProjectAddressModal;
