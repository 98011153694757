import React, { useEffect, useState } from "react";
import "./ExistingOrders.css";
// import { RightOutlined } from "@ant-design/icons";
// import { useNavigate } from "react-router";
import { Button, Col, Row, Typography, Tag, Checkbox, Affix } from "antd";
import SearchInput from "../../components/Input/SearchInputs/SearchInput";
import "./ExistingOrders.css";
import TableSkeleton from "../../components/skeleton/TableSkeleton";
// import { useLazyGetExistingOrdertTableQuery } from "../../apis/ManageOrders";
import { useDispatch, useSelector } from "react-redux";
import { getmanageOrdersTableData } from "../../redux/slices/manageOrders/action";
import { manageOrdersDataSelector } from "../../redux/slices/manageOrders/selector";
// import { dateFormat } from "../../commonFuntions/CommonUtilFunctions";
import CustomSelect from "../../components/SelectableDropdown/CustomSelect/CustomSelect";
import CustomTableWithPagination from "../../components/CustomTableWithPagination/CustomTableWithPagination";
import UploadPaymentModel from "../../components/Modal/SettlementModal/UploadPaymentModel";
import {
  useCreateSettlementPaymentMutation,
  useLazyGetDownloadPdfMobPaymentQuery
  
} from "../../apis/settlement";
import {
  colorMapping,
  dateFormat,
 // handlePdfDownload,
} from "../../commonFuntions/CommonUtilFunctions";
// import { iteratee } from "lodash";
import {
  showErrorToast,
  showSuccessToast,
} from "../../NotificationToast/NotificationToast";
import AddCatalogueModal from "../../components/Modal/AddCatalogueModal/AddCatalogueModal";
import { saveAs } from "file-saver";

const { Text } = Typography;
const handleTableData = (data) => {
  let tableData = [];
  data?.results?.map((item) => {
    tableData.push({
      ...item,
      checkbox: false,
      // order_number: "898DRY57YUF",
      // date: "23 Jun 2022, 09:13am",
      // invoice_no: "897688",
      // status: "Awaiting",
      // revenue: 10200,
      // tax: 1500,
      // settlement_amount: 8200,
    });
    return null;
  });
  return tableData;
};

const MobOrders = (props) => {
  const { bmp_id, orderTableApi, orderData, isLoading } = props;
  const dispatch = useDispatch();
  // const navigate = useNavigate();
  const selectorData = useSelector(manageOrdersDataSelector);
  const [openUploadModal, setOpenUploadModal] = useState(false);
  const [searchInput, setSearchInput] = useState("");
  const [searchedValue, setSearchedValue] = useState({});
  const [isFixedBarVisible, setIsFixedBarVisible] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [catalogeModal, setCatalogeModal] = useState(false);

  const [
    getDownloadMobPdfApi,
    //  { data: mobPdfData }
  ] = useLazyGetDownloadPdfMobPaymentQuery();
  const [createPaymentApi] = useCreateSettlementPaymentMutation();
  const [tableDataSource, setTableDataSource] = useState([]);

  // useEffect(() => {
  //   orderTableApi({ bmp_id });
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);
  const handleCloseCatalogueModal = () => {
    setCatalogeModal(false);
  };
  const handleOpenCatalogueModal = () => {
    setCatalogeModal(true);
  };
  useEffect(() => {
    if (orderData?.status) {
      dispatch(getmanageOrdersTableData(orderData?.data));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderData]);

  useEffect(() => {
    if (selectorData) {
      const data = selectorData ? handleTableData(selectorData) : "";
      setTableDataSource(data);
    }
  }, [selectorData]);
  const handleSearchedValue = (params) => {
    setSearchedValue(params);
    setCurrentPage(1);
  };
  // const goToSellerPage = (record) => {
  //   navigate(`/admin/settlement`);
  // };
  const handleChange = async (value) => {
    const response = await getDownloadMobPdfApi({
      bmp_id: bmp_id,
      ...(searchInput && { search: searchInput }),
    });
    if(!response?.data?.status){
      showSuccessToast("No data to Download")
      return
    }
    //handlePdfDownload(response?.data?.data?.url, true);
    saveAs(response?.data?.data?.url);
  };

  const handlePageChange = async (currPage) => {
    const params = {
      page: currPage,
      bmp_id: bmp_id,
      ...(searchedValue && { search: searchedValue?.search }),
    };
    setCurrentPage(currPage);
    await orderTableApi({bmp_id,params});
  };

  const handleOk = () => {
    setOpenUploadModal(false);
  };

  // const rowSelection = {
  //   onChange: (selectedRowKeys, selectedRows) => {
  //   },
  //   getCheckboxProps: (record) => ({
  //     disabled: record.name === "Disabled User", // Column configuration not to be checked
  //     name: record.name,
  //   }),
  // };

  const downloadOptions = [
    {
      key: 1,
      value: "Excel",
      label: "Excel",
    },
  ];

  const handleSettled = async () => {
    function isAnyPendingAmountLessThanSelector(array, selectorAmount) {
      for (let obj of array) {
        if (obj && obj.settlement_amount > Math.abs(selectorAmount || 0)) {
          return true;
        }
      }
    }
    if (
      isAnyPendingAmountLessThanSelector(
        selectedRows,
        selectorData?.pending_amount
      )
    ) {
      handleOpenCatalogueModal();
    } else {
      let param = [];
      selectedRows?.map((item) => param.push(item?.settlement_id));
      let response = await createPaymentApi({ settlements: param });
      if (response?.data?.status) {
        showSuccessToast(response?.data?.message);
        setIsFixedBarVisible(false);
        setSelectedRows([]);
        orderTableApi({ bmp_id });
      } else {
        showErrorToast(response?.error?.data?.message);
      }
    }
  };
  const handleAllCheckbox = (e) => {
    const value = e.target.checked;
    let newTableDate = [...tableDataSource];
    newTableDate = newTableDate?.map((item, index) => {
      if (item.checked) {
        return { ...item, checkbox: value };
      } else {
        return item;
      }
    });
    let arr = newTableDate.filter((item) => item?.checked);
    setSelectedRows(value ? arr : []);
    setIsFixedBarVisible(value ? arr?.length > 0 : false);
    setTableDataSource(newTableDate);
  };
  const handleCheckboxChange = (e, record) => {
    const newSelectedRows = e.target.checked
      ? [...selectedRows, { ...record }]
      : selectedRows.filter(
          (item) => !(record.settlement_id === item.settlement_id)
        );
    let newTableDate = [...tableDataSource];
    newTableDate = newTableDate?.map((item, index) => {
      if (record.settlement_id === item.settlement_id) {
        return { ...item, checkbox: e.target.checked };
      } else {
        return item;
      }
    });
    setSelectedRows(newSelectedRows);
    setIsFixedBarVisible(newSelectedRows.length > 0);
    setTableDataSource(newTableDate);
  };
  const columns = [
    {
      width: "40px",
      fixed: "left",
      title: (
        <>
          <Checkbox onChange={(e) => handleAllCheckbox(e)} />
        </>
      ),
      dataIndex: "checkbox",
      render: (params, record) => {
        return (
          <>
            {record?.checked && (
              <Checkbox
                checked={record?.checkbox}
                onChange={(e) => handleCheckboxChange(e, record)}
              />
            )}
          </>
        );
      },
    },
    {
      width: "220px",
      title: "Seller order number",
      dataIndex: "sub_order",
      className: "table_text",
    },
    {
      width: "200px",
      title: "Settled on",
      dataIndex: "created_at",
      render: (params, record) => {
        return (
          <>
            <Text className="table_text">{params && dateFormat(params)}</Text>
          </>
        );
      },
    },
    {
      title: "Invoice no",
      width: "200px",
      dataIndex: "invoice_number",
      className: "table_text",
    },
    {
      // fixed: "left",
      title: "Payment status",
      width: "200px",
      dataIndex: "payment_status",
      render: (params, record) => {
        return (
          <Tag className={`tag-font-style ${colorMapping[params]}`}>
            {params}
          </Tag>
        );
      },
    },
    {
      title: "Revenue(Inc Taxes)",
      width: "200px",
      dataIndex: "revenue",
      render: (params, record) => {
        return <Text className="table_text">₹ {(params || 0).toFixed(2)}</Text>;
      },
    },
    {
      title: "Tax",
      width: "200px",
      dataIndex: "tax",
      render: (params, record) => {
        return <Text className="table_text">₹ {(params || 0).toFixed(2)}</Text>;
      },
    },
    {
      title: "Order status",
      width: "150px",
      dataIndex: "status",
      render: (params, record) => {
        return <Text className="table_text">{params || "N/A"}</Text>;
      },
    },
    {
      title: "Settlement amount",
      width: "200px",
      dataIndex: "settlement_amount",
      render: (params, record) => {
        return (
          <Col style={{ display: "flex", justifyContent: "space-around" }}>
            <Text style={{ color: "#01a685" }} className="table_text">
              ₹ {(params || 0).toFixed(2)}
            </Text>
            {/* <RightOutlined onClick={() => goToSellerPage(record)} /> */}
          </Col>
        );
      },
    },
  ];

  return (
    <>
      <Col>
        {selectorData?.pending_amount ? (
          <Row>
            <Col
              style={{
                backgroundColor: "#f8e6b6",
                width: "100%",
                padding: "24px",
                borderRadius: "8px",
              }}
              // className="settlement-amount-map"
            >
              <Col
                style={{
                  color: "#0a243f",
                  fontSize: "14px",
                  fontWeight: "normal",
                }}
              >
                Settled amount to be mapped against the orders
              </Col>
              <Col
                style={{ color: "#0a243f", fontSize: "18px", fontWeight: 500 }}
              >
                ₹ {selectorData?.pending_amount || "N/A"}
              </Col>
            </Col>
          </Row>
        ) : (
          <></>
        )}
        <Row className="action-container">
          <Col className="action-sub-container">
            <SearchInput
              size={"large"}
              placeholder={"Search by seller / BMP"}
              getLibraryApi={orderTableApi}
              width={"400px"}
              parentSetterState={setSearchInput}
              id={bmp_id}
              isLoading={isLoading}
              handleSearchedValue={handleSearchedValue}
            />
          </Col>
          <Col className="action-sub-container">
            <CustomSelect
              placeholder={"Download"}
              width={"150px"}
              height={"46px"}
              options={downloadOptions}
              handleChange={handleChange}
              value={"Download"}
            />
            {/* <Button
              size="large"
              style={{
                backgroundColor: "#0354a3",
                color: "#fff",
                fontWeight: 500,
                height: "48px",
              }}
              onClick={() => setOpenUploadModal(true)}
            >
              ADD PAYMENT
            </Button> */}
          </Col>
        </Row>
        <Col>
          <CustomTableWithPagination
            className="order-list-table"
            tableDataSource={tableDataSource || []}
            tableColumns={columns}
            locale={{
              emptyText: isLoading ? <TableSkeleton length={30} /> : "",
            }}
            scroll={{ x: "100%" }}
            currentPage={currentPage}
            handlePageChange={handlePageChange}
            itemsPerPage={20}
            totalEntries={
              !isLoading && orderData?.data?.pagination?.total_entries
            }
            isLoading={tableDataSource.length && isLoading}
            
          />
        </Col>
      </Col>
      <UploadPaymentModel
        open={openUploadModal}
        onCancleModel={setOpenUploadModal}
        handleOk={handleOk}
      />
      {isFixedBarVisible && (
        <Affix
          offsetBottom={10}
          offsetTop={10}
          style={{ position: "sticky", zIndex: 999 }}
        >
          <Col
            style={{
              width: "100%",
              height: "80px",
              backgroundColor: "#fff",
              display: "flex",
              padding: "0 40px",
              boxShadow: "0 1px 4px 0 rgba(0, 0, 0, 0.16)",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Col style={{}}>
              <Text
                style={{
                  width: "150px",
                  color: "#0a243f",
                  fontSize: "14px",
                  fontWeight: 500,
                  display: "block",
                }}
              >
                {selectedRows?.length} order selected
              </Text>
              <Text
                style={{
                  color: "#0a243f",
                  fontSize: "16px",
                  fontWeight: 500,
                  paddingTop: "2px",
                }}
              >
                ₹{" "}
                {(
                  selectedRows?.reduce(
                    (total, item, index) =>
                      total + (item?.settlement_amount || 0),
                    0
                  ) || 0
                ).toFixed(2)}
              </Text>
            </Col>
            <Button
              style={{
                color: "#fff",
                backgroundColor: "#0354a3",
                height: "44px",
                width: "180px",
              }}
              onClick={() => handleSettled()}
            >
              MARK AS SETTLED
            </Button>
          </Col>
        </Affix>
      )}
      <AddCatalogueModal
        data={selectorData?.settlement_amount}
        handleOk={handleCloseCatalogueModal}
        open={catalogeModal}
      />
    </>
  );
};

export default MobOrders;
