import React, { useState } from "react";
import { Button, Col, Input, Modal, Radio, Typography } from "antd";
import "./AddPaymentModal.css";
import CustomDropdown from "./CustomDropdown";
import {
  showErrorToast,
  showSuccessToast,
} from "../../../../../NotificationToast/NotificationToast";
import CustomDatePicker from "./DatePicker/CustomDatePicker";

const { Text } = Typography;

const paymentMode = [
  {
    key: "upi",
    value: "UPI",
  },
  {
    key: "cheque",
    value: "Cheque",
  },
  {
    key: "bankTransfer",
    value: "Bank transfer",
  },
  {
    key: "pg",
    value: "PG",
  },
];

const AddPaymentModal = ({
  openAddFile,
  setOpenAddFile,
  orderPaymentApi,
  orderId,
  totalAmount,
  pendingAmount,
  amountError,
  setAmountError,
  // remarksError,
  // setRemarksError,
  dateError,
  setDateError,
  isFetching,
  
  data,
}) => {
  const [radioValue, setRadioValue] = useState("Razorpay");
  const [datePikerEmpty, setDatePickerEmpty] = useState(false);

  const [paymentTypeSelected, setPaymentTypeSelected] =
    useState("Online payment");
  const [paymentModeSelected, setPaymentModeSelected] = useState("UPI");
  const [payTypeVisible, setPayTypeVisible] = useState(false);
  const [payModeVisible, setPayModeVisible] = useState(false);
  const [amountErrorValue, setAmountErrorValue] = useState(false);
  const initialFormData = {
    order: "",
    amount: "0",
    payment_date: "",
    remarks: "",
    payment_type: "Online payment",
    payment_mode: "UPI",
    payment_gateway: "Razorpay",
    reference_number: "",
  };
  const [paymentFormData, setPaymentFormData] = useState(initialFormData);
  const paymentType = [
    {
      key: "onlinePayment",
      value: "Online payment",
      description:
        "Link will be sent to customer’s email/SMS, once the payment is successful, we will proceed further.",
    },
    {
      key: "offlinePayment",
      value: "Offline payment",
      description:        
        "We accept,NEFT,RTGS,IMPS,Cheque only."
    },
    {
      key: "wallet",
      value: `Wallet (₹ ${data?.user_details?.wallet.toFixed(2)})`,
      description: "Note: This event not able to revert once accepted",
    },
    {
      key: "mob_credit",
      value: "Mob Credit",
      description: "",
    },
  ];
  const paymentTypeVisible = (flag) => {
    setPayTypeVisible(flag);
  };
  const paymentModeVisible = (flag) => {
    setPayModeVisible(flag);
  };
  const onChangeRadio = (e) => {    
    const key = e.target.name;
    const targetValue = e.target.value;
    setPaymentFormData({
      ...paymentFormData,
      [key]: targetValue,
    });
    setRadioValue(e.target.value);
  };
  const handlePaymentApi = async () => {    
    if (!paymentFormData.amount) {
      setAmountError(true);
      return;
    } else {
      setAmountError(false);
    }

    // if (!paymentFormData.remarks) {
    //   setRemarksError(true);
    //   return;
    // } else {
    //   setRemarksError(false);
    // }

    if (!paymentFormData.payment_date) {
      setDateError(true);
      return;
    } else {
      setDateError(false);
    }

    let wallet_string = paymentFormData.payment_type?.split(" ");
    if (
      wallet_string[0] === "Wallet" &&
      paymentFormData?.amount > data?.user_details?.wallet
    ) {
      showErrorToast("Insufficient funds in the wallet.");
      return;
    }
    const params = {
      order: orderId,
      amount: paymentFormData.amount,
      payment_date: paymentFormData.payment_date,
      remarks: paymentFormData.remarks,
     
      payment_type:
        wallet_string[0] === "Wallet" ? "Wallet" : paymentFormData.payment_type,
        payment_gateway:paymentFormData.payment_gateway
    };

    if (paymentFormData?.payment_type === "Online payment") {
      params.payment_gateway = paymentFormData?.payment_gateway;
    }
    else{ params.payment_gateway = ""}

    if (paymentFormData?.payment_type === "Offline payment") {
      params.reference_number = paymentFormData?.reference_number;
      params.payment_mode = paymentFormData?.payment_mode;
      params.payment_gateway ="";
    }  
    
    //console.log(params);
    const response = await orderPaymentApi(params);
    if (response?.data?.status) {
      showSuccessToast(response?.data?.message);
      setOpenAddFile(false);
      setRadioValue("Razorpay");
      setPaymentTypeSelected("Online payment");
      setPaymentModeSelected("UPI");
      setPaymentFormData(initialFormData);
      setDatePickerEmpty(true);
    } else if (response?.error) {
      showErrorToast(response?.error?.data?.message);
    }
  };
  const handleCancel = () => {
    setOpenAddFile(false);
    setPaymentFormData(initialFormData);
    setDatePickerEmpty(true);
    setRadioValue("Razorpay");
    setPaymentTypeSelected("Online payment");
    setPaymentModeSelected("UPI");
    setAmountErrorValue(false);
  };

  const handlePaidFull = () => {
    setPaymentFormData({
      ...paymentFormData,
      // amount: parseFloat(pendingAmount || 0),
      amount: parseFloat((pendingAmount || 0).toFixed(2)),
    });
    setAmountErrorValue(false);
  };
  const handlePayTypeClick = (selectedValue) => {
    setPaymentTypeSelected(selectedValue);
    setPaymentFormData({
      ...paymentFormData,
      payment_type: selectedValue,
    });
    setPayTypeVisible(false);
  };
  const handlePayModeClick = (selectedValue) => {
    setPaymentFormData({
      ...paymentFormData,
      payment_mode: selectedValue,
    });
    setPaymentModeSelected(selectedValue);
    setPayModeVisible(false);
  };
  const handleChildFieldChange = (e) => {
    setPaymentFormData({
      ...paymentFormData,
      ...e,
    });
  };
  const handleFormData = (e) => {    
    const key = e.target.name;
    const targetValue = e.target.value;
    if (key === "amount") {
      // const decimalPattern = /^\d+$/;
     // const decimalPattern = /^(?:\d+|\d+\.\d{0,2})$/;
     const decimalPattern = /^-?(?:\d+|\d+\.\d{0,2})$/;
      if (
        decimalPattern.test(targetValue)
        //  &&  targetValue <= parseInt((pendingAmount || 0) + 2)
        // targetValue <= Number((pendingAmount || 0).toFixed(2))
      ) {      
        if (Number(targetValue) <= (parseFloat(pendingAmount) || 0).toFixed(2)) {
          setPaymentFormData({
            ...paymentFormData,
            [key]: targetValue,
          });
        // setAmountErrorValue(true);
        } else {
          setPaymentFormData({
            ...paymentFormData,
            [key]: targetValue,
          });
          setAmountErrorValue(true);
        }
      } else {
      }
      if (targetValue === ""|| targetValue === "-") {
        setPaymentFormData({
          ...paymentFormData,
          [key]: targetValue,
        });
      }
      else{
        // setPaymentFormData({
        //   ...paymentFormData,
        //   [key]: targetValue,
        // });
      }
    } else {
      setPaymentFormData({
        ...paymentFormData,
        [key]: targetValue,
      });
    }
  };
  return (
    <>
      <Modal
        title="Add payment"
        open={openAddFile}
        className="add-payment-modal"
        onOk={handlePaymentApi}
        onCancel={handleCancel}
        footer={
          <Col className="addFile-wrapper">
            <Button key="back" className="cancel-btn" onClick={handleCancel}>
              CANCEL
            </Button>
            <Button
              key="submit"
              type="primary"
              className="submit-btn"
              onClick={handlePaymentApi}
              loading={isFetching}
            >
              ADD PAYMENT{" "}
            </Button>
          </Col>
        }
      >
        <Col className="pay-modal">
          <Col className="pay-amount">
            <Col className="pay-align-text">
              <Text className="amount-key">Total amount</Text>
              <Text style={{ fontSize: "16px" }}>{`₹ ${Number(
                totalAmount
              ).toFixed(2)}`}</Text>
            </Col>
            <Col className="pay-align-text">
              <Text className="amount-key">Payment pending</Text>
              <Text
                style={{
                  fontSize: "16px",
                  fontWeight: "bold",
                  color: "#bf2600",
                }}
              >
                {`₹ ${Number(pendingAmount).toFixed(2)}`}
              </Text>
            </Col>
          </Col>
          <Col className="pay-wrapper">
            <Col className="pay-input">
              <Col className="pay-lebel">
                <Text>Amount</Text>
                <Text
                  onClick={handlePaidFull}
                  style={{
                    fontWeight: 500,
                    color: "#2973f0",
                    cursor: "pointer",
                  }}
                >
                  Paid in full
                </Text>
              </Col>
              
              <Input
                placeholder="Enter amount to pay"
                className={`addPayment-input ${
                  amountError || amountErrorValue ? "error" : ""
                }`}
                name="amount"
                value={paymentFormData?.amount}
                onChange={handleFormData}
              
              />
              {amountError && (
                <p className="error-message">Amount is required</p>
              )}
              {amountErrorValue && (
                <p className="error-message">
                  {/* Amount should be less than {parseInt(pendingAmount || 0)} */}
                  Amount should be less than {(pendingAmount || 0).toFixed(2)}
                </p>
              )}
            </Col>
            <Col className="pay-date">
              <Col className="pay-date-picker">
                <Text>Payment Date</Text>
                <Col className="pay-cal">
                  <CustomDatePicker
                    name="payment_date"
                    value={paymentFormData?.payment_date}
                    handleChildFieldChange={handleChildFieldChange}
                    datePikerEmpty={datePikerEmpty}
                  />
                  {dateError && (
                    <p className="error-message">Payment Date is required</p>
                  )}
                </Col>
              </Col>
              <Col className="pay-date-picker">
                <Text>Remarks</Text>
                <Input
                  value={paymentFormData?.remarks}
                  name="remarks"
                  onChange={handleFormData}
                  className={`addPayment-input`}
                  // className={`addPayment-input ${remarksError ? "error" : ""}`}
                />
                {/* {remarksError && (
                  <p className="error-message">Remarks are required</p>
                )} */}
              </Col>
            </Col>
            <Col className="pay-type">
              <Text className="pay-type-text">Select payment type</Text>
              <Col className="dropdown-wrapper">
                <CustomDropdown
                  handleOptionClick={handlePayTypeClick}
                  visible={payTypeVisible}
                  handleVisibleChange={paymentTypeVisible}
                  selectedText={paymentTypeSelected}
                  options={paymentType}
                />
              </Col>
            </Col>
            {paymentTypeSelected === "Online payment" && (
              <Col className="pay-notify">
                <Text>
                  Link will be sent to customer’s email/SMS, once the payment is
                  successful, we will proceed further.
                </Text>
              </Col>
            )}
            {paymentTypeSelected === "Online payment" && (
              <Col className="pay-type">
                <Text className="pay-type-text">Select payment gateway</Text>
                <Radio.Group
                  onChange={onChangeRadio}
                  name="payment_gateway"
                  value={radioValue}
                >
                  <Radio value={"Razorpay"} className="style-radio">
                    Razorpay
                  </Radio>
                  <Radio value={"Rupifi"} className="style-radio">
                    Rupifi
                  </Radio>
                </Radio.Group>
              </Col>
            )}
            {paymentTypeSelected === "Offline payment" && (
              <Col className="pay-mode">
                <Col className="mode-type">
                  <Text>Payment mode</Text>
                  <Col className="dropdown-wrapper">
                    <CustomDropdown
                      handleOptionClick={handlePayModeClick}
                      visible={payModeVisible}
                      handleVisibleChange={paymentModeVisible}
                      selectedText={paymentModeSelected}
                      options={paymentMode}
                    />
                  </Col>
                </Col>
                <Col className="mode-type">
                  <Text>Reference no</Text>
                  <Input
                    value={paymentFormData?.reference_number}
                    name="reference_number"
                    onChange={handleFormData}
                    className="addPayment-input"
                  />
                </Col>
              </Col>
            )}
          </Col>
        </Col>
      </Modal>
    </>
  );
};

export default AddPaymentModal;
