import {
  Avatar,
  Button,
  Col,
  Dropdown,
  Menu,
  Skeleton,
  Typography,
  Form,
} from "antd";
import React, { useEffect, useState } from "react";
import { ArrowLeftOutlined } from "@ant-design/icons";
import "./ViewDetails.css";
// import DropdownComponent from "../../../../modules/Request/RFQ/DropdownComponent";
// import { EyeOutlined } from "@ant-design/icons";
// import { ReactComponent as SaveIcon } from "../../../../assets/icons/save.svg";
import { ReactComponent as FileIcon } from "../../../../assets/icons/fileI.svg";
// import { ReactComponent as ClockIcon } from "../../../../assets/icons/clock.svg";

import {
  //  PlusOutlined,
  DashOutlined,
} from "@ant-design/icons";
import StatusDropDown from "../OrderItems/StatusDropDown";
import { useNavigate, useParams } from "react-router-dom";
import NoVehicleAssigned from "./NoVehicleAssigned/NoVehicleAssigned";
import DeliveryTable from "./DeliveryTable/DeliveryTable";
import CustomerNote from "./CustomerNote/CustomerNote";
import AddVehicleModal from "./AddVehicleModal/AddVehicleModal";
import CustomProfileSection from "../../../../components/CustomContainer/CustomProFileSection/CustomProfileSection";
import SelectedVehicleTable from "./SelectedVehicleTable/SelectedVehicleTable";
import ChooseVehicleModal from "../OrderModals/ChooseVehicleModal/ChooseVehicleModal";
// import { UpArrow } from "../../../../components/Icons/UpArrow";
// import { DownArrow } from "../../../../components/Icons/DownArrow";
import { ReactComponent as DefaultIcon } from "../../../../assets/icons/default.svg";
import { ReactComponent as FastDelivery } from "../../../../assets/icons/fast-delivery.svg";
import {
  useAddVehicleMutation,
  useDeleteSuborderVehicleMutation,
  useLazyGetSubOrderDetailQuery,
  useLazyGetVehiclesDetailQuery,
  useLazyGetVendorAddressQuery,
  useUpdateOrderStatusMutation,
  useUpdateVehiclesDetailMutation,
} from "../../../../apis/ManageOrders";
import { useDispatch, useSelector } from "react-redux";
import {
  editSubOrderDetailSelector,
  // vehicleDetailSelector,
  // vehicleDetailSelector,
} from "../../../../redux/slices/manageOrders/selector";
import { getEditSubOrderDetail } from "../../../../redux/slices/manageOrders/action";
import {
  showErrorToast,
  showSuccessToast,
} from "../../../../NotificationToast/NotificationToast";
import { statusData } from "../OrderConstant/Constant";
// import {
//   ActivityContainer,
//   ActivityText,
// } from "../../../Request/RFQ/CreateQuote/CreateQuoteProfileSection/ProfileStyled";
import ActivityLogSidebar from "../../../Request/RFQ/CreateQuote/ActivityLogSidebar";
import ManageDeleteModal from "../../../../components/Modal/DeleteModal/ManageDeleteModal/ManageDeleteModal";
import { baseUrl } from "../../../../commonUtils/commonUtils";
import ConfirmationModal from "../OrderModals/ConfirmationModal/ConfirmationModal";
import { handlePdfDownload } from "../../../../commonFuntions/CommonUtilFunctions";
import DropdownComponent from "../OrderItems/DropdownComponent";
import { CancelOrderModal } from "./CancelOrderModal/CancelOrderModal";
import ServiceComponent from "../../../../components/ServiceComponent/ServiceComponent";
import ServiceRequestDrawer from "../../../../components/Drawer/ServiceRequestDrawer/ServiceRequestDrawer";
import {
  useAddCommentServiceRequestMutation,
  useAddServiceRequestMutation,
  useUpdateServiceRequestStatusMutation,
} from "../../../../apis/sellerCatalogue";
import ServiceRequestModal from "../../../../components/Modal/ServiceRequestModal/ServiceRequestModal";
import ViewServiceRequestModal from "../../../../components/Modal/ServiceRequestModal/ViewServiceRequestModal";
import ServiceRequestList from "../../../../components/ServiceComponent/ServiceRequestList";
import dayjs from "dayjs";

const { Text } = Typography;
const initialServiceRequestData = { issue_type: "",
   comments: "", images: [],reporting_manager : null };
const ViewDetails = () => {
  const [form] = Form.useForm();
  const { id } = useParams();
  const [addVehicleModalOpen, setAddVehicleModalOpen] = useState(false);
  const [updateVehicleModalOpen, setUpdateVehicleModalOpen] = useState(false);
  const editSubOrderData = useSelector(editSubOrderDetailSelector);
  const [deliveryStatus, setDeliveryStatus] = useState("");
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [ChooseVehicleModalOpen, setChooseVehicleModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [productTableData, setProductTableData] = useState({});
  const [vendorAddressData, setVendorAddressData] = useState([]);
  const [ConfirmationModalOpen, setConfirmationModalOpen] = useState(false);
  const [vehicleUpdateData, setVehicleUpdateData] = useState({});
  const [datePikerEmpty, setDatePickerEmpty] = useState(false);
  const [getVendorAddressApi, { isFetching }] = useLazyGetVendorAddressQuery();
  const [validationErrors, setValidationErrors] = useState({});
  const [pickupAddressValue, setPickupAddressValue] = useState({});
  const [openCancelModal, setOpenCancelModal] = useState(false);
  const [openServiceDrawer, setOpenServiceDrawer] = useState(false);
  const [serviceDrawerData, setServiceDrawerData] = useState({});
  const [openServiceModal, setOpenServiceModal] = useState(false);
  const [openViewServiceModal, setOpenViewServiceModal] = useState(false);
  const [serviceRequestForm, setServiceRequestForm] = useState(
    initialServiceRequestData
  );
  const [checkboxState, setCheckboxState] = useState({
    email: false,
    phone_number: false,
  });


useEffect(() => {

   const userName = localStorage.getItem('userName');
      const managers = editSubData?.data?.reporting_managers;

      if (managers) {
        const matchingManagers = managers.filter(manager =>
          manager.reporting_person.includes(userName)
        );
        if (matchingManagers.length > 0) {
          setServiceRequestForm((prevForm) => ({
            ...prevForm,
            reporting_manager: matchingManagers[0].id,
          }));
          console.log("Manager set 👨🏻‍🦳👩🏻‍🦳", serviceRequestForm);
        }
      }
// eslint-disable-next-line react-hooks/exhaustive-deps
}, [openServiceModal]);

  const [DeliverydateValue, setDeliveryDateValue] = useState("");
  const [selectedTime, setSelectedTime] = useState("Early morning 6am - 9am");

  const [getVehiclesDetailApi, { data: vehicleData }] =
    useLazyGetVehiclesDetailQuery();
  const [
    getSubOrderDetailApi,
    { data: editSubData, isFetching: isFetchingEditSubData },
  ] = useLazyGetSubOrderDetailQuery();
  const [addVehicleApi, { isSuccess: successAddVehicleApi }] =
    useAddVehicleMutation();

  const [updateVehicleApi] = useUpdateVehiclesDetailMutation();
  const [addVehicleFormData, setAddVehicleFormData] = useState({
    waybillno: "",
    customer_logistics_ref_no: "",
    assigned_date: "",
    assigned_time: "",
    driver_name: "",
    driver_phone_number: "",
    vechicle_number: "",
    sub_order: editSubData?.data?.id,
    vehicle: "",
    add_way_bill: "",
  });
  const [
    deleteSuborderVehicleApi,
    { isSuccess: successSuborderVehicleDelete },
  ] = useDeleteSuborderVehicleMutation();
  const [
    addServiceRequestAPI,
    {
      isLoading: isLoadingServiceRequest,
      isFetching: isFetchingServiceRequest,
      data: serviceRequestModalData,
      isSuccess: isSuccessServiceRequest,
    },
  ] = useAddServiceRequestMutation();
  const [
    updateServiceRequestStatusAPI,
    {
      // isLoading: isLoadingServiceRequestStatus,
      // data: serviceRequestStatusData,
      isSuccess: isSuccessServiceRequestStatus,
    },
  ] = useUpdateServiceRequestStatusMutation();
  const [
    addCommentServiceRequestAPI,
    {
      // isLoading: isLoadingAddCommentServiceRequest,
      // data: serviceRequestAddCommentData,
      isSuccess: isSuccessAddCommentServiceRequest,
    },
  ] = useAddCommentServiceRequestMutation();
  const [
    updateOrderStatusApi,
    { isLoading: updateOrderStatusLoading },
    //  { isSuccess: successUpdateOrderStatusApi }
  ] = useUpdateOrderStatusMutation();
  // const vehicleDetailData = useSelector(vehicleDetailSelector);
  const handleCloseCancelModal = () => {
    setOpenCancelModal(false);
    setCheckboxState({ email: false, phone_number: false });
  };
  const handleOpenCancelModal = () => {
    setOpenCancelModal(true);
  };

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const handleAddVehicleModal = () => {
    setValidationErrors({});
    setDatePickerEmpty(true);
    setVehicleUpdateData({});
    setAddVehicleModalOpen(true);
  };
  const handleDeleteModalOpen = () => {
    setIsDeleteModalOpen(true);
  };
  const handleDeleteModalCancel = () => {
    setIsDeleteModalOpen(false);
  };

  const handleChooseVehicle = async () => {
    const params = editSubOrderData?.vendor?.bmp_id;
    // const params = editSubData?.data?.items[0]?.product?.vendor;
    const response = await getVendorAddressApi({ bmp_id: params });

    setVendorAddressData(response?.data);

    setChooseVehicleModalOpen(true);
  };
  const _onRowClick = (data) => {
    setProductTableData(data);
  };


  const onDelete = async () => {
    const params = { vehicle_id: productTableData?.id };
    const response = await deleteSuborderVehicleApi(params);
    if (response?.data?.status) {
      showSuccessToast(response?.data?.message);
      setIsDeleteModalOpen(false);
      // libraryTableApi();
    } else {
      showErrorToast(response?.error?.data?.message);
    }
  };
  const onEdit = (data) => {
    setVehicleUpdateData(data);
    setUpdateVehicleModalOpen(true);
  };


  
  const [reportingManager, setReportingManager] = useState(null);

  const [serviceReportingManager, setServiceReportingManager] = useState(null);

  

//  const [updateReportingManager] = useUpdateReportingManagerMutation();

  const [open, setOpen] = useState(false);
  
  const handleMenuClick = async (e) => {
    const reportingManagers = editSubOrderData?.reporting_managers
    const selectedManager = reportingManagers.find((reportingManager) => {
      return Number(reportingManager.id) === Number(e.key);
    });

    setServiceReportingManager({
      "id" : selectedManager.id,"reporting_to" :
      selectedManager.reporting_to, 
      "reporting_person" : selectedManager.reporting_person})

      setServiceRequestForm((prevForm) => ({
        ...prevForm,
        reporting_manager: selectedManager.id ,
      }));
    
  }

  const handleOpenChange = (nextOpen, info) => {    
    setOpen(nextOpen);
  };



  const columns = [
    {
      title: (
        <>

        </>
      ),
      key: "icon",
      dataIndex: "icon",
      fixed: "left",
      width: "50px",
      render: (params, record) => {
        return (
          <>
            <Dropdown
              overlay={
                <Menu>
                  <>
                    <Menu.Item
                      onClick={() => onEdit(record)}
                      style={{ padding: "7px 12px" }}
                    >
                      <Col className="container-list">
                        <Text className="select-text">Edit</Text>
                      </Col>
                    </Menu.Item>

                    <Menu.Item
                      onClick={handleDeleteModalOpen}
                      style={{
                        padding: "7px 12px",
                      }}
                    >
                      <Col className="container-list">
                        <Text
                          className="select-text"
                          style={{ color: "#bf2600" }}
                        >
                          Delete
                        </Text>
                      </Col>
                    </Menu.Item>
                  </>
                </Menu>
              }
              trigger={"click"}
            >
              <DashOutlined onClick={() => _onRowClick(record)} />
            </Dropdown>
          </>
        );
      },
    },
    {
      title: "Vehicle type",
      dataIndex: "vehicle_type",
      key: "vehicle_type",

      render: (params, record) => {
        return (
          <>
            <Col
              style={{
                display: "flex",
                gap: "20px",
                alignItems: "center",
                paddingLeft: record.parentKey ? "60px" : "0px",
              }}
            >
              <Col>
                <Avatar
                  style={{
                    width: "48px",
                    height: "48px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    backgroundColor: "#f1f1f2",
                  }}
                  shape="square"
                  src={`${baseUrl}${record?.vehicle_info?.vechicle_image}`}
                  alt="Avatar"
                  icon={<DefaultIcon width={20} height={20} />}
                />
              </Col>
              <Col>
                <Col style={{ fontWeight: 500 }}>
                  {record?.vehicle_info?.vechicle_type || "No value"}
                </Col>
                <Col style={{ display: "flex", gap: "1rem" }}>
                  <Text style={{ color: "#6c7c8c" }}>{`Weight: ${
                    record?.vehicle_info?.vechicle_weight || "No value"
                  }`}</Text>
                  <Text style={{ color: "#6c7c8c" }}>{`storage: ${
                    record?.vehicle_info?.vechicle_storage || "No value"
                  }`}</Text>
                </Col>
              </Col>
            </Col>
          </>
        );
      },
    },

    {
      title: "Vehicle number",
      dataIndex: "vechicle_number",
      key: "vechicle_number",
    },
    {
      title: "Assigned time",
      dataIndex: "assigned_time",
      key: "assigned_time",
    },
    {
      title: "Driver name",
      dataIndex: "driver_name",
      key: "driver_name",
    },
    {
      title: "Driver mobile",
      dataIndex: "driver_phone_number",
      key: "driver_phone_number",
    },
    {
      title: "Logistic partner",
      dataIndex: "logistic_partner",
      key: "logistic_partner",
    },
  ];

  const columnsDeliveryBy = [
    {
      title: `${editSubOrderData?.items?.length} Items`,
      dataIndex: "product",
      render: (params, record) => {
        return (
          <>
            <Col className="deliveryBy-wrapper">
              <Col>
                <Avatar
                  size={88}
                  shape="square"
                  src={`${baseUrl}${params?.product_image}`}
                />
              </Col>
              <Col
                className="deliveryBy-items"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "12px",
                  maxWidth: "380px",
                }}
              >
                <Text style={{ fontSize: "16px", fontWeight: 500 }}>
                  {params?.product_name}
                </Text>
                {params?.mob_sku && (
                  <Text style={{ color: "#6c7c8c" }}>
                    MOBSKU: {params?.mob_sku}
                  </Text>
                )}
              </Col>
            </Col>
            {/* {deliveryStatus === "Delivered" && (
              <Col className="return-exchange-notify">
                <Col
                  className="status-pointer"
                  style={{ backgroundColor: "#4fb589" }}
                />
                <Text className="delivery-status-notify">
                  Return/ Exchange window open till 12 Jan 2022
                </Text>
              </Col>
            )} */}
          </>
        );
      },
    },
    {
      title: "Quantity",
      dataIndex: "quantity",
    },
    {
      title: "Amount",
      dataIndex: "total",
      render: (params, record) => {
        return <Col>{` ₹ ${Number(params).toFixed(2)}`}</Col>;
      },
    },
  ];
  const handleBack = () => {
    navigate(-1);
  };
  const handlePrintChallan = () => {
    handlePdfDownload(editSubData?.data?.challans[0].file);
  };
  const handleConfirmationModal = () => {
    setConfirmationModalOpen(true);
  };
  const handleChangeStatus = async (status, index) => {
    const response = await updateOrderStatusApi({
      id: id,
      params: {
        status,
        notify_email: checkboxState?.email,
        notify_phone: checkboxState?.phone_number,
        // delivery_date: dayjs(DeliverydateValue).format("YYYY-MM-DD"),
        // delivery_slot: selectedTime,
      },
    });
    if (response?.data?.status === true) {
      setDeliveryStatus(status);
      showSuccessToast(response?.data?.message);
      getSubOrderDetailApi(id);
      setConfirmationModalOpen(false);
      setChooseVehicleModalOpen(false);
    } else {
      showErrorToast(response?.error?.data?.message);
    }
  };
  const handleOrderAcceptStatus = async (status, index) => {
    if (DeliverydateValue === "") {
      showErrorToast("Select Delivery date and time");
      return;
    }
    if (selectedTime === "") {
      showErrorToast("Select Delivery slot");
      return;
    }
    const response = await updateOrderStatusApi({
      id: id,
      params: {
        status,
        notify_email: checkboxState?.email,
        notify_phone: checkboxState?.phone_number,
        delivery_date: dayjs(DeliverydateValue).format("YYYY-MM-DD"),
        delivery_slot: selectedTime,
      },
    });
    if (response?.data?.status === true) {
      setDeliveryStatus(status);
      showSuccessToast(response?.data?.message);
      getSubOrderDetailApi(id);
      setConfirmationModalOpen(false);
      setChooseVehicleModalOpen(false);
    } else {
      showErrorToast(response?.error?.data?.message);
    }
  };



  useEffect(() => {
    getSubOrderDetailApi(id);
    getVehiclesDetailApi();
   
// eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    successSuborderVehicleDelete,
    successAddVehicleApi,
    isSuccessServiceRequest,
    isSuccessServiceRequestStatus,
  ]);
  useEffect(() => {
    if (editSubData?.status) {
      console.log(editSubData, "ds,mhgkfdgjkf")
      dispatch(getEditSubOrderDetail(editSubData?.data));
      setDeliveryStatus(editSubData?.data?.status);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editSubData]);
  const handleCancelOrderModal = async (param) => {
    setOpenCancelModal(false);
    handleChangeStatus("Cancelled");
  };

  const handleSaveServiceRequest = async (data) => {
    const response = await addServiceRequestAPI(serviceRequestForm);
    if (response?.data?.status) {
      setOpenServiceModal(false);
      setOpenViewServiceModal(true);
      form.resetFields();
      setServiceRequestForm(() => ({
        ...initialServiceRequestData,
        comments: "",
        issue_type: "",
        suborder: data?.id,
        
      }));
      showSuccessToast(response?.data?.message);
    } else {
      showErrorToast(response?.error?.data?.message);
    }
  };
  const handleCancelViewServiceRequest = (data) => {
    setOpenViewServiceModal(false);
  };
  const handleOpenServiceRequestModal = (data) => {
    setOpenServiceModal(true);
    const userName = localStorage.getItem('userName');
    const managers = editSubOrderData?.reporting_managers
    const matchingManagers = managers.filter(manager =>
      manager.reporting_person.includes(userName)
    );
  
    console.log("ssssssssssss")
    if (matchingManagers.length > 0) {
      setServiceReportingManager(matchingManagers[0]);
      setServiceRequestForm((prevForm) => ({
        ...prevForm,
        reporting_manager: matchingManagers[0].id ,
      }));
    }      
    
  };
  const handleOpenServiceRequestDrawer = (data) => {
    setServiceDrawerData(data || serviceRequestModalData?.data);
    setOpenServiceDrawer(true);
    handleCancelViewServiceRequest();
  };
  return (
    <Col className="view-detail-wrapper">
      <Col className="view-detail-status">
        <Col className="detail-activity">
          <Button
            className="view-detail-btn"
            icon={<ArrowLeftOutlined />}
            onClick={handleBack}
          >
            BACK TO ORDER
          </Button>
          {/* <ActivityContainer onClick={showDrawer}>
            <ClockIcon style={{ height: "20px", width: "20px" }} />
            <ActivityText level={5}>Activity log</ActivityText>
          </ActivityContainer> */}
        </Col>
        {isFetchingEditSubData ? (
          <>
            <Skeleton active paragraph={{ rows: 2 }} />
          </>
        ) : (
          <>
            <Col className="detail-invoice-wrapper">
              <Col style={{ display: "flex", flexDirection: "column" }}>
                <Text className="detail-heading">
                  {editSubData?.data?.vendor?.vendor_name || "N/A"}
                </Text>
                <Text style={{ fontSize: "1rem" }}>
                  {`BMP ID: ${editSubData?.data?.vendor?.bmp_id}`}
                </Text>
              </Col>
              <Col className="comp-wrapper">
                <DropdownComponent
                  // title={"Invoice"}
                  data={editSubData?.data?.invoices}
                  // handlePdfDownload={handlePdfPreview}
                  title={"Invoice"}
                  // data={element?.invoices}
                  // deleteSuborderFileApi={deleteSuborderFileApi}
                  status={deliveryStatus}
                  // proformaInvoice={editSubData?.data?.proforma_invoices}
                />
                <DropdownComponent
                  // title={"Invoice"}
                  data={editSubData?.data?.proforma_invoices}
                  // handlePdfDownload={handlePdfPreview}
                  title={"Proforma Invoice"}
                  // data={element?.invoices}
                  // deleteSuborderFileApi={deleteSuborderFileApi}
                  status={deliveryStatus}
                  // proformaInvoice={editSubData?.data?.proforma_invoices}
                />
                {/* <Text className="seller-view">View order in seller view</Text> */}
              </Col>
            </Col>
            <Col className="detail-status">
              <Text
                style={{
                  fontSize: "1rem",
                }}
              >{`Sub ID: ${editSubData?.data?.sub_order_id}`}</Text>

              <StatusDropDown
                status={deliveryStatus}
                data={statusData}
                // api={updateOrderStatusApi}
                // index={index}
                handleAPI={handleChangeStatus}
              />

              {/* <StatusDropDown status={"Ready for Pickup"} data={statusData} /> */}
            </Col>
          </>
        )}
        <Col className="details-btn-wrapper">
          <Col className="comp-wrapper">
            {deliveryStatus === "Waiting" && (
              <Button
                className="detail-btn"
                style={{ color: "#bf2600", borderColor: "#bf2600" }}
                onClick={() => handleOpenCancelModal()}
              >
                CANCEL ORDER
              </Button>
            )}
            {deliveryStatus === "Delivered" && (
              <>
                <ServiceComponent
                  openData={""}
                  handleOpen={handleOpenServiceRequestModal}
                  requestNumberCheck={true}
                  requestNumber={editSubOrderData?.service_requests?.length}
                />
                <ServiceRequestModal
                  title={"Service request"}
                  isModalOpen={openServiceModal}
                  setIsModalOpen={setOpenServiceModal}
                  saveText={"RAISE SERVICE REQUEST"}
                  handleCancel
                  data={editSubData?.data}
                  handleSave={handleSaveServiceRequest}
                  serviceRequestForm={serviceRequestForm}
                  setServiceRequestForm={setServiceRequestForm}
                  initialValues={initialServiceRequestData}
                  form={form}
                  loading={isLoadingServiceRequest || isFetchingServiceRequest} 

                  setReportingManager = {setServiceReportingManager}
                  reportingManager={serviceReportingManager}
                  reportingManagers={editSubOrderData?.reporting_managers}
                  open={open}
                  handleMenuClick={handleMenuClick}
                  handleOpenChange={handleOpenChange}
                  setOpen = {setOpen}
                  
                />
                <ViewServiceRequestModal
                  open={openViewServiceModal}
                  handleOk={handleCancelViewServiceRequest}
                  data={serviceRequestModalData?.data}
                  handleOpenDrawer={handleOpenServiceRequestDrawer}
                />
              </>
            )}
            {/* <Button className="detail-btn">EDIT ORDER</Button> */}
          </Col>
          <Col className="comp-wrapper">
            {deliveryStatus !== "Waiting" && (
              <Button
                className="detail-btn"
                style={{
                  backgroundColor: "#01a685",
                  display: "flex",
                  alignItems: "center",
                  maxWidth: "360px",
                }}
                type="primary"
                icon={<FileIcon />}
                onClick={handlePrintChallan}
              >
                Print delivery challan (pdf)
              </Button>
            )}
            {deliveryStatus !== "Waiting" &&
              deliveryStatus !== "Delivered" &&
              deliveryStatus !== "Cancelled" && (
                <Button
                  className="detail-btn"
                  style={{ backgroundColor: "#0354a3" }}
                  type="primary"
                  onClick={handleConfirmationModal}
                >
                  {deliveryStatus === "Out for delivery"
                    ? "MARK DELIVERED"
                    : "ORDER SHIPPED"}
                </Button>
              )}
            {deliveryStatus === "Waiting" && (
              <Button
                className="detail-btn"
                style={{ backgroundColor: "#0354a3" }}
                type="primary"
                onClick={handleChooseVehicle}
                loading={isFetching}
              >
                ASSIGN VEHICLE TO ACCEPT
              </Button>
            )}
          </Col>
        </Col>
      </Col>
      {/* <Col className="user-profile"> */}
      <CustomProfileSection
        isLoading={isFetchingEditSubData}
        editCondition={editSubOrderData?.user_details?.full_name}
        data={editSubOrderData?.user_details}
        check={"viewDetail"}
      />
      {/* </Col> */}
      {deliveryStatus === "Ready for Pickup" && (
        <Col className="vehicle-notify">
          <Text className="detail-heading">Vehicle selected</Text>
          {editSubOrderData?.selected_vehicles?.length === 0 ? (
            <>
              <Col className="vehicle-text">
                Vehicle not assigned for delivery. Please update the vehicle
                details.
              </Col>
              <NoVehicleAssigned
                handleAddVehicleModal={handleAddVehicleModal}
              />
            </>
          ) : (
            <>
              <Col className="assigned-container">
                <Text className="assigned-heading">
                  These are the vehicle that has been assigned by MOB.
                </Text>
                <Button className="detail-btn" onClick={handleAddVehicleModal}>
                  ADD VEHICLE
                </Button>
              </Col>
            </>
          )}{" "}
        </Col>
      )}
      {deliveryStatus === "Ready for Pickup" && (
        <Col className="vehicle-notify">
          <SelectedVehicleTable
            className="mob-list-table"
            tableDataSource={editSubOrderData?.selected_vehicles}
            tableColumns={columns}
            pagination={false}
          />
        </Col>
      )}
      {deliveryStatus === "Delivered" && (
        <>
          <Col>
            <Col style={{ display: "flex", gap: "15px", alignItems: "center" }}>
              <Text className="detail-heading">Service requests </Text>
              <Text>
                {editSubOrderData?.service_requests?.length || "0"} items
              </Text>
            </Col>
            <ServiceRequestList
              data={editSubOrderData?.service_requests}
              order_id = {editSubOrderData?.order_id}
              reporting_managers = {editSubOrderData?.reporting_managers}
              reporting_manager = {editSubOrderData?.reporting_manager}
              
              handleOpenView={handleOpenServiceRequestDrawer}
            />
          </Col>
        </>
      )}
      {isFetchingEditSubData ? (
        <>
          <Skeleton active paragraph={{ rows: 8 }} />
        </>
      ) : (
        <>
          <Col className="vehicle-notify">
            <Text className="detail-heading">
              Delivery by:{" "}
          
              {editSubOrderData?.delivery_date && dayjs(editSubOrderData?.delivery_date).format("DD MMM YYYY") &&
              editSubOrderData?.delivery_slot
                ? `${dayjs(editSubOrderData?.delivery_date).format(
                    "DD MMM YYYY"
                  )}, ${editSubOrderData?.delivery_slot}`
                : "No value"}
                
               {(deliveryStatus !== "Waiting"  && deliveryStatus !== "Delivered" && deliveryStatus !== "Cancelled" ) && (
              editSubOrderData?.is_delayed && (
                <Text>
                  <Text style={{ color: "#bf2600", fontweight: 600 }}>
                    <FastDelivery
                      style={{
                        paddingTop: "5px",
                        width: "20px",
                        height: "15px",
                        marginLeft: "10px",
                      }}
                    />{" "}
                    Delayed {editSubOrderData?.is_delayed}
                  </Text>
                </Text>
              )
            )}


            </Text>
            <DeliveryTable
              data={editSubOrderData?.items}
              columns={columnsDeliveryBy}
              deliveryStatus={deliveryStatus}
              editSubOrderData={editSubOrderData}
            />
            <Col style={{ display: "flex", justifyContent: "flex-end" }}>
              {deliveryStatus !== "Waiting" && (
                <Button
                  className="detail-btn"
                  style={{
                    backgroundColor: "#01a685",
                    display: "flex",
                    alignItems: "center",
                    maxWidth: "360px",
                  }}
                  type="primary"
                  icon={<FileIcon />}
                  onClick={handlePrintChallan}
                >
                  Print delivery challan (pdf)
                </Button>
              )}
            </Col>
          </Col>
        </>
      )}
      {(editSubOrderData?.quote_delivery_notes || editSubOrderData?.notes) && (
        <>
          <Col className="vehicle-notify">
            <Text className="detail-heading">Customer note</Text>
            <CustomerNote editSubOrderData={editSubOrderData} />
          </Col>
        </>
      )}
      <AddVehicleModal
        setIsModalOpen={setAddVehicleModalOpen}
        isModalOpen={addVehicleModalOpen}
        title="Add vehicle"
        vehicleDetailData={vehicleData?.data}
        addVehicleFormData={addVehicleFormData}
        setAddVehicleFormData={setAddVehicleFormData}
        editSubData={editSubData}
        addVehicleApi={addVehicleApi}
        datePikerEmpty={datePikerEmpty}
        validationErrors={validationErrors}
        setValidationErrors={setValidationErrors}
      />
      <AddVehicleModal
        setIsModalOpen={setUpdateVehicleModalOpen}
        isModalOpen={updateVehicleModalOpen}
        title="Update vehicle"
        vehicleDetailData={vehicleData?.data}
        addVehicleFormData={addVehicleFormData}
        setAddVehicleFormData={setAddVehicleFormData}
        editSubData={editSubData}
        addVehicleApi={updateVehicleApi}
        vehicleUpdateData={vehicleUpdateData}
        datePikerEmpty={datePikerEmpty}
        validationErrors={validationErrors}
        setValidationErrors={setValidationErrors}
      />
      <ChooseVehicleModal
        handleOk={() => handleOrderAcceptStatus("Ready for Pickup")}
        setIsModalOpen={setChooseVehicleModalOpen}
        isModalOpen={ChooseVehicleModalOpen}
        vendorAddressData={vendorAddressData}
        pickupAddressValue={pickupAddressValue}
        vendorAddressapiSuccess={isFetching}
        setPickupAddressValue={setPickupAddressValue}
        updateOrderStatusLoading={updateOrderStatusLoading}
        DeliveryDateValue={DeliverydateValue}
        SelectedTime={selectedTime}
        setDeliveryDateValue={setDeliveryDateValue}
        setSelectedTime={setSelectedTime}
      />
      <ActivityLogSidebar
        drawerVisible={drawerVisible}
        setDrawerVisible={setDrawerVisible}
      />
      <ManageDeleteModal
        handleOk={handleDeleteModalCancel}
        open={isDeleteModalOpen}
        handleSave={onDelete}
        data={`this vehicle ${productTableData?.vechicle_number}`}
      />
      <ConfirmationModal
        title={
          deliveryStatus === "Out for delivery"
            ? "Mark Delivered"
            : "Order Shipped"
        }
        updateOrderStatusLoading={updateOrderStatusLoading}
        subId={editSubData?.data?.sub_order_id}
        isModalOpen={ConfirmationModalOpen}
        setIsModalOpen={setConfirmationModalOpen}
        handleOk={() =>
          handleChangeStatus(
            deliveryStatus === "Out for delivery"
              ? "Delivered"
              : "Out for delivery"
          )
        }
      />
      <CancelOrderModal
        // title={"Are you sure?"}
        title={"Are you sure you want to cancel this order?"}
        cancelText={"NO, GO BACK"}
        saveText={"YES, CANCEL ORDER"}
        open={openCancelModal}
        handleCloseModal={handleCloseCancelModal}
        handleCancel={handleCloseCancelModal}
        handleSave={handleCancelOrderModal}
        data={editSubData?.data}
        checkboxState={checkboxState}
        setCheckboxState={setCheckboxState}
      />

      <ServiceRequestDrawer
        drawerVisible={openServiceDrawer}
        setDrawerVisible={setOpenServiceDrawer}
        data={serviceDrawerData}
        updateStatusApi={updateServiceRequestStatusAPI}
        addCommentApi={addCommentServiceRequestAPI}
        isSuccessAddCommentServiceRequest={isSuccessAddCommentServiceRequest}
        setReportingManager = {setReportingManager}
        reportingManager={reportingManager}
        reportingManagers={editSubOrderData?.reporting_managers}
        open={open}
        handleMenuClick={handleMenuClick}
        handleOpenChange={handleOpenChange}
        setOpen = {setOpen}
      />
    </Col>
  );
};

export default ViewDetails;
