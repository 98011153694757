import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQueryWithReauth } from "./baseQuery";

export const updateRfqAPI = createApi({
  reducerPath: "updateRfqAPI",
  baseQuery: baseQueryWithReauth,
  endpoints: (builder) => ({
    publishQuoteById: builder.mutation({
      query: (status) => ({
        url: "/api/orders/rfq/update_quote/",
        method: "PATCH",
        body: status,
      }),
    }),
  }),
});

export const { updateRfqAPIMutation } = updateRfqAPI;

export default updateRfqAPI;
