import React from "react";
import { Button, Col, Modal, Typography ,message} from "antd";
import "./UpdateRedundantModal.css";

// // import { ReactComponent as LostIcon } from "../../../assets/icons/lost.svg" ;
// import LostIcon  from "../../../assets/icons/lost.svg" ;
import { ReactComponent as LostIcon } from "../../../assets/icons/lost.svg";

const { Text } = Typography;


const UpdateRedundantModal = (props) => {
  const { isModalOpen, setIsModalOpen, handleSaveApi } = props;

  const handleSave = async () => {
    try {
      await handleSaveApi();
     
      setIsModalOpen(false); // Close the modal after successful save
    } catch (error) {
     message.error("Failed to update RFQ status.");
    }
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <Modal
        onCancel={handleCancel}
        title={"You Want to Update this RFQ status?"}
        // closeIcon={null}
        // open={true}
        onOk={handleCancel}
        open={isModalOpen}
        style={{ color: "#0a243f", padding: "0px" }}
        footer={[
          <Col
            style={{
              display: "flex",
              justifyContent: "center",
              borderTop: "1px solid #dedede",
              borderRadius: "0px 0px 8px 8px",
              padding: "20px 0px",
              boxShadow: "0 -6px 10px 0 rgba(0, 0, 0, 0.1)",
              backgroundColor: "#fff",
              width: "100%",
            }}
          >
            <Button
              onClick={handleCancel}
              style={{
                height: "48px",
                fontWeight: 500,
                fontSize: "14px",
                maxWidth: "120px",
                width: "100%",
              }}
            >
              No
            </Button>
            <Button
            //   loading={loading}
            //   disabled={loading}
              onClick={handleSave}
              style={{
                height: "48px",
                maxWidth: "200px",
                width: "100%",
                fontWeight: 500,
                fontSize: "14px",
                color: "white",
                backgroundColor: "#f0483e",
                border: "none",
              }}
            >
              YES, CHANGE STATUS
            </Button>
          </Col>,
        ]}
      >
        <Col
          style={{
            display: "flex",
            height: "350px",
            alignItems: "center",
            padding: "20px",
          }}
        >
          <Col
            style={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
            }}
          >
        
            <Text
              style={{
                color: "#0a243f",
                marginBottom: "50px",
                fontWeight: 500,
                fontSize: "20px",
              }}
            >
              Are you sure you want to change the status to redundant/ lost?
            </Text>
           
            <Text
              style={{
                color: "#0a243f",
                fontWeight: 500,
                fontSize: "15px",
              }}
            >
            <div style={{textAlign:"center"}}>

              
            <LostIcon  style={{height:120,marginBottom:40}}  />
            </div>

             <p style={{marginBottom:20}}>
             The customer status will not change and they will not be notified.
              </p> 
            </Text>
          </Col>
          
        </Col>
       
      </Modal>
    </>
  );
};

export default UpdateRedundantModal;
