import React, { useEffect, useState } from "react";
import "./OrderItem.css";
import { Button, Col, Typography, Form } from "antd";
import DropdownComponent from "./DropdownComponent";
import dayjs from "dayjs";
// import { ReactComponent as SaveIcon } from "../../../../assets/icons/save.svg";
// import { ReactComponent as DeleteIcon } from "../../../../assets/icons/redDelete.svg";
import { ReactComponent as CheckIcon } from "../../../../assets/icons/Check.svg";
// import { EyeOutlined } from "@ant-design/icons";
import StatusDropDown from "./StatusDropDown";
import AddFileDragger from "../OrderModals/AddFileDragger/AddFileDragger";
import {
  showErrorToast,
  showSuccessToast,
} from "../../../../NotificationToast/NotificationToast";
import { useNavigate } from "react-router-dom";

import { TimeDropdownData, statusData } from "../OrderConstant/Constant";
import ConfirmationModal from "../OrderModals/ConfirmationModal/ConfirmationModal";
import {
  useAddCommentServiceRequestMutation,
  useAddOrderFileMutation,
  useAddServiceRequestMutation,
  useUpdateServiceRequestStatusMutation,
} from "../../../../apis/sellerCatalogue";
// import dayjs from "dayjs";
// import {
//   //  dateFormat,
//   onlyDateFormat,
// } from "../../../../commonFuntions/CommonUtilFunctions";
import ServiceComponent from "../../../../components/ServiceComponent/ServiceComponent";
import ServiceRequestModal from "../../../../components/Modal/ServiceRequestModal/ServiceRequestModal";
import ViewServiceRequestModal from "../../../../components/Modal/ServiceRequestModal/ViewServiceRequestModal";
import ServiceRequestDrawer from "../../../../components/Drawer/ServiceRequestDrawer/ServiceRequestDrawer";
import { ReactComponent as FastDelivery } from "../../../../assets/icons/fast-delivery.svg";
import { ReactComponent as EditIcon } from "../../../../assets/icons/Edit.svg";
import { onlyDateFormat } from "../../../../commonFuntions/CommonUtilFunctions";
import DeliverySlot from "../OrderModals/DeliverySlotModal/DeliverySlot";

const { Text } = Typography;
// const invoiceData = [
//   {
//     title: "Invoice",
//     subTitle: "OD-0723-BENG-1-1480-01 10-Jul-2023onlyDateFormat 04:34 PM",
//     icon: <SaveIcon />,
//     key: 0,
//     iconI: <EyeOutlined />,
//   },

//   {
//     title: "Proforma invoice",
//     subTitle: "0D-0723-BENG-1-1480-01 10-Jul-2023 03:34 PM",
//     icon: <SaveIcon />,
//     key: 1,
//     iconI: <EyeOutlined />,
//   },
// ];

// const fileData = [
//   {
//     title: "Steel weight receipt",
//     icon: <SaveIcon />,
//     key: 0,
//     iconI: <EyeOutlined />,
//     iconII: <DeleteIcon />,
//   },
//   {
//     title: "Challan 1",
//     icon: <SaveIcon />,
//     key: 1,
//     iconI: <EyeOutlined />,
//     iconII: <DeleteIcon />,
//   },
// ];
const initialServiceRequestData = { issue_type: "", comments: "", images: [] };
const OrderItem = (props) => {
  const {
    itemList,
    updateOrderStatusApi,
    handleAddVehicleModal,
    handleRefetchApi,
    deleteSuborderFileApi,
    updateOrderStatusLoading,
    reporting_managers
  } = props;



  const [DeliverydateValue, setDeliveryDateValue] = useState("");
  const [selectedTime, setSelectedTime] = useState("Early morning 6am - 9am");
  const [form] = Form.useForm();
  const [ConfirmationModalOpen, setConfirmationModalOpen] = useState(false);
  // const [DeliveryUpdateModel, setDeliveryUpdateModel] = useState(false);
  const [subOrderData, setSubOrderData] = useState({});
  const [openAddFile, setOpenAddFile] = useState(false);
  const [openServiceModal, setOpenServiceModal] = useState(false);
  console.log(openServiceModal)
  const [openViewServiceModal, setOpenViewServiceModal] = useState(false);
  const [openServiceDrawer, setOpenServiceDrawer] = useState(false);
  const [serviceDrawerData, setServiceDrawerData] = useState({});
  const [DeliveryComments, setDeliveryComments] = useState("");
  const [DeliverySubOrderId, setDeliverySubOrderId] = useState("");
  const [addOrderFileAPI, { isLoading: isFetching }] =
    useAddOrderFileMutation();
  const [
    addServiceRequestAPI,
    {
      isLoading: isLoadingServiceRequest,
      isFetching: isFetchingServiceRequest,
      data: serviceRequestData,
    },
  ] = useAddServiceRequestMutation();
  const [
    updateServiceRequestStatusAPI,
    // {
    // isLoading: isLoadingServiceRequestStatus,
    // data: serviceRequestStatusData,
    // isSuccess: isSuccessServiceRequestStatus,
    // },
  ] = useUpdateServiceRequestStatusMutation();
  const [
    addCommentServiceRequestAPI,
    {
      // isLoading: isLoadingAddCommentServiceRequest,
      // data: serviceRequestAddCommentData,
      isSuccess: isSuccessAddCommentServiceRequest,
    },
  ] = useAddCommentServiceRequestMutation();
  const [serviceRequestForm, setServiceRequestForm] = useState(
    initialServiceRequestData
  );
  const [serviceReportingManager, setServiceReportingManager] = useState(null);

  useEffect(()=>{

    setServiceRequestForm((prevForm) => ({
      ...prevForm,
      reporting_manager: serviceReportingManager?.id ,
    }));

  }, [openServiceModal,serviceReportingManager?.id])

  useEffect(() => {
    const userName = localStorage.getItem('userName');
    const managers = reporting_managers
    console.log(reporting_managers)
    if(managers){
      const matchingManagers = managers.filter(manager =>
        manager.reporting_person.includes(userName)
      )
      if (matchingManagers.length > 0) {
        setServiceReportingManager(matchingManagers[0]);
      } 
    } 
  },[reporting_managers])

  const handleMenuClick = (e) => {
    const selectedManager = reporting_managers.find((reportingManager) => {
      return Number(reportingManager.id) === Number(e.key);
    });
    setServiceReportingManager({"id" : selectedManager.id,"reporting_to" :
      selectedManager.reporting_to, "reporting_person" : selectedManager.reporting_person})
      setServiceRequestForm((prevForm) => ({
        ...prevForm,
        reporting_manager: selectedManager.id ,
      }));
    
    }

  const [modalStates, setModalStates] = useState([
    { isOpen: false },
    { isOpen: false },
  ]);
  const [serviceRequestModalStates, setserviceRequestModalState] = useState([
    { isOpen: false },
    { isOpen: false },
  ]);


  const handleChangeStatus = async (status, index) => {
    const response = await updateOrderStatusApi({
      id: index,
      params: { status },
    });
    if (response?.data?.status === true) {
      showSuccessToast(response?.data?.message);
      setConfirmationModalOpen(false);
    } else {
      showErrorToast(response?.error?.data?.message);
      setConfirmationModalOpen(false);
    }
  };

  const updateDeliveryDate = async (status, index) => {
    if (DeliverydateValue === "") {
      showErrorToast("Select Delivery date and time");
      return;
    }
    if (selectedTime === "") {
      showErrorToast("Select Delivery slot");
      return;
    }
    if (DeliveryComments === "") {
      showErrorToast("Enter comments");
      return;
    }
    const response = await updateOrderStatusApi({
      id: index,
      params: {
        // status,
        // notify_email: checkboxState?.email,
        // notify_phone: checkboxState?.phone_number,
        customer_note: DeliveryComments,
        delivery_date: dayjs(DeliverydateValue).format("YYYY-MM-DD"),
        delivery_slot: selectedTime,
      },
    });
    if (response?.data?.status === true) {
      showSuccessToast(response?.data?.message);
      setConfirmationModalOpen(false);
    } else {
      showErrorToast(response?.error?.data?.message);
      setConfirmationModalOpen(false);
    }
  };
  const navigate = useNavigate();

  const handleAddFile = (data) => {
    setSubOrderData(data);
    setOpenAddFile(true);
  };
  const handleViewDetals = (id, status) => {
    navigate(`/admin/orders/view-details/${id}`, { state: { id, status } });
  };
  const handleConfirmationModal = () => {
    setConfirmationModalOpen(true);
  };

  const handleUploadFile = async (inputValue, fileUrl) => {
    const response = await addOrderFileAPI({
      file: fileUrl,
      sub_order: subOrderData?.id,
      file_name: inputValue,
    });
    if (response?.data?.status === true) {
      showSuccessToast(response?.data?.message);
      setOpenAddFile(false);
      handleRefetchApi();
    } else {
      showErrorToast(response?.error?.data?.message);
    }
  };
  const handlerandom = () => {};
  const handleOpenServiceRequestModal = (data, index) => {    
    setOpenServiceModal(true);
    setserviceRequestModalState((prevStates) => ({
      ...prevStates,
      [index]: { isOpen: true },
    }));
  };

  const handleSaveServiceRequest = async (data) => {
    const response = await addServiceRequestAPI(serviceRequestForm);
    if (response?.data?.status) {
      setOpenServiceModal(false);
      setOpenViewServiceModal(true);
      form.resetFields();
      setServiceRequestForm(() => ({
        ...initialServiceRequestData,
        comments: "",
        issue_type: "",
        suborder: data?.id,

      }));
      showSuccessToast(response?.data?.message);
    } else {
      showErrorToast(response?.error?.data?.message);
    }
  };
  
  const handleCancelViewServiceRequest = (data) => {
    setOpenViewServiceModal(false);
  };
  const handleOpenServiceRequestDrawer = (data) => {
    setServiceDrawerData(data || serviceRequestData?.data);
    setOpenServiceDrawer(true);
    handleCancelViewServiceRequest();
  };
  const handleDeliveryDate = (data, index) => {
    setDeliverySubOrderId(data);
    // setDeliveryUpdateModel(true);
    setModalStates((prevStates) => ({
      ...prevStates,
      [index]: { isOpen: true },
    }));
  };


  return (
    <>
      {itemList?.map((element, index) => {
        return (
          <>
            <Col className="item-wrapper" key={index}>
              <Col className="item-header">
                <Text className="item-title">{element?.vendor_name}</Text>
                <Col className="flex-btn">
                  {element?.status === "Delivered" && (
                    <DropdownComponent
                      title={"Invoice"}
                      data={element?.invoices}
                      deleteSuborderFileApi={deleteSuborderFileApi}
                      status={element?.status}
                      // proformaInvoice={element?.proforma_invoices}
                    />
                  )}
                  <DropdownComponent
                    title={"Proforma Invoice"}
                    data={element?.proforma_invoices}
                    deleteSuborderFileApi={deleteSuborderFileApi}
                    status={element?.status}
                    // proformaInvoice={element?.proforma_invoices}
                  />
                  <DropdownComponent
                    title={"files attached"}
                    data={element?.suborder_files}
                    _onClick={handleAddFile}
                    subOrderData={element}
                    deleteSuborderFileApi={deleteSuborderFileApi}
                  />
                </Col>
              </Col>
              <Col className="item-status">
                <Text>Sub ID: {element?.suborder_id}</Text>
                <StatusDropDown
                  status={element?.status}
                  data={statusData}
                  // api={updateOrderStatusApi}
                  index={element?.id}
                  handleAPI={handleChangeStatus}
                  key={element?.id}
                />
                {element?.status !== "Waiting" &&
                  element?.status !== "Delivered" &&
                  element?.status !== "Cancelled" &&
                  (element?.is_delayed ||
                    !element?.status === "Delivered" ||
                    !element?.status === "Cancelled") && (
                    <Text>
                      <Text style={{ color: "#bf2600", fontweight: 600 }}>
                        <FastDelivery
                          style={{
                            paddingTop: "5px",
                            width: "20px",
                            height: "15px",
                          }}
                        />{" "}
                        Delayed {element?.is_delayed}
                      </Text>
                    </Text>
                  )}
              </Col>
              <Col className="item-data">
                <Col className="item-subData">
                  <Col className="key-val">
                    <Text className="item-key">Items:</Text>
                    <Text className="item-val">{element?.item}</Text>
                  </Col>
                  <Col className="key-val">
                    <Text className="item-key">Total:</Text>{" "}
                    <Text className="item-val">{`₹ ${Number(
                      element?.total
                    ).toFixed(2)}`}</Text>
                  </Col>
              
                  {element?.delivery_date && element?.status !== "Waiting" && (
                    <Col className="key-val">
                      <Text className="item-key">Delivery by: </Text>
                      <Text className="item-val">
                        {element?.status !== "Delivered" ? (
                          <Text
                            className="pay-tII"
                            onClick={() =>
                              handleDeliveryDate(element?.id, index)
                            }
                          >
                            {onlyDateFormat(element?.delivery_date)}
                            {", "}
                            {element?.delivery_slot &&
                              TimeDropdownData.find(
                                (item) => item.value === element.delivery_slot
                              )?.label}{" "}
                            <EditIcon
                              style={{ width: "12px", height: "12px" }}
                            />
                          </Text>
                        ) : (
                          <>
                            <Text style={{ fontSize: "16px" }}>
                              {onlyDateFormat(element?.delivery_date)}
                              {", "}
                              {element?.delivery_slot &&
                                element?.delivery_slot}{" "}
                            </Text>
                          </>
                        )}
                      </Text>
                    </Col>
                  )}
                  {/* {element?.delivery_slot && (
                    <Col className="key-val">
                      <Text className="item-key">Delivery time:</Text>
                      <Text className="item-val">{element?.delivery_slot}</Text>
                    </Col>
                  )} */}
                  {element?.status === "Delivered" && (
                    <Col className="key-val">
                      <ServiceComponent
                        requestNumberCheck={true}
                        requestNumber={element?.service_request_count}
                        openData={""}
                        handleOpen={handleOpenServiceRequestModal}
                        index={index}
                      />
                    </Col>
                  )}
                </Col>
                <Col className="item-button">
                  {(element?.status === "Ready for Pickup" ||
                    element?.status === "Out for delivery" ||
                    element?.status === "Delivered" ||
                    element?.status === "Cancelled") && (
                    <Button
                      className="btnI"
                      onClick={() =>
                        handleViewDetals(element?.id, element?.status)
                      }
                    >
                      VIEW DETAILS
                    </Button>
                  )}
                  {element?.status === "Ready for Pickup" && (
                    <>
                      <Button
                        className="btnII"
                        onClick={handleConfirmationModal}
                      >
                        ORDER SHIPPED
                      </Button>
                    </>
                  )}
                  {element?.status === "Waiting" && (
                    <Button
                      type="primary"
                      className="btnII"
                      onClick={() => {
                        element?.status === "Waiting"
                          ? handleViewDetals(element?.id, element?.status)
                          : handlerandom();
                      }}
                    >
                      VIEW TO ACCEPT
                    </Button>
                  )}
                </Col>
              </Col>
              {element?.status === "Ready for Pickup" &&
                !element?.vehicle_assigned && (
                  <Col className="item-notify">
                    Vehicle not assigned for delivery. Please update the vehicle
                    details{" "}
                    <Text
                      style={{ textDecoration: "underline", cursor: "pointer" }}
                      onClick={() => handleAddVehicleModal(element?.id)}
                    >
                      here
                    </Text>
                  </Col>
                )}
              {element?.status === "Out for delivery" && (
                <Col className="item-check">
                  <CheckIcon />
                  <Col>
                    <Text style={{ opacity: 0.6 }}>Vehicle details added </Text>
                    {/* <Text style={{ textDecoration: "underline" }}>View</Text> */}
                  </Col>
                </Col>
              )}
              {element?.status === "Cancelled" && (
                <Col className="detail-notify-cancelled">
                  <Text className="detail-notify-cancelled-text">
                    1. Paid amount will be automatically added to the MOB
                    wallet.
                  </Text>
                  <Text className="detail-notify-cancelled-text">
                    2. If the order was confirmed on mobCREDIT, the amount will
                    be auto adjusted. No action needed.
                  </Text>
                </Col>
              )}
              <AddFileDragger
                openAddFile={openAddFile}
                setOpenAddFile={setOpenAddFile}
                handleUploadFile={handleUploadFile}
                isFetching={isFetching}
              />
            </Col>
            <ConfirmationModal
              title={"Order Shipped"}
              subId={element?.suborder_id}
              isModalOpen={ConfirmationModalOpen}
              setIsModalOpen={setConfirmationModalOpen}
              updateOrderStatusLoading={updateOrderStatusLoading}
              handleOk={() =>
                handleChangeStatus("Out for delivery", element?.id)
              }
            />

            <ViewServiceRequestModal
              open={openViewServiceModal}
              handleOk={handleCancelViewServiceRequest}
              data={serviceRequestData?.data}
              handleOpenDrawer={handleOpenServiceRequestDrawer}
            />
            <ServiceRequestDrawer
              drawerVisible={openServiceDrawer}
              setDrawerVisible={setOpenServiceDrawer}
              data={serviceDrawerData}
              updateStatusApi={updateServiceRequestStatusAPI}
              addCommentApi={addCommentServiceRequestAPI}
              isSuccessAddCommentServiceRequest={
                isSuccessAddCommentServiceRequest
              }
            />
            <ServiceRequestModal
              title={"Service request"}
              //  isModalOpen={openServiceModal}
              isModalOpen={
                serviceRequestModalStates[index] &&
                serviceRequestModalStates[index].isOpen
              }
              setIsModalOpen={setOpenServiceModal}
              saveText={"RAISE SERVICE REQUEST"}
              handleCancel
              data={element}
              handleSave={handleSaveServiceRequest}
              serviceRequestForm={serviceRequestForm}
              setServiceRequestForm={setServiceRequestForm}
              initialValues={initialServiceRequestData}
              form={form}
              handleMenuClick = {handleMenuClick}
              setReportingManager = {setServiceReportingManager}
              reportingManager={serviceReportingManager}
              loading={isLoadingServiceRequest || isFetchingServiceRequest}
              
              setModalOpen={(isOpen) => {
                console.log("Modal Open")
                setserviceRequestModalState((prevStates) => ({
                  ...prevStates,
                  [`modal${index}`]: isOpen,
                }));
              }}
              setserviceRequestModalState={setserviceRequestModalState}
              index={index}
              reportingManagers={reporting_managers}
            />
            <DeliverySlot
              handleOk={() => updateDeliveryDate("", DeliverySubOrderId)}
              //  isModalOpen={DeliveryUpdateModel}
              isModalOpen={modalStates[index] && modalStates[index].isOpen}
              setModalStates={setModalStates}
              DeliveryDateValue={DeliverydateValue}
              SelectedTime={selectedTime}
              setDeliveryDateValue={setDeliveryDateValue}
              setSelectedTime={setSelectedTime}
              ExistingDate={element?.delivery_date}
              ExistingTime={element?.delivery_slot}
              setDeliveryComments={setDeliveryComments}
              DeliveryComments={DeliveryComments}
              DeliverySubOrderId={DeliverySubOrderId}
              index={index}
              setModalOpen={(isOpen) => {
                setModalStates((prevStates) => ({
                  ...prevStates,
                  [`modal${index}`]: isOpen,
                }));
              }}
            />
          </>
        );
      })}
    </>
  );
};

export default OrderItem;
