
import {Col, Button, Typography, Tag,Avatar} from "antd";
import "./ServiceComponent.css";
import {
  colorMapping,
  dateFormat,
} from "../../commonFuntions/CommonUtilFunctions";
import {getNameInitials} from   "../../commonFuntions/CommonUtilFunctions";


const ServiceRequestList = (props) => {
  const { data, 
    order_id, 
    reporting_managers, 
    reporting_manager, 
    handleOpenView } = props;

  const {Text} = Typography;

  return (
    <>
      <Col className="service-list-main-Wrapper">
        {data?.map((item) => {
          return (
            <>
              <Col className="service-list-main-sub-Wrapper">
                <Col className="service-list-details">
                  <Col className="service-list-details-sub">
                    <Text className="service-list-details-sub-service-id">
                      {item?.service_request_id}
                    </Text>
                    <Text className="service-list-details-sub-service-date">
                      {item?.created_at ? dateFormat(item?.created_at) : "N/A"}
                    </Text>
                  </Col>

                  <Col className="service-list-details-sub-type-container">
                    <Text className="service-list-details-sub-service-id">
                      {item?.issue_type}
                    </Text>
                    <Col className="service-list-details-sub-type-tag-container">
                      <Tag
                        style={{padding: "8px", height: "36px"}}
                        className={`tag-font-style ${
                          colorMapping[item?.status]
                        }`}>
                        {item?.status}
                      </Tag>
                    </Col>
                    
                  </Col>
                </Col>
                <Col style={{justifyContent:'left',textAlign:'left'}}>

                
                    {item?.reporting_manager?.reporting_person ? (
                      <div style={{justifyContent:'left',textAlign:'left'}}>
  <Avatar style={{ backgroundColor: '#4295a5', marginRight: '8px',marginTop:'8px' }}>
  {getNameInitials(item?.reporting_manager?.reporting_person)}
   </Avatar>
    <b type="">{item?.reporting_manager?.reporting_person}
    </b>
 </div>

                    ) :(<></>)}
       
               
                </Col>
                
                <Col>
                  <Button
                    className="service-list-main-sub-Wrapper-view-button"
                    onClick={() => handleOpenView(item, order_id, reporting_managers, reporting_manager)}>
                    View
                  </Button>
                </Col>
              </Col>
            </>
          );
        })}
      </Col>
    </>
  );
};

export default ServiceRequestList;
