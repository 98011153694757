import {Col, Input, Typography} from "antd";
import React from "react";

const ProjectCustomInput = ({
  inputClass,
  labelClass,
  ContainerClass,
  placeholder,
  value,
  label,
  name,
  handleInputChange,
  ref,
  maxLength,
  onKeyDown,
  type,
  disabled,
  onBlur = () => {},
}) => {
  const {Text} = Typography;
  return (
    <>
      <Col className={ContainerClass}>
        <Text className={labelClass}>{label}</Text>
        <Input
          ref={ref}
          className={inputClass}
          placeholder={placeholder}
          value={value}
          name={name}
          type={type}
          onChange={(event) => handleInputChange(event)}
          maxLength={maxLength}
          onKeyDown={onKeyDown}
          onBlur={onBlur}
          disabled={disabled}
        />
      </Col>
    </>
  );
};

export default ProjectCustomInput;
