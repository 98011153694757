import React, {useEffect, useState} from "react";
import Card from "antd/es/card/Card";
import {Button, Col, Input, Row} from "antd";
// import { Checkbox } from "antd";
import {Divider, Typography} from "antd";
// import { ReactComponent as TagIcon } from "../../../../assets/icons/tag.svg";
import {ReactComponent as SubOrderIcon} from "../../../../assets/icons/SubOrderHome.svg";
// import { ReactComponent as StarIcon } from "../../../../assets/icons/star-icon.svg";
import {ReactComponent as MobCreditWhiteIcon} from "../../../../assets/icons/mobCreditWhiteIcon.svg";
import {ReactComponent as ActiveTickIcon} from "../../../../assets/icons/greenTickActive.svg";
import {ReactComponent as CancelCrossIcon} from "../../../../assets/icons/cancelInactiveIcon.svg";

// import { DownOutlined } from "@ant-design/icons";
import {
  useDispatch,
  // useSelector
} from "react-redux";
import Toggle from "../../../../components/Discount/Toggle";
import TermsAndCondition from "./Term&Condition/TermsAndCondition";
import {rupifiStatusCheck} from "../../../../commonUtils/commonUtils";
const {Text} = Typography;

const Checkout = ({
  AddNewSubOrder,
  selectedSeller = [],
  dataSource,
  editCondition,
  handleCreateQuote,
  disableCondition,
  cancelQuote,
  grandPrice,
  buttonText,
  type,
  bmpPrice,
  editQuoteData,
  getGrandPrice,
  billingAddressSelector,
  data,
  loading,
}) => {

  const dispatch = useDispatch();
  // const billing_address = useSelector(billingAddressSelector);
  const [additionalDiscountValue, setAdditionalDiscountValue] = useState("");
  const [active, setActive] = useState(0);
  const onSelect = (value) => {
    setActive(value);
  };
  useEffect(() => {
    if (editCondition) {
      setAdditionalDiscountValue(editQuoteData?.additional_discount);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editCondition, editQuoteData]);
  const handleAddDiscountValue = (e) => {
    const value = e.target.value;
    const decimalPattern = /^(?:\d+|\d+\.\d{0,2})$/;
    if (decimalPattern.test(value)) {
      setAdditionalDiscountValue(value || "");
    }
    if (value === "") {
      setAdditionalDiscountValue("");
    }
  };
  const SubtotalPrice = dataSource?.reduce(
    (tableSubTotal, table, index) =>
      tableSubTotal +
      table.reduce(
        (rowSubTotal, row, index) => rowSubTotal + parseFloat(row.total || 0),
        0
      ) +
      parseFloat(bmpPrice[index]?.delivery_fee || 0),
    0
  );

  const totalDiscount = dataSource?.reduce(
    (tableTotalDiscount, table, index) =>
      tableTotalDiscount +
      table.reduce(
        (rowDiscount, row, index) =>
          rowDiscount +
          parseFloat(row.discount || 0) * parseFloat(row.quantity || 1),
        0
      ),
    0
  );

  const taxPrice = bmpPrice?.reduce((acc, tableFoot, index) => {
    return acc + parseFloat(tableFoot?.cgst || 0);
  }, 0);
  const igst = bmpPrice?.reduce((acc, tableFoot, index) => {
    return acc + parseFloat(tableFoot?.igst || 0);
  }, 0);

  const grandTotalPrice =
    active === 0
      ? SubtotalPrice - parseFloat(additionalDiscountValue || 0)
      : SubtotalPrice -
        (SubtotalPrice * parseFloat(additionalDiscountValue || 0)) / 100;

  const grandTotal = grandTotalPrice < 0 ? 0 : grandTotalPrice;

  useEffect(() => {
    dispatch(
      getGrandPrice({
        ...grandPrice,
        additional_discount: additionalDiscountValue || 0,
        sub_total: SubtotalPrice,
        discount: totalDiscount,
        sgst: taxPrice,
        cgst: taxPrice,
        add_additional_discount: additionalDiscountValue || 0,
        total: grandTotal,
        igst: igst,
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [additionalDiscountValue, taxPrice, grandTotal]);

  return (
    <>
      {(selectedSeller[0] || selectedSeller?.length === 0) && (
        <>
          {" "}
          <Col
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginTop: "24px",
            }}>
            <Col>
              <Button
                onClick={() => AddNewSubOrder()}
                icon={<SubOrderIcon />}
                style={{
                  width: "280px",
                  height: "50px",
                  color: "#0a243f",
                  fontWeight: 500,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}>
                Add new suborder
              </Button>
            </Col>
            <Card style={{width: "48%"}}>
              {(data?.wallet ||
                data?.wallet?.wallet ||
                data?.wallet === 0 ||
                data?.wallet?.wallet === 0) && (
                <Col
                  style={{
                    display: "flex",
                    gap: "12px",
                    alignContent: "middle",
                  }}>
                  {/* <Checkbox /> */}
                  <Typography>
                    <Text
                      style={{
                        fontFamily: "Inter",
                        fontWeight: "500",
                        fontStyle: "normal",
                        fontSize: "14px",
                        color: "#01a685",
                      }}>
                      {`₹ ${
                        editCondition
                          ? (data?.wallet || 0).toFixed(2)
                          : (data?.wallet?.wallet || 0).toFixed(2)
                      } wallet money available`}
                      {/* {`Use your ₹ ${
                        editCondition ? data?.wallet : data?.wallet?.wallet
                      } wallet money`} */}
                    </Text>
                  </Typography>
                </Col>
              )}
              {/* <Col
                style={{ display: "flex", gap: "12px", verticalAlign: "middle" }}
              >
                <Checkbox />
                <Typography>
                  <Text
                    style={{
                      fontFamily: "Inter",
                      fontWeight: "500",
                      fontStyle: "normal",
                      fontSize: "14px",
                      color: "#0a243f",
                    }}
                  >
                    Use your
                  </Text>
                </Typography>
                <StarIcon
                  style={{
                    margin: "3px 3px",
                  }}
                />
                <Typography>
                  <Text
                    style={{
                      marginLeft: "-10px",
                      fontFamily: "Inter",
                      fontWeight: "500",
                      fontStyle: "normal",
                      fontSize: "14px",
                      color: "#0a243f",
                    }}
                  >
                    2500 points
                  </Text>
                  <Text
                    style={{
                      color: "#01a685",
                      fontFamily: "Inter",
                      fontSize: "14px",
                      fontWeight: "500",
                    }}
                  >
                    ( ₹250 )
                  </Text>
                </Typography>
              </Col> */}
              <Divider />
              <Col>
                <Typography
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}>
                  <Text
                    style={{
                      display: "inline-block",
                      fontFamily: "Inter",
                      fontSize: "14px",
                      fontWeight: "500",
                    }}>
                    Subtotal
                  </Text>
                  <Text
                    style={{
                      fontFamily: "Inter",
                      fontSize: "14px",
                      fontWeight: "500",
                    }}>
                    ₹{" "}
                    {(SubtotalPrice && Number(SubtotalPrice || 0).toFixed(2)) ||
                      0}
                  </Text>
                </Typography>
                <Typography
                  style={{
                    marginTop: "16px",
                    display: "flex",
                    justifyContent: "space-between",
                  }}>
                  <Text
                    style={{
                      display: "inline-block",
                      fontFamily: "Inter",
                      fontSize: "14px",
                      fontWeight: "500",
                    }}>
                    Discount
                  </Text>
                  <Text
                    style={{
                      fontFamily: "Inter",
                      fontSize: "14px",
                      fontWeight: "500",
                      color: "#01a685",
                    }}>
                    - ₹ {totalDiscount}
                  </Text>
                </Typography>
                {billingAddressSelector?.state !== "Tamil Nadu" ? (
                  <Typography
                    style={{
                      marginTop: "16px",
                      display: "flex",
                      justifyContent: "space-between",
                    }}>
                    <Text
                      style={{
                        display: "inline-block",
                        fontFamily: "Inter",
                        fontSize: "14px",
                        fontWeight: "500",
                      }}>
                      {"IGST"}
                    </Text>
                    <Text
                      style={{
                        fontFamily: "Inter",
                        fontSize: "14px",
                        fontWeight: "500",
                        color: "#01a685",
                      }}>
                      ₹ {(igst && Number(igst || 0).toFixed(2)) || 0}
                    </Text>
                  </Typography>
                ) : (
                  <>
                    <Typography
                      style={{
                        marginTop: "16px",
                        display: "flex",
                        justifyContent: "space-between",
                      }}>
                      <Text
                        style={{
                          display: "inline-block",
                          fontFamily: "Inter",
                          fontSize: "14px",
                          fontWeight: "500",
                        }}>
                        {"SGST"}
                      </Text>
                      <Text
                        style={{
                          fontFamily: "Inter",
                          fontSize: "14px",
                          fontWeight: "500",
                          color: "#01a685",
                        }}>
                        ₹ {(taxPrice && Number(taxPrice).toFixed(2)) || 0}
                      </Text>
                    </Typography>
                    <Typography
                      style={{
                        marginTop: "16px",
                        display: "flex",
                        justifyContent: "space-between",
                      }}>
                      <Text
                        style={{
                          display: "inline-block",
                          fontFamily: "Inter",
                          fontSize: "14px",
                          fontWeight: "500",
                        }}>
                        {"CGST"}
                      </Text>
                      <Text
                        style={{
                          fontFamily: "Inter",
                          fontSize: "14px",
                          fontWeight: "500",
                          color: "#01a685",
                        }}>
                        ₹ {(taxPrice && Number(taxPrice).toFixed(2)) || 0}
                      </Text>
                    </Typography>
                  </>
                )}
                <Col
                  style={{
                    paddingTop: "10px",
                    display: "flex",
                    height: "70px",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}>
                  <Col
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}>
                    <Text
                      style={{
                        display: "inline-block",
                        fontFamily: "Inter",
                        fontSize: "14px",
                        fontWeight: 500,
                        color: "#0a243f",
                      }}>
                      Add additional discount
                    </Text>
                  </Col>
                  <Col
                    style={{
                      display: "flex",
                      alignItems: "center",
                    }}>
                    <Input
                      className="custum_input toggle_input"
                      disabled={true}
                      placeholder="Enter discount"
                      style={{
                        height: "40px",
                        width: "240px",
                        textAlign: "right",
                      }}
                      value={additionalDiscountValue}
                      onChange={handleAddDiscountValue}
                      prefix={
                        <Toggle
                          setActive={setActive}
                          active={active}
                          onSelect={onSelect}
                        />
                      }
                    />
                  </Col>
                </Col>
              </Col>
              <Divider />
              <Col>
                <Typography
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}>
                  <Text
                    style={{
                      fontSize: "20px",
                      fontFamily: "Inter",
                      color: "#0a243f",
                      fontWeight: "500",
                    }}>
                    Total
                  </Text>
                  <Text
                    style={{
                      fontSize: "20px",
                      fontFamily: "Inter",
                      color: "#0a243f",
                      fontWeight: "bold",
                    }}>
                    ₹ {(grandTotal && Number(grandTotal).toFixed(2)) || 0}
                  </Text>
                </Typography>
              </Col>
              <Divider />
              {/* <Col style={{ display: "flex", justifyContent: "flex-end" }}>
                <Text
                  style={{
                    fontSize: "14px",
                    color: "#0a243f",
                    fontWeight: "bold",
                    opacity: "0.6",
                  }}
                >
                  You will earn{" "}
                  <Text
                    style={{
                      fontSize: "14px",
                      color: "#0a243f",
                      fontWeight: "bold",
                      opacity: 1,
                    }}
                  >
                    <StarIcon />
                    328 points
                  </Text>{" "}
                  on this purchase
                </Text>
              </Col> */}
            </Card>
          </Col>
          {rupifiStatusCheck.includes(
            data?.rupifiDetails?.account_status ||
              data?.user_details?.rupifiDetails?.account_status ||
              data?.rupifiDetails?.account_status
          ) && (
            <Col
              style={{
                display: "flex",
                width: "100%",
                justifyContent: "flex-end",
                marginTop: "15px",
              }}>
              <Col
                style={{
                  display: "flex",
                  width: "48%",
                  height: "60px",
                  justifyContent: "space-between",
                  alignItems: "center",
                  marginTop: "15px",
                  background: "#fff",
                  borderRadius: "8px",
                  padding: "0px 16px",
                  border: "1px solid #dedede",
                }}>
                <Col
                  style={{display: "flex", alignItems: "center", gap: "10px"}}>
                  <MobCreditWhiteIcon />
                  {/* {false && (
                    <Text style={{ fontSize: "16px", fontWeight: 500 }}>
                      Checkout option
                    </Text>
                  )} */}
                  {(data?.rupifiDetails?.account_status ||
                    data?.user_details?.rupifiDetails?.account_status ||
                    data?.rupifiDetails?.account_status) === "ACTIVE" && (
                    <Text style={{fontSize: "16px", fontWeight: 500}}>
                      Billing address & GSTIN
                    </Text>
                  )}
                </Col>
                <Col
                  style={{display: "flex", alignItems: "center", gap: "10px"}}>
                  {(data?.rupifiDetails?.account_status ||
                    data?.user_details?.rupifiDetails?.account_status ||
                    data?.rupifiDetails?.account_status) !== "ACTIVE" ? (
                    <>
                      {" "}
                      <Text
                        style={{
                          fontSize: "16px",
                          fontWeight: 500,
                          color: "#d33f0d",
                        }}>
                        Account is not active
                      </Text>
                    </>
                  ) : billingAddressSelector?.mob_credit ? (
                    <>
                      <ActiveTickIcon />
                      <Text
                        style={{
                          fontSize: "16px",
                          fontWeight: 500,
                          color: "#0da72f",
                        }}>
                        Selected
                      </Text>
                    </>
                  ) : (
                    <>
                      <CancelCrossIcon />
                      <Text
                        style={{
                          fontSize: "16px",
                          fontWeight: 500,
                          color: "#d33f0d",
                        }}>
                        Not selected
                      </Text>{" "}
                    </>
                  )}
                </Col>
              </Col>
            </Col>
          )}
          {/* <Col style={{ display: "flex", justifyContent: "right" }}>
            <Card
              style={{
                width: "48%",
                marginTop: "24px",
                boxShadow: " 0 1px 4px 0 rgba(0, 0, 0, 0.16)",
                border: "solid 1px #eaeaea",
              }}
            >
              <Typography
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Col
                  style={{
                    height: "40px",
                    width: "40px",
                    padding: "12.4px 11.6px 11.6px 12.4px",
                    borderRadius: "20px",
                    backgroundColor: "#f1f1f2",
                    marginRight: "16px",
                  }}
                >
                  <TagIcon
                    style={{
                      width: "16px",
                      height: "16px",
                    }}
                  />
                </Col>
                <Text
                  style={{
                    fontWeight: "500",
                    fontFamily: "Inter",
                    fontSize: "16px",
                    color: "#0a243f",
                    width: "600px",
                  }}
                >
                  Apply coupons and offers
                </Text>
                <Col style={{ margin: "15px", cursor: "pointer" }}>
                  <DownOutlined />
                </Col>
              </Typography>
            </Card>
          </Col> */}
        </>
      )}
      <Col
        style={{
          display: "flex",
          justifyContent: "right",
        }}>
        <Row
          style={{
            width: "450px",
            marginTop: "48px",
            display: "flex",
            justifyContent: "end",
            gap: "16px",
          }}>
          <Button
            style={{
              padding: "13px 40px",
              height: "48px",
              fontFamily: "Inter",
              fontWeight: "500",
              border: "solid 1px #9da7b2",
              borderRadius: "8px",
            }}
            onClick={() => cancelQuote(editCondition ? "updateOrder" : "")}>
            CANCEL
          </Button>
          <Button
            style={{
              padding: "13px 60px 13px 60px",
              fontFamily: "Inter",
              fontWeight: "500",
              backgroundColor: type ? "#0354a3" : "#0a243f",
              color: "#fff",
              height: "48px",
              borderRadius: "8px",
              opacity: disableCondition || loading ? "0.5" : 1,
            }}
            loading={loading}
            disabled={disableCondition || loading}
            onClick={handleCreateQuote}>
            {/* {editCondition ? "UPDATE QUOTE" : "CREATE QUOTE"} */}
            {buttonText}
          </Button>
        </Row>
      </Col>

      <Col
        style={{
          display: "flex",
          marginTop: "80px",
        }}>
        <TermsAndCondition
          termsText
          editCondition={editCondition}
          editQuoteData={editQuoteData}
          grandPrice={grandPrice}
          getGrandPrice={getGrandPrice}
        />
      </Col>
    </>
  );
};

export default React.memo(Checkout);
