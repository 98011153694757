import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQueryWithReauth } from "./baseQuery";

export const createOrderAPI = createApi({
  reducerPath: "createOrderAPI",
  baseQuery: baseQueryWithReauth,
  endpoints: (builder) => ({
    getCreateOrderUsers: builder.query({
      query: (searchValue) => {
        return {
          url: `accounts/mob_user/get_users/`,
          params: searchValue,
        };
      },
    }),
    getEditOrderUsers: builder.query({
      query: (id) => {
        return {
          url: `api/orders/order/${id}/get_order_items/`,
        };
      },
    }),
    getEditOrderAddress: builder.query({
      query: (params) => {
        return {
          url: `accounts/mob_user/get_user_address/`,
          params,
        };
      },
    }),
    createOrderApi: builder.mutation({
      query: (newVendorData) => ({
        url: "api/orders/order/create_order/",
        method: "POST",
        body: newVendorData,
      }),
    }),
    createNewUser: builder.mutation({
      query: (data) => ({
        url: "accounts/mob_user/create_user/",
        method: "POST",
        body: data,
      }),
    }),
    updateOrderApi: builder.mutation({
      query: (newVendorData) => ({
        url: "api/orders/order/update_order/",
        method: "PATCH",
        body: newVendorData,
      }),
    }),

 updateReportingManager: builder.mutation({
    query: (params) => {
      return {
        url: `/api/orders/order/update_reporting_manager/`,
        method: "PATCH",
        body: params,
      };
    },
  }),
  }),

 


});

export const {
  useLazyGetCreateOrderUsersQuery,
  useCreateOrderApiMutation,
  useUpdateOrderApiMutation,
  useLazyGetEditOrderUsersQuery,
  useCreateNewUserMutation,
  useLazyGetEditOrderAddressQuery,
  useUpdateReportingManagerMutation
} = createOrderAPI;

export default createOrderAPI;
