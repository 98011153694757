import React, {useEffect, useState} from "react";
import {Col, Tag, Typography, message} from "antd";
import SearchInput from "../../../components/Input/SearchInputs/SearchInput";
import CustomSelect from "../../../components/SelectableDropdown/CustomSelect/CustomSelect";
import CustomTableWithPagination from "../../../components/CustomTableWithPagination/CustomTableWithPagination";
import {
  useLazyGetDownloadServiceRequestReportsQuery,
  useLazyGetServiceRequestDataQuery,
} from "../../../apis/reports";
import TableSkeleton from "../../../components/skeleton/TableSkeleton";
import {LoadingOutlined} from "@ant-design/icons";
import {saveAs} from "file-saver";
import {
  colorMapping,
  dateFormat,
} from "../../../commonFuntions/CommonUtilFunctions";
import {Link} from "react-router-dom";
import {getNameInitials} from "../../../commonFuntions/CommonUtilFunctions";
import DateRangePicker from "../../../components/DateRangePicker/DateRangePicker";

import {Avatar} from "antd";
import dayjs from "dayjs";


const {Text} = Typography;
const options = [
  {
    key: 1,
    value: "Export",
    label: "Export",
  },
];

const dateOptions = [
  {
    key: 1,
    value: "Today",
    label: "Today",
  },
  {
    key: 2,
    value: "Yesterday",
    label: "Yesterday",
  },
  {
    key: 3,
    value: "last_7_day",
    label: "Last 7 day",
  },
  {
    key: 4,
    value: "last_30_day",
    label: "Last 30 day",
  },
  {
    key: 5,
    value: "last_60_day",
    label: "Last 60 day",
  },
  {
    key: 6,
    value: "Custom",
    label: "Custom",
  },
  {
    key: 7,
    value: "clear_date",
    label: (
      <Col style={{ color: "#006644", fontWeight: "500" }}>Clear Date</Col>
    ),
  },
];

const ServiceRequestReport = () => {
  const [searchedValue, setSearchedValue] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [serviceStatusFilter, setServiceStatusFilter] = useState();
  const [serviceReportingFilter, setServiceReportingFilter] = useState();
  const [dateFilter, setDateFilter] = useState();

  //const [reportingManagers,setReportingManagers] = useState([])

  const [
    getCustomerOrderReportApi,
    {data: customerOrderReportData, isFetching},
  ] = useLazyGetServiceRequestDataQuery();
  const [getDownloadCustomerDetailsReportapi, {isFetching: downloadReport}] =
    useLazyGetDownloadServiceRequestReportsQuery();
  useEffect(() => {
    getCustomerOrderReportApi();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const [dateSearch, setDateSearch] = useState({
    start_date: "",
    end_date: "",
  });

  const handleReportingChange = async (value)=>{
    setCurrentPage(1);

    if(value === "clear"){
      
      setServiceReportingFilter()
      const params = {
        reporting_manager: "",
      ...(serviceStatusFilter && {status: serviceStatusFilter}),
      ...(currentPage && { page: 1 }),
      ...(dateSearch.start_date && { start_date: dateSearch.start_date }),
      ...(dateSearch.end_date && { end_date: dateSearch.end_date }),
      ...(searchedValue && {search: searchedValue?.search}),
      ...(searchedValue && {search: searchedValue?.search}),
      };
      await getCustomerOrderReportApi(params);
      return
    }
    const params = {
      reporting_manager: value,
      ...(serviceStatusFilter && {status: serviceStatusFilter}),
      ...(currentPage && { page: 1 }),
      ...(dateSearch.start_date && { start_date: dateSearch.start_date }),
      ...(dateSearch.end_date && { end_date: dateSearch.end_date }),
      ...(searchedValue && {search: searchedValue?.search}),
      ...(searchedValue && {search: searchedValue?.search}),
    };
    
    await getCustomerOrderReportApi(params);
    setServiceReportingFilter(value);
  }
  const  handleStatusChange = async (value)=>{
    setCurrentPage(1);

    if(value === "clear"){
      setServiceStatusFilter();
      console.log(serviceReportingFilter, "👨🏻👩🏻")
      const params = {
        status: "",
        ...(serviceReportingFilter && {reporting_manager: serviceReportingFilter}),
        ...(currentPage && { page: 1 }),
        ...(dateSearch.start_date && { start_date: dateSearch.start_date }),
        ...(dateSearch.end_date && { end_date: dateSearch.end_date }),
        ...(searchedValue && {search: searchedValue?.search}),
      };
     await getCustomerOrderReportApi(params);
     return
    }
    const params = {
      status: value,
      ...(serviceReportingFilter && {reporting_manager: serviceReportingFilter}),
      ...(currentPage && { page: 1 }),
      ...(dateSearch.start_date && { start_date: dateSearch.start_date }),
      ...(dateSearch.end_date && { end_date: dateSearch.end_date }),
      ...(searchedValue && {search: searchedValue?.search}),
    };
    await getCustomerOrderReportApi(params);
    setServiceStatusFilter(value);
  }


  const handleChange = (value) => {
    let formattedDate = null;

    switch (value) {
      case "Today":
        formattedDate = dayjs().format("YYYY-MM-DD");
        break;
      case "Yesterday":
        formattedDate = dayjs().subtract(1, "day").format("YYYY-MM-DD");
        break;
      case "last_7_day":
        formattedDate = dayjs().subtract(6, "day").format("YYYY-MM-DD");
        break;
      case "last_30_day":
        formattedDate = dayjs().subtract(29, "day").format("YYYY-MM-DD");
        break;
      case "last_60_day":
        formattedDate = dayjs().subtract(59, "day").format("YYYY-MM-DD");
        break;
      case "Custom":
        formattedDate = "Custom";
        break;
      default:
        break;
    }

    const currentDate = dayjs().format("YYYY-MM-DD");
    if (value !== "Custom" && value !== null) {
      if (value === "clear_date") {
        getCustomerOrderReportApi({
          status: value,
          ...(serviceStatusFilter && {status: serviceStatusFilter}),
          ...(serviceReportingFilter && {reporting_manager: serviceReportingFilter}),
          ...(currentPage && { page: 1 }),
          ...(searchedValue?.search && { search: searchedValue?.search }),

          page: 1,
        });
        setDateSearch({
          start_date: "",
          end_date: "",
        });
      } else {
        getCustomerOrderReportApi({
          start_date: formattedDate,
          end_date: currentDate,
          ...(serviceStatusFilter && {status: serviceStatusFilter}),
          ...(serviceReportingFilter && {reporting_manager: serviceReportingFilter}),
          ...(searchedValue?.search && { search: searchedValue?.search }),

          page: 1,
        });
        setDateSearch({
          start_date: formattedDate,
          end_date: currentDate,
        });
      }
      setCurrentPage(1);
    }
    setDateFilter(value);
  };
  const handleChangeRangePicker = (dates, dateStrings) => {
    if (dates && dates.length === 2) {
      const startDateFormatted = dates[0].format("YYYY-MM-DD");
      const endDateFormatted = dates[1].format("YYYY-MM-DD");
      getCustomerOrderReportApi({
        start_date: startDateFormatted,
        end_date: endDateFormatted,
        ...(serviceStatusFilter && {status: serviceStatusFilter}),
        ...(serviceReportingFilter && {reporting_manager: serviceReportingFilter}),
        ...(searchedValue?.search && { search: searchedValue?.search }),

      });
      setDateSearch({
        start_date: startDateFormatted,
        end_date: endDateFormatted,
      });
    }
  };


  const serviceStatusOptions  =[
    { key: 1, value: "Registered", label: "Registered" },
    { key: 2, value: "In Progress", label: "In Progress" },
    { key: 3, value: "Redundant", label: "Redundant" },
    { key: 5, value: "Resolved", label: "Resolved" },

    {
      key: 7,
      value: "clear",
      label: (
        <Col style={{ color: "#006644", fontWeight: "500" }}>Clear</Col>
      ),
    },
  ]

  function convertToExportOptions(data) {
    if(data === undefined){
      return
    }
    var options =  data.map(item => ({
      key: item.id,
      value: item.id,
      label: item.reporting_person,
      
    }));
    options.push( {
      key: 9999,
      value: "clear",
      label: (
        <Col style={{ color: "#006644", fontWeight: "500" }}>Clear</Col>
      ),
    },)
    return options
  }

  const columns = [
    {
      title: "Service request ID",
      dataIndex: "service_request_id",
      key: "service_request_id",
      width: "230px",
      fixed: "left",
      render: (params, record) => {
        return (
          <Link
            to={`/admin/orders/view-details/${record?.suborder?.id}`}
            target="_blank"
            style={{fontWeight: 500, color: "#2973f0", cursor: "pointer"}}>
            {params}
          </Link>
        );
      },
    },
    {
      title: "Suborder id",
      dataIndex: "suborder",
      key: "suborder",
      width: "200px",
      render: (params, record) => {
        return (
          <Link
            to={`/admin/orders/view-details/${record?.suborder?.id}`}
            target="_blank"
            style={{fontWeight: 500, color: "#2973f0", cursor: "pointer"}}>
            {record?.suborder?.suborder_id || "N/A"}
          </Link>
        );
      },
    },
    {
      flex: 1,
      title: "Assigned to",
      width: "250px",
      key: 3,
      dataIndex: "reporting_manager",
      render: (params, record) => {
        var _params = null
        if(record.reporting_manager){
          _params = record.reporting_manager.reporting_person
        }

        return (
          <>

      {_params === null ? (
        <Text type="danger">Not assigned</Text>
      ) : (
        <div>
         <Avatar style={{ backgroundColor: '#4295a5', marginRight: '8px',marginTop:'8px' }}>
            {getNameInitials(_params)}
          </Avatar>
           <Text type="">{_params}
           </Text>
        </div>
      )}          </>
        );
      },
    },
    {
      title: "Date and time",
      dataIndex: "created_at",
      key: "created_at",
      width: "250px",
      render: (params, record) => {
        return (
          <Col style={{fontWeight: 400}}>
            <Text>{params ? dateFormat(params) : "N/A"}</Text>
          </Col>
        );
      },
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      width: "150px",
      render: (params, record) => {
        return (
          <Tag
            style={{padding: "8px"}}
            className={`tag-font-style ${colorMapping[params]}`}>
            {params}
          </Tag>
        );
      },
    },

    {
      title: "Issue type",
      dataIndex: "issue_type",
      key: "issue_type",
      width: "280px",
      render: (params, record) => {
        return (
          <Col>
            <Text>{params}</Text>
          </Col>
        );
      },
    },
    {
      title: "Name (customer)",
      dataIndex: "address",
      key: "address",
      width: "250px",
      render: (parmas, record) => {
        return (
          <Col>
            <Text>{record?.user_data?.full_name}</Text>
          </Col>
        );
      },
    },
    {
      title: "Business mobile",
      dataIndex: "address_line_2",
      key: "address_line_2",
      width: "250px",
      render: (parmas, record) => {
        return (
          <Col>
            <Text>{record?.user_data?.phone_number}</Text>
          </Col>
        );
      },
    },
  ];

  const handlePageChange = async (currPage) => {
    const params = {
      page: currPage,
      ...(searchedValue && {search: searchedValue?.search}),
    };
    setCurrentPage(currPage);
    await getCustomerOrderReportApi(params);
  };

  const handleSearchedValue = (params) => {
    setSearchedValue(params);
    setCurrentPage(1);
  };
  const handleExportApi = async () => {
    let response = await getDownloadCustomerDetailsReportapi({
      ...(searchedValue.search && {search: searchedValue.search}),
      ...(serviceReportingFilter && {reporting_manager: serviceReportingFilter}),
      ...(serviceStatusFilter && {status: serviceStatusFilter}),
      ...(dateSearch.start_date && { start_date: dateSearch.start_date }),
      ...(dateSearch.end_date && { end_date: dateSearch.end_date }),
      
    });
    if(response?.data?.status === false){
      message.error(`${response?.data?.message}`);
      return;
    }

    if (response?.status === "fulfilled") {
      saveAs(response?.data?.data?.url);
    }
  };

  return (
    <Col className="SalesReport-wrapper">
      <Text className="heading">Service Request Report</Text>
      <Col style={{display: "flex", gap: "1rem"}}>
        <SearchInput
          size={"large"}
          placeholder={"Search by customer name, mobile no, email"}
          width={"400px"}
          getLibraryApi={getCustomerOrderReportApi}
          handleSearchedValue={handleSearchedValue}
          isLoading={isFetching}
          queryparams={{
            'status' : serviceStatusFilter,
            'reporting_manager' : serviceReportingFilter,
            'page' : 1,
            'start_date' : dateFilter?.start_date,
            'end_date' : dateFilter?.end_date
          }}
        />
         <CustomSelect
            placeholder={"Date"}
            width={"150px"}
            options={dateOptions}
            handleChange={handleChange}
            value={dateFilter}
          />
          {dateFilter === "Custom" && (
            <DateRangePicker
              onChange={handleChangeRangePicker}
              format={"DD-MMM-YYYY"}
            />
          )}

        <CustomSelect
            placeholder={"Status"}
            width={"150px"}
            options={serviceStatusOptions}
            handleChange={handleStatusChange}
            value={serviceStatusFilter}
          />


        <CustomSelect
            placeholder={"Assigned to"}
            width={"150px"}
            options={convertToExportOptions(customerOrderReportData?.data?.reporting_managers)}
            handleChange={handleReportingChange}
            value={serviceReportingFilter}

          />


        <CustomSelect
          placeholder={"Export"}
          width={"150px"}
          options={options}
          handleChange={handleExportApi}
          value={downloadReport ? <LoadingOutlined /> : "EXPORT"}
          disabled={downloadReport ? true : false}
          loading={downloadReport}
        />
      </Col>
      <CustomTableWithPagination
        className="order-list-table"
        tableDataSource={customerOrderReportData?.data?.results}
        tableColumns={columns}
        locale={{
          emptyText: isFetching ? <TableSkeleton length={10} /> : "",
        }}
        isLoading={customerOrderReportData?.data?.results.length && isFetching}
        scroll={{x: "100%"}}
        currentPage={currentPage}
        handlePageChange={handlePageChange}
        itemsPerPage={30}
        totalEntries={
          !isFetching &&
          customerOrderReportData?.data?.pagination?.total_entries
        }
      />
    </Col>
  );
};

export default ServiceRequestReport;
