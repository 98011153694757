import React, { useCallback, useEffect, useState } from "react";
import {
  Affix,
  Avatar,
  Button,
  Checkbox,
  Col, 
  Dropdown,
  Menu,
  Popconfirm,
  Table,
  Typography,
} from "antd";
import {
  // useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import { CaretUpOutlined, CaretDownOutlined } from "@ant-design/icons";
import CreateOrderProfileSection from "./CreateOrderProfileSection/CreateOrderProfileSection";
import { ReactComponent as UploadImage } from "../../../assets/icons/uploadImage.svg";
import { ReactComponent as DeleteIcon } from "../../../assets/icons/DeleteIconTable.svg";
import TableHeader from "../../Request/RFQ/CreateQuote/CreateQuoteTable/TableHeader/TableHeader";
import TableFooter from "../../Request/RFQ/CreateQuote/CreateQuoteTable/TableFooter/TableFooter";
import EmptyTable from "../../Request/RFQ/CreateQuote/CreateQuoteTable/EmptyTable";
import {
  addressFlagSelector,
  billingAddressSelector,
  bmpPriceSelector,
  // catalogueSelector,
  deliveryAddressSelector,
  editQuoteDataSelector,
  grandPriceSelector,
  vendorListSelector,
} from "../../../redux/slices/createQuote/selector";

import { useDispatch, useSelector } from "react-redux";
import TextArea from "antd/es/input/TextArea";
import UnitNameCell from "../../Request/RFQ/CreateQuote/CreateQuoteTable/TableComponents/UnitNameCell/UnitNameCell";
import GstCell from "../../Request/RFQ/CreateQuote/CreateQuoteTable/TableComponents/GstCell/GstCell";
import {
  priceAfterTax,
  totalPrice,
} from "../../../commonFuntions/CommonUtilFunctions";
import AddImages from "../../../components/Modal/Table/AddImages";
import Checkout from "../../Request/RFQ/CreateQuote/Checkout";
import MoveItemModal from "../../../components/Modal/Table/MoveItemModal";
import { baseUrl } from "../../../commonUtils/commonUtils";
import { debounce } from "lodash";
import {
  useDeleteQuoteItemMutation,
  useDeleteQuoteTableMutation,
  useLazyGetCatalogueByVendorDrawerQuery,
  useLazyGetCatalogueByVendorModalQuery,
  useLazyGetCatalogueByVendorQuery,
  useLazyGetReportingManagersQuery
  
} from "../../../apis/createQuote";
import {

  useUpdateReportingManagerMutation,
  
} from  "../../../apis/createOrder";

import {
  getCreateOrderBillingAddress,
  getCreateOrderDeliveryAddress,
  getCreateOrderDrawerProductData,
  getCreateOrderModalProductData,
  getCreateOrderProductList,
  getCreateOrderUserData,
} from "../../../redux/slices/createOrder/action";
import {
  createOrderProductListSelector,
  usersDataSelector,
} from "../../../redux/slices/createOrder/selector";
import {
  getAllVendors,
  getBillingAddress,
  getBillingAddressList,
  getBmpPrice,
  getDeliveryAddress,
  getDeliveryAddressList,
  getGrandPrice,
  getRFQQuoteData,
  updateAddressFlag,
} from "../../../redux/slices/createQuote/action";
import {
  showErrorToast,
  showSuccessToast,
} from "../../../NotificationToast/NotificationToast";
import {
  useCreateOrderApiMutation,
  useLazyGetEditOrderUsersQuery,
  useUpdateOrderApiMutation,
} from "../../../apis/createOrder";
// import { getRFQListFlag } from "../../../redux/slices/rfqList/action";
import ConfirmOrderModal from "../../../components/Modal/ConfirmOrderModal/ConfirmOrderModal";
import UpdateOrderModal from "../../../components/Modal/UpdateOrderModal/UpdateOrderModal";
import TicketAssignmentModal from "../../../components/Modal/UpdateOrderModal/TicketAssignmentModal";

import TableSkeleton from "../../../components/skeleton/TableSkeleton";
import { QuoteLoading } from "../../Animations/QuoteLoading/QuoteLoading";
import CanceledQuoteTable from "../../CanceledQuote/CanceledQuoteTable";


const data = [
  {
    key: 1,
    input: "",
    unit_name: "",
    imageURL: "",
    hsn_sac: "",
    before_tax: "",
    gst: 0,
    after_tax: "",
    amount_in: "amount",
    amount: "",
    bmp_price: "",
    discount: 0,
    quantity: 0,
    total: 0,
    mob_margin: { amount_in: "amount", amount: 0 },
  },
];

const CreateOrder = () => {
  const { order_id, quote_id } = useParams();
  const { Text } = Typography;
  const dispatch = useDispatch();
  // const location = useLocation();
  const navigate = useNavigate();
  const sellerList = useSelector(vendorListSelector);
  const productList = useSelector(createOrderProductListSelector);
  const userData = useSelector(usersDataSelector);
  const bmpPrice = useSelector(bmpPriceSelector);
  const grandPrice = useSelector(grandPriceSelector);
  const editQuoteData = useSelector(editQuoteDataSelector);
  const delivery_address = useSelector(deliveryAddressSelector);
  const addressFlag = useSelector(addressFlagSelector);

  const billing_address = useSelector(billingAddressSelector);
  const [dataSource, setDataSource] = useState([data]);
  const [count, setCount] = useState([data.length]);
  const [selectedSeller, setSelectedSeller] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [addImageModal, setAddImageModal] = useState(false);
  const [currentRecord, setCurrentRecord] = useState(null);
  const [currentIndexTable, setCurrentIndexTable] = useState(null);
  const [isFixedBarVisible, setIsFixedBarVisible] = useState(false);
  const [loader, setLoader] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [warningModal, setWarningModal] = useState(false);
  const [ticketModal, setTicketModal] = useState(false);

  const [trigger, { data: createOrderCatalogueList }] =
    useLazyGetCatalogueByVendorQuery();
  const [getcatalogueListDrawerApi, { data: catalogueListDrawerData }] =
    useLazyGetCatalogueByVendorDrawerQuery();
  const [
    getProductCatalogueDataApi,
    {
      data: productCatalogueData,
      isSuccess: isSuccessProductCatalogueData,
      isFetching: isFetchingProductCatalogueData,
    },
  ] = useLazyGetCatalogueByVendorModalQuery();
  const [
    getEditOrderUserApi,
    { data: editOrderData, isLoading: isLoadingEditData },
  ] = useLazyGetEditOrderUsersQuery();
  const [createOrderAPI, { isLoading: isCreateLoading }] =
    useCreateOrderApiMutation();
  const [updateOrderAPI, { isLoading: isUpdateLoading }] =
    useUpdateOrderApiMutation();
  const [deletTableRowApi] = useDeleteQuoteItemMutation();
  const [deletTableApi] = useDeleteQuoteTableMutation();
  const [getReportingManagers, { data: reportingManagers }] = useLazyGetReportingManagersQuery();

  const handleConfirmOrderModal = () => {
    setModalOpen(true);
  };
  const handleWarnigModal = () => {
    setWarningModal(true);
  };

  const handleTicketModal = () => {
    setTicketModal(true);
  };

  const handleTicketModalCancel = ()=>{
    setTicketModal(false)
  }

  const handleEditDataSource = (editData) => {
    if (!editData?.id) return {};
    const grandPrice = { terms_and_condition: editData?.terms_and_condition };
    const arr = Object?.entries(editData?.quote_items);
    const venderList = arr.map((item, index) => {
      return item[0];
    });
    const bmpData = [];
    const tableData = arr.map((item, index) => {
      bmpData.push({
        additional_bmp_discount: item[1].additional_bmp_discount,
        additional_bmp_discount_in: item[1].additional_bmp_discount_in,
        delivery_fee: item[1].delivery_fee,
        quote_delivery_dates: item[1]?.quote_delivery_dates,
        quote_delivery_slot: item[1]?.quote_delivery_slot,
        quote_delivery_notes: item[1]?.quote_delivery_notes,
        quote_vendor: item[1]?.quote_vendor,
        is_cancelled: item[1]?.is_cancelled,
        index: index,
      });

      const table = item[1].items.map((row, i) => {
        const obj = {
          before_tax: row.price_before_tax,
          after_tax: row.price_after_tax,
          gst: row.gst,
          // index: row.index,
          id: row.id,
          imageURL: row.product.product_image,
          key: i + 1,
          input: row.product.product_name,
          unit_name: row.unit,
          hsn_sac: row?.product?.hsn_code || "",
          amount_in: row.margin_value,
          amount: row.mob_margin,
          bmp_price: row.bmp_price,
          discount: row.discount,
          quantity: row.quantity,
          total: row.total,
          mob_sku: row.product.mob_sku,
          quote_vendor: row?.quote_vendor,
        };
        return obj;
      });
      return table;
    });
    return { venderList, tableData, bmpData, grandPrice };
  };
  const editCondition = quote_id ? true : false;
  const initialValue =
    editCondition && editOrderData?.status
      ? handleEditDataSource(editQuoteData)
      : "";
  const dropDownList = productList?.map((element, index) => {
    return { ...element, label: element.product_name, key: index };
  });

  const handleSetSelector = (data) => {
    dispatch(getCreateOrderUserData(data));
  };

  useEffect(() => {
  
    getReportingManagers();
  }, [getReportingManagers]);


  // useEffect(() => {

  //   const userName = localStorage.getItem('userName');
  //   const managers = reportingManagers?.data
  //   if(managers){
  //     const matchingManagers = managers.filter(manager =>
  //       manager.reporting_person.includes(userName)
  //     )
  //     console.log(matchingManagers, "🧈🧇🍳")

  //     if (matchingManagers.length > 0) {
  //       console.log("🍿🍟")
  //       setReportingManager(matchingManagers[0]);
  //       console.log(reportingManager)
  //     } 
  //   }

  // }, [reportingManagers.data, reportingManager])

  useEffect(() => {
   
    if (reportingManagers?.data) {
      setReportingManagersData(reportingManagers?.data);

      const userName = localStorage.getItem('userName');
      const managers = reportingManagers?.data

      if (managers) {
        const matchingManagers = managers.filter(manager =>
          manager.reporting_person.includes(userName)
        );
        if (matchingManagers.length > 0) {
          setReportingManager(matchingManagers[0])
          console.log("Reporing 👩🏻‍🦱👨🏻‍🦰👩🏻‍🦰👴🏻")
        }
      }
      
    }
  }, [reportingManagers]);

  useEffect(() => {

   
    if (editCondition && quote_id) {
      getEditOrderUserApi(quote_id);
    }

    

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [quote_id, editCondition]);

  useEffect(() => {


    if (editCondition && quote_id && addressFlag) {
      getEditOrderUserApi(quote_id);

      dispatch(updateAddressFlag(false));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addressFlag]);

  useEffect(() => {
    if (editOrderData && editOrderData?.status) {
      setReportingManagersData(editOrderData?.data?.reporting_managers)

      if(editOrderData?.data?.reporting_manager){

        setReportingManager(editOrderData?.data?.reporting_manager)
      }else{
        const userName = localStorage.getItem('userName');
        const managers = editOrderData?.data?.reporting_managers
  
        if (managers) {
          const matchingManagers = managers.filter(manager =>
            manager.reporting_person.includes(userName)
          );
          if (matchingManagers.length > 0) {
            setReportingManager(matchingManagers[0])
            console.log("Reporing 👩🏻‍🦱👨🏻‍🦰👩🏻‍🦰👴🏻")
          }
        }
      }




      dispatch(getRFQQuoteData(editOrderData?.data));
      dispatch(
        getBillingAddress(editOrderData?.data?.address?.billing_address)
      );
      dispatch(
        getDeliveryAddress(editOrderData?.data?.address?.delivery_address)
      );
      dispatch(
        getDeliveryAddressList([
          editOrderData?.data?.data?.address?.delivery_address,
        ])
      );
      dispatch(
        getBillingAddressList([
          editOrderData?.data?.data?.address?.billing_address,
        ])
      );
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editOrderData]);
  useEffect(() => {
    if (editCondition) {
      const contKey = [];
      initialValue?.tableData?.map((element, i) => {
        contKey.push(element?.length);
        return null;
      });
      setDataSource(initialValue?.tableData || []);
      setCount(contKey);
      dispatch(getBmpPrice(initialValue?.bmpData || []));
      dispatch(getGrandPrice(initialValue?.grandPrice || {}));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editQuoteData]);
  const showModal = (record, tableIndex) => {
    setAddImageModal(true);
    setCurrentRecord(record);
    setCurrentIndexTable(tableIndex);
  };
  const handleOk = () => {
    setAddImageModal(false);
  };
  const handleCancel = () => {
    setAddImageModal(false);
  };
  const deselectAll = () => {
    setSelectedRows([]);
    setIsFixedBarVisible(false);
  };
  const handleTrigger = (params) => {
    trigger(params);
  };
  useEffect(() => {
    if (createOrderCatalogueList) {
      dispatch(
        getCreateOrderProductList(
          createOrderCatalogueList?.data?.length
            ? createOrderCatalogueList?.data
            : []
        )
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createOrderCatalogueList]);
  useEffect(() => {
    if (catalogueListDrawerData) {
      dispatch(getCreateOrderDrawerProductData(catalogueListDrawerData || {}));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [catalogueListDrawerData]);
  useEffect(() => {
    if (productCatalogueData?.status || isSuccessProductCatalogueData) {
      dispatch(getCreateOrderModalProductData(productCatalogueData?.data));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    productCatalogueData,
    isSuccessProductCatalogueData,
    isFetchingProductCatalogueData,
  ]);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const searchTextDebouncedTrigger = useCallback(
    debounce(handleTrigger, 1000),
    []
  );
  const handleSaveProduct = (e, tableIndex, record) => {
    const productKey = e.key;
    const productDetail = productList[productKey];
    const total = totalPrice({
      gst: productDetail.product_tax_code.tax,
      before_tax: productDetail.rfq_price,
      discount: record.discount,
      quantity: record.quantity,
    });
    const after_tax = priceAfterTax({
      gst: productDetail.product_tax_code.tax,
      before_tax: productDetail.rfq_price,
    });
    setDataSource((prevData) => {
      return prevData.map((table, index) => {
        return tableIndex === index
          ? table.map((item) => {
              if (item.key === record.key) {
                return {
                  ...item,
                  input: productDetail.product_name,
                  mob_sku: productDetail.mob_sku,
                  hsn_sac: productDetail.hsn_code,
                  before_tax: productDetail.rfq_price,
                  rfq_price: productDetail.rfq_price,
                  vendor_selling_price: productDetail.vendor_selling_price,
                  imageURL: productDetail.product_image,
                  total: total,
                  gst: productDetail.product_tax_code.tax,
                  after_tax: after_tax,
                  amount: 0,
                  bmp_price: total,
                };
              }
              return item;
            })
          : table;
      });
    });
    // dispatch(getCatalogueByVendor([]));
  };
  const handleSearchText = (e, tableIndex, row, currentSeller) => {
    const targetValue = e.target.value;
    setDataSource((prevData) => {
      return prevData.map((table, index) => {
        return tableIndex === index
          ? table.map((item) => {
              if (item.key === row.key) {
                return { ...item, input: targetValue, mob_sku: "" };
              }
              return item;
            })
          : table;
      });
    });
    if (targetValue.length >= 3) {
      searchTextDebouncedTrigger({
        id: currentSeller.bmp_id,
        searchValue: targetValue,
      });
    }
  };
  const moveRowsToTable = (value) => {
    let moveData = [...selectedRows];
    let removeItems = selectedRows.reduce(
      (acc, { tableIndex, key }) => {
        if (acc[tableIndex]) {
          acc[tableIndex] = acc[tableIndex].filter((item) => item.key !== key);
        }
        return acc;
      },
      [...dataSource]
    );
    let newTable = moveData.map((item, index) => {
      const { tableIndex, key, ...rest } = item;
      return { key: index + 1, ...rest };
    });
    if (value === "new_sub_order") {
      let finalData = [...removeItems, newTable].filter(
        (item, index) =>
          item?.length !== 0 || selectedSeller[index]?.vendor_name
      );
      let finalSelectedSeller = [];
      [...removeItems, newTable]?.map((item, index) => {
        if (item?.length !== 0 || selectedSeller[index]?.vendor_name) {
          finalSelectedSeller.push(selectedSeller[index] || {});
        }
        return null;
      });
      setDataSource(finalData);
      setSelectedSeller(finalSelectedSeller);
      setCount([...count, newTable.length]);
    } else {
      let movedTableRows = [...removeItems[value], ...selectedRows];
      movedTableRows = movedTableRows.map((item, index) => {
        const { tableIndex, key, ...rest } = item;
        return { key: index + 1, ...rest };
      });
      let finalArray = removeItems.map((table, index) => {
        if (index === value) {
          return movedTableRows;
        } else {
          return table;
        }
      });
      let finalData = finalArray?.filter(
        (item, index) =>
          item?.length !== 0 || selectedSeller[index]?.vendor_name
      );
      let finalSelectedSeller = [];
      finalArray?.map((item, index) => {
        if (item?.length !== 0 || selectedSeller[index]?.vendor_name) {
          finalSelectedSeller.push(selectedSeller[index] || {});
        }
        return null;
      });
      setDataSource(finalData);
      setSelectedSeller(finalSelectedSeller);
      setCount(
        count.map((item, index) =>
          index === value ? movedTableRows.length : item
        )
      );
    }
    setSelectedRows([]);
    setIsFixedBarVisible(false);
  };
  const AddNewSubOrder = () => {
    const table = [
      {
        key: 1,
        input: "",
        unit_name: "",
        before_tax: "",
        hsn_sac: "",
        gst: 0,
        imageURL: "",
        after_tax: "",
        discount: 0,
        mob_margin: { amount_in: "amount", amount: 0 },
        amount_in: "amount",
        amount: "",
        bmp_price: "",
        quantity: 0,
        total: 0,
      },
    ];
    const newTableData = [...dataSource, table];
    setDataSource(newTableData);
    setCount([...count, 1]);
    setSelectedSeller([...selectedSeller, {}]);
  };
  const handleDeleteTable = async (index, quote_vendor) => {
    const updatedTables = dataSource.filter((_, i) => i !== index);
    const sellerList = selectedSeller.filter((_, i) => i !== index);
    const countList = count.filter((_, i) => i !== index);
    // const bmp = bmpPrice.filter((_, i) => i !== index);
    // dispatch(getBmpPrice(bmp));
    setCount(countList);
    setSelectedSeller(sellerList);
    setDataSource(updatedTables);
    if (editCondition && quote_vendor?.quote_vendor) {
      const params = { quote_vendor: quote_vendor?.quote_vendor };
      const response = await deletTableApi(params);
      if (response?.data?.status) {
        showSuccessToast(response?.data?.message);
      } else {
        showErrorToast(response?.error?.data?.message);
      }
    }
  };
  // const handleAddRow = (tableIndex) => {
  //   const newRow = {
  //     key: count[tableIndex] + 1,
  //     input: "",
  //     unit_name: "",
  //     before_tax: "",
  //     hsn_sac: "",
  //     gst: 0,
  //     imageURL: "",
  //     after_tax: "",
  //     discount: 0,
  //     mob_margin: {amount_in: "amount", amount: 0},
  //     amount_in: "amount",
  //     amount: "",
  //     bmp_price: "",
  //     quantity: 0,
  //     total: 0,
  //   };
  //   setDataSource((table) => {
  //     const updatedData = table.map((tableRows, i) =>
  //       i === tableIndex ? [...tableRows, newRow] : tableRows
  //     );
  //     return updatedData;
  //   });
  //   setCount((prevCount) => {
  //     return prevCount.map((item, index) =>
  //       tableIndex === index ? item + 1 : item
  //     );
  //   });
  // };
  const handleAddRow = (tableIndex, rowData) => {
    let newRow = {};
    if (rowData) {
      newRow = {
        key: count[tableIndex] + 1,
        unit_name: rowData?.unit || "",
        discount: 0,
        mob_margin: { amount_in: "amount", amount: 0 },
        amount_in: "amount",
        amount: "",
        quantity: 0,
        input: rowData?.product_name || rowData?.item_name_title || "",
        mob_sku: rowData?.mob_sku || "",
        hsn_sac: rowData?.hsn_code || "",
        before_tax: rowData?.rfq_price || rowData?.vendor?.rfq_price || "",
        rfq_price: rowData?.rfq_price || rowData?.vendor?.rfq_price || "",
        vendor_selling_price:
          rowData?.vendor_selling_price ||
          rowData?.vendor?.vendor_selling_price ||
          "",
        imageURL: rowData?.product_image || rowData?.images?.[0]?.image || "",
        // total: total,
        gst: rowData?.product_tax_code?.tax || "",
        // after_tax: after_tax,
        // bmp_price: total,
      };
    } else {
      newRow = {
        key: count[tableIndex] + 1,
        input: "",
        unit_name: "",
        before_tax: "",
        hsn_sac: "",
        gst: 0,
        imageURL: "",
        after_tax: "",
        discount: 0,
        mob_margin: { amount_in: "amount", amount: 0 },
        amount_in: "amount",
        amount: "",
        bmp_price: "",
        quantity: 0,
        total: 0,
      };
    }
    setDataSource((table) => {
      const updatedData = table.map((tableRows, i) =>
        i === tableIndex ? [...tableRows, newRow] : tableRows
      );
      return updatedData;
    });
    setCount((prevCount) => {
      return prevCount.map((item, index) =>
        tableIndex === index ? item + 1 : item
      );
    });
  };
  const handleDeleteRow = async (key, tableIndex, record) => {
    setDataSource((prevDataSource) => {
      const updatedData = prevDataSource.map((tableRows, i) => {
        if (i === tableIndex) {
          const updatedTableRows = tableRows.filter((row) => row.key !== key);
          return updatedTableRows;
        }
        return tableRows;
      });
      return updatedData;
    });

    if (editCondition && record?.id) {
      const params = { quote_item_id: record?.id };
      const response = await deletTableRowApi(params);
      if (response?.data?.status) {
        showSuccessToast(response?.data?.message);
      } else {
        showErrorToast(response?.error?.data?.message);
      }
    }
  };
  const handleErrorMsg = (errorMsg) => {
    switch (errorMsg) {
      case "key delivery_address inside address is missing":
        return "Please enter delivery address!";
      case "key billing_address inside address is missing":
        return "Please enter billing address!";
      case "vendor_product":
        return "Please select vendor!";
      case "price_before_tax":
        return "Please enter price before tax!";
      case "must_select_one_quote":
        return "Please create atleast one quote!";
      case "input":
        return "Please enter product name!";
      case "before_tax":
        return "Please enter price before tax!";
      case "quantity":
        return "Please enter product quantity!";
      default:
        return "Please enter all required information!";
    }
  };
  const handleCheckError = () => {
    let check = false,
      message = "";
    if (!delivery_address?.id) {
      return {
        check: true,
        message: handleErrorMsg(
          "key delivery_address inside address is missing"
        ),
      };
    }
    if (!billing_address?.id) {
      return {
        check: true,
        message: handleErrorMsg(
          "key billing_address inside address is missing"
        ),
      };
    }
    if (dataSource[0].length === 0) {
      return {
        check: true,
        message: handleErrorMsg("must_select_one_quote"),
      };
    }
    if (!selectedSeller[0]) {
      return {
        check: true,
        message: handleErrorMsg("vendor_product"),
      };
    }
    for (let key in dataSource[0][0]) {
      if (key === "before_tax" || key === "quantity" || key === "input") {
        let field = "";
        if (!dataSource[0][0][key]) {
          if (key === "input") {
            field = "input";
          } else if (key === "before_tax") {
            field = "before_tax";
          } else if (key === "quantity") {
            field = "quantity";
          }
          return {
            check: true,
            message: handleErrorMsg(field),
          };
        }
      }
    }
    return { check, message };
  };
  const handleCheckboxChange = (e, record, tableIndex) => {
    const newSelectedRows = e.target.checked
      ? [...selectedRows, { ...record, tableIndex }]
      : selectedRows.filter(
          (item) => !(tableIndex === item.tableIndex && record.key === item.key)
        );
    setSelectedRows(newSelectedRows);
    setIsFixedBarVisible(newSelectedRows.length > 0);
  };
  const handleMoveRow = (key, direction, tableIndex) => {
    const movedArray = dataSource.map((subArray) => [...subArray]);
    const subArray = movedArray[tableIndex];
    const index = subArray.findIndex((item) => item.key === key);
    if (index === -1) return;
    const [movedItem] = subArray.splice(index, 1);
    const newIndex = direction === "up" ? index - 1 : index + 1;
    subArray.splice(newIndex, 0, movedItem);
    movedArray[tableIndex] = subArray;
    setDataSource([...movedArray]);
  };
  const handleCancelOrder = (check) => {
    setDataSource([data]);
    dispatch(getRFQQuoteData({}));
    dispatch(getCreateOrderDeliveryAddress({}));
    dispatch(getDeliveryAddress({}));
    dispatch(getBillingAddress({}));
    dispatch(getCreateOrderBillingAddress({}));
    dispatch(getCreateOrderUserData({}));
    dispatch(getBmpPrice([]));
    dispatch(getGrandPrice({}));
    setLoader(false);
    // dispatch(getRFQListFlag(true));
    if (check === "updateOrder") {
      navigate(`/admin/orders/order-details/${order_id}`, {
        state: { id: order_id },
      });
    } else {
      navigate("/admin/orders");
    }
  };
  function checkBmpIdPresence(arrayOfObjects) {
    for (let obj of arrayOfObjects) {
      if (!obj.hasOwnProperty("bmp_id")) {
        return false; // Return false if bmp_id key is not present in any object
      }
    }
    return true; // Return true if all objects have bmp_id key
  }

  const handleCreateOrder = async (data) => {
    setLoader(true);
    let errorCheck = handleCheckError();
    if (errorCheck?.check) {
      showErrorToast(errorCheck?.message);
      setLoader(false);
      setModalOpen(false);
      return;
    }
    if (!checkBmpIdPresence(selectedSeller)) {
      showErrorToast("Please select vendor");
      setLoader(false);
      return;
    }
    const params = {
      ...(!editCondition && {
        phone_number: data?.phone_number,
        email: data?.email,
      }),
      user_id: userData?.id,
      ...(quote_id && { quote_id: quote_id }),
      terms_and_condition: grandPrice.terms_and_condition || "",
      additional_discount:
        Number(grandPrice?.add_additional_discount || 0) || 0,
      quote_comments: "",
      delivery_fee: 0,
      address: {
        delivery_address: delivery_address?.id,
        billing_address: billing_address?.id,
      },
      sub_total: Number(grandPrice?.sub_total || 0),
      discount: Number(grandPrice?.discount || 0),
      sgst: Number(grandPrice?.cgst || 0),
      cgst: Number(grandPrice?.cgst || 0),
      igst: Number(grandPrice?.igst || 0),
      add_additional_discount:
        Number(grandPrice?.add_additional_discount || 0) || 0,
      total: Number(grandPrice?.total || 0),
      quote_items: {},
      reporting_manager : reportingManager.id
    };

    for (let i = 0; i < selectedSeller.length; i++) {
      const tableData = [];
      dataSource[i].map((rows, index) => {
        tableData.push({
          vendor: selectedSeller[i].bmp_id,
          vendor_product: rows.mob_sku || rows.input,
          product_image: rows.imageURL || "No Image",
          unit: rows.unit_name || 0,
          hsn_code: rows.hsn_sac || "",
          index: index,
          gst: Number(rows?.gst || 0),
          price_before_tax: Number(rows?.before_tax || 0),
          price_after_tax: Number(rows?.after_tax || 0),
          discount: Number(rows?.discount || 0) || 0,
          quantity: Number(rows?.quantity || 0),
          total: Number(rows?.total || 0),
          mob_margin: {
            amount_in: rows.amount_in,
            amount: Number(rows?.amount || 0) || 0,
          },
          bmp_price: Number(rows?.bmp_price || 0) || 0,
          ...(quote_id && {
            product: {
              vendor: selectedSeller[i].bmp_id,
              product_name: rows.mob_sku || rows.input,
              product_image: rows.imageURL || "No Image",
            },
          }),
          ...(quote_id && {
            quote_vendor: rows.quote_vendor,
          }),
          ...(rows.id && {
            id: rows.id,
          }),
        });
        return null;
      });
      const hasBmpId = tableData.some(
        (item) => item.vendor === "MOB_ALL_123" || item.vendor === "MOB_ALL_23"
      );
      if (hasBmpId) {
        showErrorToast("Change the BMP to confirm order");
        setModalOpen(false);
        setLoader(false);
        return;
      }
      // console.log("tableData",tableData);
      // console.log("hasBmpId",hasBmpId);
      params.quote_items[selectedSeller[i].bmp_id] = {
        ...(quote_id && { quote_vendor: bmpPrice[i]?.quote_vendor }),
        total_bmp_price: Number(bmpPrice[i]?.total_bmp_price || 0) || 0,
        additional_bmp_price:
          Number(bmpPrice[i]?.additional_bmp_price || 0) || 0,
        delivery_fee: Number(bmpPrice[i]?.delivery_fee || 0) || 0,
        discount: Number(bmpPrice[i]?.discountPrice || 0) || 0,
        total_of_quote_items: Number(bmpPrice[i]?.subTotal || 0),
        quote_delivery_notes: bmpPrice[i].quote_delivery_notes,
        quote_delivery_dates: bmpPrice[i].quote_delivery_dates,
        quote_delivery_slot: bmpPrice[i].quote_delivery_slot,
        is_cancelled: bmpPrice[i].is_cancelled,
        sgst: Number(bmpPrice[i]?.sgst || 0) || 0,
        cgst: Number(bmpPrice[i]?.sgst || 0) || 0,
        igst: Number(bmpPrice[i]?.igst || 0) || 0,
        additional_bmp_discount:
          Number(bmpPrice[i]?.additional_bmp_discount || 0) || 0,
        additional_bmp_discount_in: bmpPrice[i]?.additional_bmp_discount_in,
        final_bmp_price: Number(bmpPrice[i]?.final_bmp_price || 0) || 0,
        sub_total: Number(bmpPrice[i]?.subTotal || 0) || 0,
        items: tableData,
      };
    }

    if (!editCondition) {
      // if (userId && !location?.state?.data) {
      const response = await createOrderAPI(params);
      if (response?.data?.message && response?.data?.status) {
      
        showSuccessToast(response.data.message);
        setTimeout(() => {
          handleCancelOrder();
        }, 5000);
      } else {
        const inputFieldsValidation = response?.error?.data
          ? Object.keys(response?.error?.data)[0]
          : "Please check quote fields!";
        const errorMsg = handleErrorMsg(
          response?.error?.data?.message || inputFieldsValidation
        );
        showErrorToast(errorMsg);
        setLoader(false);
      }
    } else {
      const response = await updateOrderAPI(params);
      if (response?.data?.message && response?.data?.status) {
    
        showSuccessToast(response?.data?.message);
        setTimeout(() => {
          handleCancelOrder("updateOrder");
        }, 5000);
      } else {
        const inputFieldsValidation = response?.error?.data
          ? Object.keys(response?.error?.data?.data)[0]
          : "Please check quote fields!";
        const errorMsg = handleErrorMsg(
          inputFieldsValidation || response?.error?.data?.message
        );
        showErrorToast(errorMsg);
        setLoader(false);
      }
    }
  };
  const handleConfirmOrder = (data) => {
    handleCreateOrder(data);
  };

  const handleUpdateOrder = () => {
    handleCreateOrder();
    setWarningModal(false);
  };


  const [reportingManager, setReportingManager] = useState(null);
  const [reportingManagersData, setReportingManagersData] = useState([]);
  const [updateReportingManager] = useUpdateReportingManagerMutation();

   // eslint-disable-next-line react-hooks/exhaustive-deps
  const [open, setOpen] = useState(false);
  console.log(open)
  
  
  const handleMenuClick = async (e) => {
    const selectedManager = reportingManagersData.find((reportingManager) => {
      return Number(reportingManager.id) === Number(e.key);
    });
    
    setReportingManager({"id" : selectedManager.id,"reporting_to" :
      selectedManager.reporting_to, 
      "reporting_person" : selectedManager.reporting_person})
      console.log("Reportig set")
      const data = {
        order_id: editOrderData?.data?.order?.order_id,
        reporting_manager : selectedManager.id
      };
      let response;
      response = await updateReportingManager(data);
      showSuccessToast(response?.data?.message);  


    
  }

  const handleOpenChange = (nextOpen, info) => {    
    setOpen(nextOpen);
  };

  const transformReportingManagers = (reportingManagers) => {
    const reportingManagerArray = Array.isArray(reportingManagers) ? reportingManagers : [];
    return reportingManagerArray.map(item => ({
      label: item.reporting_person,
      key: item.id.toString(),
    }));
  };

  const columsHeader = (table, tableIndex, currentSeller, editCondition) => {
    let defaultColumns = [];
    defaultColumns = [
      {
        title: `${table.length} Items`,
        key: "icon",
        dataIndex: "input",
        width: "30%",
        // minWidth: "300px",
        // maxWidth: "400px",
        render: (params, record, index) => {
          return (
            <>
              <Col
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  gap: "8px",
                }}
              >
                <Checkbox
                  onChange={(e) => handleCheckboxChange(e, record, tableIndex)}
                  checked={selectedRows.some(
                    (selectedRow) =>
                      tableIndex === selectedRow.tableIndex &&
                      selectedRow.key === record.key
                  )}
                />
                <Avatar
                  shape="square"
                  size={60}
                  style={{ backgroundColor: "#e5e5e5", cursor: "pointer" }}
                  icon={<UploadImage />}
                  onClick={() => showModal(record, tableIndex)}
                  src={`${baseUrl}${record?.imageURL}`}
                />
                <Dropdown
                  overlay={
                    <Menu
                      style={{
                        maxHeight: "250px",
                        maxWidth: "600px",
                        // overflow: "auto",
                        overflowX: "hidden",
                      }}
                      onClick={(e) => handleSaveProduct(e, tableIndex, record)}
                    >
                      {/* {console.log("BMP", selectedSeller[tableIndex].bmp_id)} */}
                      {/* {console.log("dropDownList", dropDownList)} */}
                      {dropDownList?.map((item, index) => (
                        <>
                          {item.bmp_id ===
                            selectedSeller[tableIndex].bmp_id && (
                            <Menu.Item
                              key={item.key}
                              value={item}
                              style={{ padding: "7px 12px" }}
                            >
                              {item.product_name}
                            </Menu.Item>

                            // {index !== dropDownList.length - 1 && (
                            //   <Divider style={{margin: "5px"}} />
                            // )}
                          )}
                        </>
                      ))}
                    </Menu>
                  }
                  style={{ fontWeight: 500, color: "#0a243f" }}
                  trigger={["click"]}
                >
                  <TextArea
                    className="custom-textarea"
                    rows={3}
                    placeholder="Enter item area"
                    value={params}
                    style={{
                      width: "75%",
                      border: "1px solid #fff",
                      borderRadius: "8px",
                      overflowY: "auto",
                    }}
                    onChange={(e) =>
                      handleSearchText(e, tableIndex, record, currentSeller)
                    }
                  />
                </Dropdown>
              </Col>
            </>
          );
        },
      },
      {
        // minWidth: "300px",
        // maxWidth: "400px",
        width: "22%",
        title: (
          <Col
            style={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Text style={{ fontFamily: "Inter", fontWeight: 500 }}>
              Unit name
            </Text>
            <Text style={{ fontFamily: "Inter", fontWeight: 500 }}>
              Price (Before tax)
            </Text>
          </Col>
        ),
        key: 1,
        dataIndex: "unit_name",
        height: "60px",
        render: (params, record) => {
          return (
            <>
              <UnitNameCell
                params={params}
                record={record}
                dataSource={dataSource}
                tableIndex={tableIndex}
                setDataSource={setDataSource}
              />
            </>
          );
        },
        unit_name: "unit_name",
        before_tax: "before_tax",
        after_tax: "after_tax",
        hsn_sac: "hsn_sac",
        title1: "Unit Name",
        title2: "Before Tax",
        tableIndex: tableIndex,
        total: "total",
        bmp_price: "bmp_price",
      },
      {
        width: "35%",
        flex: 4,
        title: (
          <Col
            style={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Text style={{ fontFamily: "Inter", fontWeight: 500 }}>GST%</Text>
            <Text style={{ fontFamily: "Inter", fontWeight: 500 }}>
              Price (After tax)
            </Text>
            <Text style={{ fontFamily: "Inter", fontWeight: 500 }}>
              Discount
            </Text>
            <Text style={{ fontFamily: "Inter", fontWeight: 500 }}>
              Quantity
            </Text>
          </Col>
        ),
        key: 2,
        dataIndex: "gst",
        render: (params, record) => {
          return (
            <GstCell
              params={params}
              record={record}
              dataSource={dataSource}
              tableIndex={tableIndex}
              setDataSource={setDataSource}
              editCondition={editCondition}
            />
          );
        },
        gst: "gst",
        after_tax: "after_tax",
        discount: "discount",
        quantity: "quantity",
        mob_margin: "mob_margin",
        amount_in: "amount_in",
        amount: "amount",
        bmp_price: "bmp_price",
        total: "total",
        title1: "Column 1 Title",
        title2: "Column 2 Title",
        tableIndex: tableIndex,
      },
      {
        flex: 1,
        width: "13%",
        // width: "150px",
        title: "Total",
        dataIndex: "total",
        align: "center",
        render: (_, record) => {
          return (
            <>
              <Col
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  height: "100%",
                  marginBottom: "20px",
                }}
              >
                <Text
                  level={5}
                  style={{
                    fontWeight: 500,
                    cursor: "not-allowed",
                  }}
                >
                  ₹ {totalPrice(record).toFixed(2)}
                </Text>
                <Popconfirm
                  key={record.key}
                  title={
                    <Text
                      style={{
                        display: "block",
                      }}
                    >
                      Are you sure ?
                    </Text>
                  }
                  placement="left"
                  trigger="click"
                  style={{ width: "150px" }}
                  onConfirm={() =>
                    handleDeleteRow(record.key, tableIndex, record)
                  }
                >
                  <Col
                    className="col-delete-icon"
                    style={{
                      height: "30px",
                      width: "30px",
                      cursor: "pointer",
                      borderRadius: "50%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <DeleteIcon />
                  </Col>
                </Popconfirm>
              </Col>
              <Col
                style={{
                  display: "flex",
                  justifyContent: "end",
                }}
              >
                <Button.Group size="small">
                  <Button
                    value="large"
                    disabled={
                      record?.key ===
                      dataSource[tableIndex][dataSource[tableIndex].length - 1]
                        ?.key
                    }
                    style={{
                      backgroundColor: "#f3f2ef",
                      borderRadius: "8px 0 0 8px",
                      height: "27px",
                    }}
                  >
                    <CaretDownOutlined
                      onClick={() =>
                        handleMoveRow(record.key, "down", tableIndex)
                      }
                    />
                  </Button>
                  <Button
                    value="small"
                    disabled={record.key === dataSource[tableIndex][0]?.key}
                    style={{
                      backgroundColor: "#f3f2ef",
                      borderRadius: "0 8px 8px 0",
                      height: "27px",
                    }}
                  >
                    <CaretUpOutlined
                      onClick={() =>
                        handleMoveRow(record.key, "up", tableIndex)
                      }
                    />
                  </Button>
                </Button.Group>
              </Col>
            </>
          );
        },
      },
    ];
    return defaultColumns;
  };
  return (
    <>
      <Col style={{ padding: "24px", backgroundColor: "#f3f2ef" }}>
        <CreateOrderProfileSection
          handleSetSelector={handleSetSelector}
          userData={userData}
          editQuoteData={editQuoteData}
          cancelOrder={handleCancelOrder}
          editCondition={editCondition}
          reportingManager = {reportingManager}
          reportingManagers = {transformReportingManagers(reportingManagersData)}
          reportingManagersData = {transformReportingManagers(reportingManagersData)}
          setReportingManager = {setReportingManager}
          handleMenuClick = {handleMenuClick}
          handleOpenChange = {handleOpenChange}
          setOpen = {setOpen}

          handleCreateOrder = {handleTicketModal}
          // handleCreateOrder={
          //   editCondition ? handleWarnigModal : handleConfirmOrderModal
          // }
          loading={
            editCondition
              ? isUpdateLoading || loader
              : isCreateLoading || loader
          }

          // handleCreateOrder={handleCreateOrder}
        />
        {isLoadingEditData ? (
          <>
            <TableSkeleton />
          </>
        ) : (
          <>
            {dataSource?.map((table, tableIndex) => {
              const tableColumns = columsHeader(
                table,
                tableIndex,
                selectedSeller[tableIndex],
                editCondition
              );

              return (
                <>
                  {bmpPrice?.[tableIndex]?.is_cancelled ? (
                    <CanceledQuoteTable
                      table={table}
                      tableIndex={tableIndex}
                      selectedSeller={selectedSeller}
                      editCondition={editCondition}
                      dataSource={dataSource}
                      setDataSource={setDataSource}
                      setSelectedSeller={setSelectedSeller}
                      sellerList={sellerList}
                      getAllVendors={getAllVendors}
                      initialValue={initialValue}
                      bmpPrice={bmpPrice}
                      getBmpPrice={getBmpPrice}
                      billingAddressSelector={billing_address}
                    />
                  ) : (
                    <Table
                      key={`${tableIndex}`}
                      className={"rfq-table"}
                      style={{
                        borderRadius: "8px",
                        boxShadow: "0 1px 4px 0 rgba(0, 0, 0, 0.16)",
                        border: "solid 1px #eaeaea",
                        marginBottom: "48px",
                        marginTop: "48px",
                      }}
                      columns={
                        selectedSeller[tableIndex] || tableIndex !== 0
                          ? tableColumns
                          : []
                      }
                      dataSource={
                        selectedSeller[tableIndex] || tableIndex !== 0
                          ? table
                          : []
                      }
                      title={() => (
                        <TableHeader
                          handleAddRow={handleAddRow}
                          tableIndex={tableIndex}
                          handleDeleteTable={handleDeleteTable}
                          selectedSeller={selectedSeller}
                          setSelectedSeller={setSelectedSeller}
                          sellerList={sellerList}
                          getAllVendors={getAllVendors}
                          initialValue={initialValue.venderList}
                          editCondition={editCondition}
                          key={tableIndex}
                          bmpPrice={bmpPrice}
                          getBmpPrice={getBmpPrice}
                          subOrderID={true}
                          getProductApi={getcatalogueListDrawerApi}
                          getProductModalApi={getProductCatalogueDataApi}
                          QuoteCreationFlag={1}
                        />
                      )}
                      footer={() => (
                        <TableFooter
                          handleAddRow={handleAddRow}
                          tableIndex={tableIndex}
                          selectedSeller={selectedSeller}
                          dataSource={dataSource}
                          key={tableIndex}
                          editCondition={editCondition}
                          bmpPrice={bmpPrice}
                          getBmpPrice={getBmpPrice}
                          billingAddressSelector={billing_address}
                        />
                      )}
                      locale={{
                        emptyText: selectedSeller[tableIndex]?.vendor_name ? (
                          ""
                        ) : (
                          <EmptyTable
                            mainText={
                              "Please select the seller to add items to the Order."
                            }
                          />
                        ),
                      }}
                      pagination={false}
                    />
                  )}
                </>
              );
            })}
          </>
        )}
        <AddImages
          addImageModal={addImageModal}
          setAddImageModal={setAddImageModal}
          showModal={showModal}
          handleOk={handleOk}
          handleCancel={handleCancel}
          record={currentRecord}
          currentIndexTable={currentIndexTable}
          dataSource={dataSource}
          setDataSource={setDataSource}
        />
        <Checkout
          AddNewSubOrder={AddNewSubOrder}
          selectedSeller={selectedSeller}
          dataSource={dataSource}
          editCondition={editCondition}
          buttonText={editCondition ? "UPDATE ORDER" : "CONFIRM ORDER"}
          type={"ConfirmOrder"}
          // handleCreateQuote={
          //   editCondition ? handleWarnigModal : handleConfirmOrderModal
          // }
          handleCreateQuote={
            handleTicketModal
          }
          // handleCreateQuote={handleCreateOrder}
          cancelQuote={handleCancelOrder}
          // cancelOrder={handleCancelOrder}
          grandPrice={grandPrice}
          bmpPrice={bmpPrice}
          editQuoteData={editQuoteData}
          getGrandPrice={getGrandPrice}
          disableCondition={editCondition ? !editQuoteData?.id : !userData?.id}
          loading={
            editCondition
              ? isUpdateLoading || loader
              : isCreateLoading || loader
          }
          billingAddressSelector={billing_address}
          data={editCondition ? editQuoteData?.user : userData}
        />
        {isFixedBarVisible && (
          <Affix
            offsetBottom={10}
            offsetTop={10}
            style={{ position: "sticky", zIndex: 999 }}
          >
            <Col
              style={{
                width: "100%",
                height: "80px",
                backgroundColor: "#fff",
                display: "flex",
                padding: "0 40px",
                boxShadow: "0 1px 4px 0 rgba(0, 0, 0, 0.16)",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Col style={{ display: "flex", gap: "24px" }}>
                <Text
                  style={{
                    width: "150px",
                    color: "#0a243f",
                    fontSize: "16px",
                    fontWeight: 500,
                  }}
                >
                  {selectedRows?.length} Items selected
                </Text>
                <Text
                  style={{
                    color: "#2973f0",
                    fontSize: "14px",
                    fontWeight: 500,
                    paddingTop: "2px",
                    cursor: "pointer",
                  }}
                  onClick={deselectAll}
                >
                  Deselect all
                </Text>
              </Col>
              <MoveItemModal
                moveRowsToTable={moveRowsToTable}
                dataSource={dataSource}
                selectedSeller={selectedSeller}
                selectedRows={selectedRows}
              />
            </Col>
          </Affix>
        )}
        <ConfirmOrderModal
          isModalOpen={modalOpen}
          setIsModalOpen={setModalOpen}
          handleConfirmOrder={handleConfirmOrder}
          editQuoteData={editCondition ? editQuoteData : userData}
          phone_number={
            editCondition
              ? editQuoteData?.user?.phone_number
              : userData?.phone_number
          }
          email={editCondition ? editQuoteData?.user?.email : userData?.email}
          loading={editCondition ? isUpdateLoading : isCreateLoading}
        />
        <UpdateOrderModal
          isModalOpen={warningModal}
          setIsModalOpen={setWarningModal}
          handleSaveApi={handleUpdateOrder}
          loading={editCondition ? isUpdateLoading : isCreateLoading}
        />
         <TicketAssignmentModal
         editCondition = {editCondition ? editQuoteData?.user?.email : userData?.email}
         setOpen = {setOpen}
         open = {open}
         handleSave= {handleUpdateOrder}
         handleOpenChange = {handleOpenChange}
         handleMenuClick = {handleMenuClick}
         setReportingManager = {setReportingManager}
         reportingManager = {reportingManager}
         reportingManagers = {transformReportingManagers(reportingManagersData)}
         transformReportingManagers
         isModalOpen={ticketModal}
         setIsModalOpen={handleTicketModal}
         handleTicketModalCancel={handleTicketModalCancel}
         handleConfirmOrderModal={editCondition ? handleWarnigModal : handleConfirmOrderModal}

       //  handleSave = {handleSave}
        // isModalOpen = {isModalOpen}
         //setIsModalOpen = {setIsModalOpen}

          // isModalOpen={warningModal}
          // setIsModalOpen={setWarningModal}
          // handleSaveApi={handleUpdateOrder}
    

        />

      </Col>
      <QuoteLoading
        open={
          editCondition ? isUpdateLoading || loader : isCreateLoading || loader
        }
      />
    </>
  );
};

export default CreateOrder;


