import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  Dropdown,
  Row,
  Typography,
  Avatar,
  Tag,
  Switch,
  Tooltip,
  Menu,
} from "antd";
import SearchInput from "../../../components/Input/SearchInputs/SearchInput";
import {
  // PlusOutlined,
  // DownOutlined,
  CloseOutlined,
  DashOutlined,
  LoadingOutlined,
} from "@ant-design/icons";
import { useNavigate, useParams } from "react-router-dom";
import SearchDropdown from "../../../components/DropDown/SearchDropdown";
import CustomTableWithPagination from "../../../components/CustomTableWithPagination/CustomTableWithPagination";
import { UpArrow } from "../../../components/Icons/UpArrow";
import { DownArrow } from "../../../components/Icons/DownArrow";
import { ReactComponent as DefaultIcon } from "../../../assets/icons/default.svg";
import {
  getInventoryPriceTableData,
  getSellerTableRowData,
  getViewStatus,
} from "../../../redux/slices/sellerCatalogue/action";
import { useDispatch, useSelector } from "react-redux";
import {
  useDeleteVendorProductMutation,
  useLazyGetExistingSellerInventoryPriceQuery,
  // useDeleteSellerCatalogueProductMutation,
  // useLazyGetExistingSellerTableQuery,
  useLazyGetInventoryPriceExcelQuery,
  useUpdateSellerCatalogueProductMutation,
} from "../../../apis/sellerCatalogue";
import {
  inventoryPriceTableSelector,
  // sellerCatalogueTableSelector,
} from "../../../redux/slices/sellerCatalogue/selector";
import TableSkeleton from "../../../components/skeleton/TableSkeleton";
import {
  colorMapping,
  handlePdfDownload,
} from "../../../commonFuntions/CommonUtilFunctions";
import CustomTableInput from "../../../components/Input/CustomTableInput/CustomTableInput";
import { ReactComponent as FlagIcon } from "../../../assets/icons/flagicon.svg";
import { ReactComponent as RedFlagIcon } from "../../../assets/icons/redFlagIcon.svg";
import { ReactComponent as ActiveToggleIcon } from "../../../assets/icons/ActiveToggle.svg";
import { ReactComponent as InactiveToggleIcon } from "../../../assets/icons/InactiveToggle.svg";
import {
  showErrorToast,
  showSuccessToast,
} from "../../../NotificationToast/NotificationToast";
import CustomIconInput from "../../../components/Input/CustomIconInput/CustomIconInput";
import FlagItemModal from "../../../components/Modal/FlagItemModal/FlagItemModal";
import ItemFlagModal from "../../../components/Modal/FlagItemModal/ItemFlagModal";
import { ReactComponent as InfoIcon } from "../../../assets/icons/InfoIcon.svg";
import "./SellerInventoryAndPrice.css";
import { baseUrl } from "../../../commonUtils/commonUtils";
import ManageDeleteModal from "../../../components/Modal/DeleteModal/ManageDeleteModal/ManageDeleteModal";
import CustomSelect from "../../../components/SelectableDropdown/CustomSelect/CustomSelect";
import StatusModal from "../../../components/Modal/StatusModal/StatusModal";
const { Text } = Typography;

const StatusToggle = (props) => {
  const { record, handleChangeRowData, name } = props;
  const [checked, setChecked] = useState(false);
  const onChange = (checked) => {
    setChecked(checked);
    handleChangeRowData(name, checked, record);
  };
  useEffect(() => {
   
    setChecked(record?.vendor_data?.is_active);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      <Switch checked={checked} onChange={onChange} />
    </>
  );
};
const handleTableData = (params) => {
  let initialArrVal = [];
  let parentSubOrder = [];

  params?.results?.products?.map((item, index) => {
    let obj = {
      key: item.id,
      productName: item.product_name,
      image: item.images[0] ? `${baseUrl}${item.images[0]?.image}` : "",
      mob_sku: item.mob_sku,
      brand_name: item.brand_name,
      mrp: item.mrp,
      uom: item.mob_sku,
      category: item.category,
      subCategory: item.sub_category_id,
      expandRow: false,
      subproducts: item.subproducts,
      selling_price: item.your_selling_price,
      dealer_price: item.dealer_price,
      tax: item?.product_tax_code?.tax,
      hsn_code: item?.hsn_code,
      is_available: item?.is_available,
      is_active: item?.is_active,
      status: item?.is_active,
      vendor_data: item?.vendor_data,
      redFlag: false,
      vendor_selling_price: item?.vendor_data?.vendor_selling_price,
      maximum_retail_price: item?.vendor_data?.maximum_retail_price,
      warranty_term: item?.vendor_data?.warranty_term,
      comission_rfq: item?.vendor_data?.comission_rfq,
      // dealer_price: item?.vendor_data?.dealer_price,
      rfq_price: item?.vendor_data?.rfq_price,
      stock: item?.vendor_data?.stock,
      vendor_product_id: item?.vendor_data?.vendor_product_id,
      save: false,
    };
    initialArrVal.push(obj);
    // parentSubOrder.push(obj);
    if (item?.subproducts?.length) {
      item?.subproducts?.map((element, index) => {
        parentSubOrder.push({
          parentKey: item.id,
          key: element.id,
          // icon: <Col className="table-icon">...</Col>,
          productName: element.product_name,
          image: element.images[0]
            ? `${baseUrl}${element.images[0]?.image}`
            : "",
          status: element?.is_active,
          mob_sku: element.mob_sku,
          brand_name: element.brand_name,
          mrp: element.mrp,
          selling_price: element.your_selling_price,
          tax: element?.product_tax_code?.tax,
          hsn_code: element?.hsn_code,
          uom: element.mob_sku,
          category: element.category,
          subCategory: element.sub_category_id,
          is_available: element?.is_available,
          is_active: item?.is_active,
          vendor_data: element?.vendor_data,
          redFlag: false,
          vendor_selling_price: element?.vendor_data?.vendor_selling_price,
          maximum_retail_price: element?.vendor_data?.maximum_retail_price,
          warranty_term: element?.vendor_data?.warranty_term,
          comission_rfq: element?.vendor_data?.comission_rfq,
          dealer_price: element?.vendor_data?.dealer_price,
          rfq_price: element?.vendor_data?.rfq_price,
          stock: element?.vendor_data?.stock,
          vendor_product_id: element?.vendor_data?.vendor_product_id,
          save: false,
        });
        return null;
      });
    }
    return null;
  });

  return { initialArrVal, parentSubOrder };
};

const SellerInventoryAndPrice = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { seller_id } = useParams();
  const tableSelector = useSelector(inventoryPriceTableSelector);

  const [tableDataSource, setTableDataSource] = useState([]);
  const [subProducts, setSubProducts] = useState([]);
  const [
    ,
    // subCategoryLength
    setSubCategoryLength,
  ] = useState(0);
  const [showStatusModal, setShowStatusModal] = useState(false);

  const [productTableData, setProductTableData] = useState({});

  const [currentPage, setCurrentPage] = useState(1);
  const [categories, setCategories] = useState([]);
  const [brands, setBrands] = useState([]);
  // const [addedFrom, setAddedFrom] = useState([]);
  const [searchedValue, setSearchedValue] = useState({});
  const [openModal, setOpenModal] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [openModalFlag, setOpenModalFlag] = useState(false);
  const [sellerTableApi, { data: tableData, isFetching: isLoading }] =
    useLazyGetExistingSellerInventoryPriceQuery();
  const [deleteProductApi] = useDeleteVendorProductMutation();
  const [updateProductApi] = useUpdateSellerCatalogueProductMutation();
  const [excelApi, { isFetching: exlLoading, data: excelApiData }] =
    useLazyGetInventoryPriceExcelQuery();
  const handleOpenModal = (record) => {
    if (!record?.redFlag) {
      dispatch(getSellerTableRowData(record));
      setOpenModal(true);
    }
  };
  const handleOk = () => {
    setOpenModal(false);
  };
  const handleDeleteModalOpen = () => {
    setIsDeleteModalOpen(true);
  };
  const handleDeleteModalCancel = () => {
    setIsDeleteModalOpen(false);
  };
  const handleGOBack = () => {
    setOpenModalFlag(false);
  };

  const handleFlagItem = (record) => {
    let currentTableData = [...tableDataSource];
    currentTableData = currentTableData.map((table, index) => {
      if (table.key === record.key) {
        return { ...table, redFlag: true };
      } else {
        return table;
      }
    });
    setTableDataSource(currentTableData);
    setOpenModal(false);
    setOpenModalFlag(true);
  };
  const _onRowClick = (data) => {
    setProductTableData(data);
    // dispatch(getTableRowData(data));
  };
  // const onEdit = () => {
  //   navigate("/admin/sellers/edit", { state: { id: productTableData?.key } });
  // };
  useEffect(() => {
    sellerTableApi({ id: seller_id });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    if (tableData?.status === true) {
      dispatch(getInventoryPriceTableData(tableData?.data));
      dispatch(
        getViewStatus({
          bmp_id: tableData?.data?.results?.vendor_info?.bmp_id,
          vendor_name: tableData?.data?.results?.vendor_info?.vendor_name,
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tableData]);
  useEffect(() => {
    if (tableSelector) {
      const data = tableSelector ? handleTableData(tableSelector) : "";
      setTableDataSource(data?.initialArrVal || []);
      setSubProducts(data?.parentSubOrder || []);
    }
  }, [tableSelector]);

  const handleChangeRowData = (keyName, inputValue, record) => {
    let currentTableData = [...tableDataSource];
    currentTableData = currentTableData.map((table, index) => {
      if (table.key === record.key) {
        return { ...table, [keyName]: inputValue, save: true };
      } else {
        return table;
      }
    });
    setTableDataSource(currentTableData);
  };
  const handlePageChange = async (currPage) => {
    let brandArray = [];
    let categoriesArray = [];
    brands.map((item) => brandArray.push(item.label));
    categories.map((item) => categoriesArray.push(item.label));
    const params = {
      page: currPage,
      ...(searchedValue && { search: searchedValue?.search }),
      ...(brandArray.length && { brands: brandArray }),
      ...(categoriesArray.length && { category: categoriesArray }),
    };
    setCurrentPage(currPage);
    await sellerTableApi({ id: seller_id, params });
  };

  const handleFilterLibrary = () => {
    let brandArray = [];
    let categoriesArray = [];
    brands.map((item) => brandArray.push(item.label));
    categories.map((item) => categoriesArray.push(item.label));
    sellerTableApi({
      id: seller_id,
      params: {
        ...(brandArray.length && { brands: brandArray }),
        ...(categoriesArray.length && { category: categoriesArray }),
      },
    });
    setCurrentPage(1);
  };
  const handleFilterSelect = (label) => {
    let brandArray = [];
    let categoriesArray = [];
    brands.map((item) => brandArray.push(item?.label));
    categories.map((item) => categoriesArray.push(item?.label));
    if (label === "Categories") {
      sellerTableApi({
        id: seller_id,
        params: {
          ...(brandArray.length && { brands: brandArray }),
          page: 1,
        },
      });
    } else if (label === "Brands") {
      sellerTableApi({
        id: seller_id,
        params: {
          ...(categoriesArray.length && { category: categoriesArray }),
          page: 1,
        },
      });
    }
    setCurrentPage(1);
  };
  const handleClearFilter = () => {
    setCategories([]);
    setBrands([]);
    sellerTableApi({ id: seller_id });
    setCurrentPage(1);
  };
  const handleRowClick = (record, open) => {
    // let currentTableData = [...tableDataSource];
    let currentTableData = tableDataSource.filter((item) => !item.parentKey);
    let parentIndex = "";
    let subOrderList = subProducts.filter(
      (item) => item.parentKey === record.key
    );
    currentTableData = currentTableData.map((table, index) => {
      if (table.key === record.key) {
        parentIndex = index;
        return { ...table, expandRow: open === "up" ? true : false };
      } else {
        return { ...table, expandRow: false };
      }
    });

    if (open === "up") {
      currentTableData.splice(parentIndex + 1, 0, ...subOrderList);
      setSubCategoryLength(subOrderList.length || 0);
    } else {
      // currentTableData.splice(parentIndex + 1, subOrderList.length);
      setSubCategoryLength(0);
    }

    setTableDataSource(currentTableData);
  };
  const onDelete = async () => {
    const params = { vendor_product_id: productTableData?.vendor_product_id };
    const response = await deleteProductApi(params);
    if (response?.data?.status) {
      showSuccessToast(response?.data?.message);
      setIsDeleteModalOpen(false);
      sellerTableApi({ id: seller_id });
      setProductTableData({});
    } else {
      showErrorToast(response?.error?.data?.message);
    }
  };
  const onActivate = async () => {
    const params = {
      vendor_product_id: productTableData?.vendor_product_id,
      is_active: !productTableData?.is_active,
    };
    const response = await updateProductApi(params);
    if (response?.data?.status) {
      showSuccessToast(response?.data?.message);
      sellerTableApi({ id: seller_id });
      setProductTableData({});
      setShowStatusModal(false);
    } else {
      showErrorToast(response?.error?.data?.message);
    }
  };
  const handleSaveProduct = async (record) => {
    const params = {
      vendor_product_id: record?.vendor_product_id,
      vendor_selling_price: record?.vendor_selling_price,
      rfq_price: record?.rfq_price,
      stock: record?.stock,
      maximum_retail_price: record?.maximum_retail_price,
      warranty_term: record?.warranty_term,
      comission_rfq: record?.comission_rfq,
      dealer_price: record?.dealer_price,
      is_active: record?.status,
    };

    const response = await updateProductApi(params);
    if (response?.error) {
      showErrorToast(response?.error?.data?.message);
    }
    if (response?.data?.status) {
      showSuccessToast(response?.data?.message);
      let currentTableData = [...tableDataSource];
      currentTableData = currentTableData.map((table, index) => {
        if (table.key === record.key) {
          return { ...table, save: false };
        } else {
          return table;
        }
      });
      setTableDataSource(currentTableData);
    }
  };
  // const items = [
  //   // {
  //   //   label: "Edit",
  //   //   key: "1",
  //   //   onClick: onEdit,
  //   // },
  //   {
  //     label: "Activate",
  //     key: "2",
  //     // onClick: onActivate,
  //   },
  //   {
  //     label: "Delete",
  //     key: "3",
  //     danger: true,
  //     onClick: onDelete,
  //   },
  // ];
  // const menuProps = {
  //   items,
  // };
  const columns = [
    {
      title: (
        <>
          {/* <PlusOutlined
            style={{
              backgroundColor: "#fff",
              borderRadius: "50%",
              padding: "3px",
            }}
          /> */}
        </>
      ),
      key: "icon",
      dataIndex: "icon",
      fixed: "left",
      width: "50px",
      render: (params, record) => {
        return (
          <>
            <Dropdown
              //  menu={menuProps}
              overlay={
                <Menu>
                  <>
                    <Menu.Item
                      onClick={() => setShowStatusModal(true)}
                      style={{ padding: "7px 12px" }}
                    >
                      <Col className="container-list">
                        <Text className="select-text">
                          {record?.is_active ? "Deactivate" : "Activate"}
                        </Text>
                      </Col>
                    </Menu.Item>
                    <Menu.Item
                      onClick={handleDeleteModalOpen}
                      style={{
                        padding: "7px 12px",
                      }}
                    >
                      <Col className="container-list">
                        <Text
                          className="select-text"
                          style={{ color: "#bf2600" }}
                        >
                          Delete
                        </Text>
                      </Col>
                    </Menu.Item>
                  </>
                </Menu>
              }
              trigger={"click"}
            >
              <DashOutlined onClick={() => _onRowClick(record)} />
            </Dropdown>
          </>
        );
      },
      // height: "60px",
    },
    {
      title: "Product Name",
      dataIndex: "product_name",
      key: "product_name",
      fixed: "left",
      width: "393px",
      render: (params, record) => {
        return (
          <>
            <Col
              style={{
                display: "flex",
                gap: "20px",
                alignItems: "center",
                paddingLeft: record.parentKey ? "60px" : "0px",
              }}
            >
              {!(record?.parentKey || !record?.subproducts?.length) && (
                <Col>
                  {record.expandRow ? (
                    <UpArrow
                      className={"cursor"}
                      onClick={() => handleRowClick(record, "down")}
                    />
                  ) : (
                    <DownArrow
                      className={"cursor"}
                      onClick={() => handleRowClick(record, "up")}
                    />
                  )}
                </Col>
              )}
              <Col>
                <Avatar
                  style={{
                    width: "48px",
                    height: "48px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    backgroundColor: "#f1f1f2",
                  }}
                  shape="square"
                  src={record.image}
                  alt="Avatar"
                  icon={<DefaultIcon width={20} height={20} />}
                />
              </Col>
              <Col style={{ fontWeight: 500 }}>{record.productName}</Col>
            </Col>
          </>
        );
      },
    },
    {
      title: "Added",
      dataIndex: "added_from",
      key: "added_from",
      width: "150px",
      render: (param, record) => {
        return (
          <>
            <Tag
              className={`${
                colorMapping["MOB library"]
                // colorMapping[record?.is_available ? "MOB library" : "locally"]
              }`}
            >
              {/* {record?.is_available ? "MOB library" : "Locally"} */}
              {"MOB library"}
            </Tag>
          </>
        );
      },
    },
    {
      title: "SKU",
      dataIndex: "mob_sku",
      key: "mob_sku",
      width: "150px",
    },
    
    {
      title: <Col style={{ textAlign: "center" }}>Tax %</Col>,
      dataIndex: "tax",
      key: "tax",
      width: "120px",
      render: (param, record) => {
        return (
          <>
            <CustomIconInput
              modalOpen={handleOpenModal}
              handleOk={handleOk}
              addonAfter={record?.redFlag ? <RedFlagIcon /> : <FlagIcon />}
              disable={true}
              value={record.tax}
              name={"tax"}
              handleChangeRowData={handleChangeRowData}
              record={record}
              toolTipText={
                "Tax % is not editable as the item is imported from the mob library. If you do think that the tax % is not accurate please click on the flag option and our team will look into it."
              }
            />
          </>
        );
      },
    },
    {
      title: (
        <Col className="tooltip-container">
          <Text className="heading-text"> Selling price</Text>
          <Tooltip
            className="heading-tooltip"
            title={"Selling price is tax inclusive."}
            color={"#fff"}
          >
            <InfoIcon style={{ cursor: "pointer" }} />
          </Tooltip>
        </Col>
      ),
      dataIndex: "vendor_selling_price",
      key: "vendor_selling_price",
      width: "150px",
      render: (param, record) => {
        return (
          <CustomTableInput
            prefix={"₹"}
            value={record.vendor_data.vendor_selling_price}
            name={"vendor_selling_price"}
            handleChangeRowData={handleChangeRowData}
            record={record}
            check={"inventory"}
            greaterValue={record?.mrp}
            errorText={"SP"}
            endText={"mrp"}
            mrpCheck={true}
          />
        );
      },
    },
    {
      title: (
        <Col className="tooltip-container">
          <Text className="heading-text">mob RFQ price</Text>
          <Tooltip
            className="heading-tooltip"
            title={"RFQ price is tax inclusive."}
            color={"#fff"}
          >
            <InfoIcon style={{ cursor: "pointer" }} />
          </Tooltip>
        </Col>
      ),
      dataIndex: "rfq_price",
      key: "rfq_price",
      width: "150px",
      render: (param, record) => {
        return (
          <CustomTableInput
            prefix={"₹"}
            value={record.vendor_data.rfq_price}
            name={"rfq_price"}
            handleChangeRowData={handleChangeRowData}
            record={record}
            check={"inventory"}
            greaterValue={record?.vendor_selling_price}
            errorText={"RFQ"}
            endText={"SP"}
          />
        );
      },
    },
    {
      title: (
        <Col className="tooltip-container">
          <Text className="heading-text"> MOB Purchase price</Text>
          <Tooltip
            className="heading-tooltip"
            title={"MOB Purchase is tax inclusive."}
            color={"#fff"}
          >
            <InfoIcon style={{ cursor: "pointer" }} />
          </Tooltip>
        </Col>
      ),
      dataIndex: "dealer_price",
      key: "dealer_price",
      width: "150px",
      
      render: (param, record) => {
        return (
          <CustomTableInput
            prefix={"₹"}
            value={record.vendor_data.dealer_price}
            name={"dealer_price"}
            handleChangeRowData={handleChangeRowData}
            record={record}
            check={"inventory"}
            greaterValue={record?.mrp}
            errorText={"DP"}
            endText={"mrp"}
            mrpCheck={true}
          />
        );
      },
    },
    {
      title: "MRP",
      dataIndex: "mrp",
      key: "mrp",
      width: "140px",
      render: (params) => {
        return <Text>₹ {params ? params?.toFixed(2) : "--"}</Text>;
      },
    },
    {
      title: "Stock",
      dataIndex: "stock",
      key: "stock",
      width: "120px",
      render: (param, record) => {
        return (
          <CustomTableInput
            type="number"
            minValue={0}
            value={record.vendor_data.stock}
            name={"stock"}
            handleChangeRowData={handleChangeRowData}
            record={record}
            conditionTest={"stock"}
          />
        );
      },
    },
    {
      title: (
        <Col className="tooltip-container">
          <Text className="heading-text"> Status</Text>
          <Tooltip
            className="heading-tooltip"
            title={
              <>
                <Col className="status-container">
                  <Text className="tooltip-heading">
                    The status shows if the product is active on mad over
                    buildings (mob) website.
                  </Text>
                  <Col className="toggle-container">
                    <ActiveToggleIcon />
                    <Text>Active</Text>
                  </Col>
                  <Col className="toggle-container">
                    <InactiveToggleIcon />
                    <Text>Inactive</Text>
                  </Col>
                </Col>
              </>
            }
            color={"#fff"}
          >
            <InfoIcon style={{ cursor: "pointer" }} />
          </Tooltip>
        </Col>
      ),
      dataIndex: "status",
      key: "status",
      width: "180px",
      render: (param, record) => {
        return (
          <>
            <Col style={{ display: "flex", gap: "40px", alignItems: "center" }}>
              <StatusToggle
                param={param}
                record={record}
                name={"status"}
                handleChangeRowData={handleChangeRowData}
              />
              {record?.save && (
                <Button
                  style={{
                    backgroundColor: "#0354a3",
                    color: "#fff",
                    height: "38px",
                  }}
                  onClick={() => handleSaveProduct(record)}
                >
                  Save
                </Button>
              )}
            </Col>
          </>
        );
      },
    },
  ];

  const handleNavigatePricing = (id) => {
    navigate(`/admin/sellers/update-pricing/${seller_id}`);
  };
  // const startItemIndex = (currentPage - 1) * 10;
  // const endItemIndex = startItemIndex + 10;
  // const currentData = tableDataSource.slice(startItemIndex, endItemIndex);

  const handleSellerLibNavigation = () => {
    navigate(`/admin/sellers/mob-library/${seller_id}`);
  };
  const handleSearchedValue = (params) => {
    setSearchedValue(params);
    setCurrentPage(1);
  };
  const handleExportApi = () => {
    let brandArray = [];
    let categoriesArray = [];
    brands.map((item) => brandArray.push(item.label));
    categories.map((item) => categoriesArray.push(item.label));
    excelApi({
      id: seller_id,
      params: {
        ...(brandArray.length && { brands: brandArray }),
        ...(categoriesArray.length && { category: categoriesArray }),
        ...(searchedValue.search && { search: searchedValue.search }),
      },
    });

    if (excelApiData?.data?.url) {
      handlePdfDownload(excelApiData?.data?.url);
    }

    // excelApi();
  };
  const options = [
    {
      key: 1,
      value: "Excel",
      label: "Excel",
    },
  ];
  return (
    <>
      <Col style={{ padding: "2rem" }}>
        <Row>
          <Col
            style={{
              display: "flex",
              alignItems: "center",
              gap: "15px",
            }}
          >
            <Text
              style={{
                fontSize: "20px",
                fontWeight: 500,
                lineHeight: 1.6,
              }}
            >
              Inventory & price
            </Text>
            <Text
              style={{
                fontSize: "16px",
                fontWeight: 400,
                lineHeight: 1.6,
              }}
            >
              {tableSelector?.pagination?.total_entries || 0} items
            </Text>
          </Col>
        </Row>
        <Row
          style={{
            marginTop: "20px",
            marginBottom: "20px",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Col
            style={{
              display: "flex",
              gap: "16px",
            }}
          >
            <SearchInput
              size={"large"}
              placeholder={"Search by name, description, SKU"}
              getLibraryApi={sellerTableApi}
              width={"400px"}
              id={seller_id}
              handleSearchedValue={handleSearchedValue}
            />
            <CustomSelect
              placeholder={"Export"}
              width={"150px"}
              options={options}
              handleChange={handleExportApi}
              value={exlLoading ? <LoadingOutlined /> : "Export"}
              disabled={exlLoading ? true : false}
              loading={exlLoading}
            />
          </Col>
          <Col
            style={{
              display: "flex",
              gap: "16px",
            }}
          >
            <Button
              size="large"
              style={{ fontWeight: 500, height: "48px" }}
              onClick={handleNavigatePricing}
            >
              Bulk price upload
            </Button>
            <Button
              size="large"
              style={{
                backgroundColor: "#0354a3",
                color: "#fff",
                fontWeight: 500,
                height: "48px",
              }}
              onClick={handleSellerLibNavigation}
            >
              Add item from library
            </Button>
          </Col>
        </Row>
        <Row
          style={{
            marginBottom: "20px",
          }}
        >
          <Col
            style={{
              display: "flex",
              gap: "12px",
              alignItems: "center",
              height: "60px",
            }}
          >
            <SearchDropdown
              list={tableSelector?.results?.categories}
              label={"Categories"}
              placeholder={"Search categories"}
              selectedItems={categories}
              setSelectedItems={setCategories}
              handleFilterLibrary={handleFilterLibrary}
              handleFilterSelect={handleFilterSelect}
            />
            <SearchDropdown
              list={tableSelector?.results?.brands}
              label={"Brands"}
              placeholder={"Search brands"}
              selectedItems={brands}
              setSelectedItems={setBrands}
              handleFilterLibrary={handleFilterLibrary}
              handleFilterSelect={handleFilterSelect}
            />
            {/* <SearchDropdown
              // list={tableSelector?.results?.brands}
              label={"Added from"}
              placeholder={"Added from"}
              selectedItems={addedFrom}
              setSelectedItems={setAddedFrom}
              handleFilterLibrary={handleFilterLibrary}
            /> */}
            {/* <Select
            defaultValue="Sort"
            style={{
              width: 120,
              height: "48px",
            }}
            // onChange={handleChange}
            options={[]}
          /> */}
            {(brands?.length !== 0 || categories?.length !== 0) && (
              <Button
                style={{
                  backgroundColor: "#f2f2f2",
                  borderRadius: "30px",
                  height: "48px",
                  fontWeight: 500,
                  border: "none",
                }}
                onClick={handleClearFilter}
              >
                <CloseOutlined /> Clear filters
              </Button>
            )}
          </Col>
        </Row>
        <CustomTableWithPagination
          className="mob-list-table"
          tableDataSource={tableDataSource || []}
          tableColumns={columns}
          locale={{
            emptyText: isLoading ? <TableSkeleton length={30} /> : "",
          }}
          scroll={{ x: "100%" }}
          currentPage={currentPage}
          handlePageChange={handlePageChange}
          itemsPerPage={50}
          totalEntries={
            !isLoading && tableData?.data?.pagination?.total_entries
          }
          isLoading={tableDataSource.length && isLoading}
        />
        <ManageDeleteModal
          handleOk={handleDeleteModalCancel}
          open={isDeleteModalOpen}
          handleSave={onDelete}
          data={`this product ${productTableData?.productName}`}
        />
        <FlagItemModal
          open={openModal}
          handleOk={handleOk}
          // handleCancel={handleCancel}
          handleSave={handleFlagItem}
        />
        <ItemFlagModal open={openModalFlag} handleOk={handleGOBack} />
        <StatusModal
          open={showStatusModal}
          setOpen={setShowStatusModal}
          handleOk={onActivate}
          data={productTableData}
        />
      </Col>
    </>
  );
};

export default SellerInventoryAndPrice;
