import React, { useEffect, useState ,useCallback} from "react";
import "./CustomerProjects.css";
import { debounce } from "lodash";
import { useNavigate,useParams } from "react-router";
import { Button, Row } from "antd";

import {
  useLazyGetCustProjectListQuery, 
} from "../../../apis/ManageOrders";


import { ArrowLeftOutlined } from "@ant-design/icons";


import ProjectNavbar from "./ProjectNavbar";
import ProjectGridView from "./ProjectGridView";
import ProjectListView from "./ProjectListView";
import {
  checkEmptyValidation,
  checkPhoneNumberValidation,
  checkgstOptionalValidation,
} from "../../../commonUtils/commonUtils";


import { useUpdateProjectMutation } from "../../../apis/ManageOrders";

const CustomerProjects = () => {
  const navigate = useNavigate();
  const queryparams = useParams();
  const  seller_id  = queryparams?.phone_number;
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [currPageToShow, setCurrPageToShow] = useState(1);
   // eslint-disable-next-line 
  const [orderInpTxt, setOrderInpTxt] = useState("");
 
  const [userAddressDetails, setuserAddressDetails] = useState({});
  const [viewType, setViewType] = useState('grid'); // 'table' or 'grid'
   // eslint-disable-next-line 
  const [file, setFile] = useState("");
   // eslint-disable-next-line 
  const [fileSize, setFileSize] = useState("");
   // eslint-disable-next-line 
  const [addAddressFlag, SetaddAddressFlag] = useState(false);
   // eslint-disable-next-line 
  const [isSubmitClick, setIsSubmitClick] = useState(false);
   // eslint-disable-next-line   
  const [addressType, setAddressType] = useState("");
   // eslint-disable-next-line 
  const [cityDisabled, setcityDisabled] = useState(false);
   // eslint-disable-next-line 
  const [stateDisabled, setstateDisabled] = useState(false);

  const [updateProject] = useUpdateProjectMutation();

  const [orderSortBy, setOrderSortBy] = useState();

  const EditAddressDetails = (data) => {
   
    const siteDeliveryData = {
      project_id: data?.project_id,
      project_name: data?.project_name,
      project_image: data?.project_image,
      Projectcity: data?.city,
      Projectstate: data?.site_delivery?.state,
      name: data?.site_delivery?.name,
      phone_number: data?.site_delivery?.phone_number,
      email: data?.site_delivery?.email,
      address_line_1: data?.site_delivery?.address_line_1,
      address_line_2: data?.site_delivery?.address_line_2,
      city: data?.site_delivery?.city,
      state: data?.site_delivery?.state,
      pincode: data?.site_delivery?.pincode,
      gst_number: data?.site_delivery?.gst_number,
      google_map_link: data?.site_delivery?.google_map_link,
      site_person: data?.site_delivery?.site_person,
      site_person_mobile: data?.site_delivery?.site_person_mobile,
      id:data?.site_delivery?.id
    };
    console.log(siteDeliveryData);
    setuserAddressDetails(siteDeliveryData);
  };
const handleCancel = () => {
    // dispatch(updateOpenAddAddressModal(false));
    setuserAddressDetails({});
    setIsModalVisible(false);
  };
  const [
    getProjectDetailApi,
    {data: projectDetailsData, isFetching: fetchingProjectDetails},
  ] = useLazyGetCustProjectListQuery();

  const handleUserAddressCreation = async () => {
    let response;
    console.log(userAddressDetails);
    if (
      checkEmptyValidation(userAddressDetails?.name) &&
      checkEmptyValidation(userAddressDetails?.state) &&
      checkEmptyValidation(userAddressDetails?.city) &&
      checkPhoneNumberValidation(userAddressDetails?.phone_number) &&
      checkgstOptionalValidation(userAddressDetails?.gst_number)
    ) {
      if (userAddressDetails) {
        const siteDeliveryData = {
          id:userAddressDetails?.id,
          name: userAddressDetails.name,
          phone_number: userAddressDetails.phone_number,
          email: userAddressDetails.email,
          address_line_1: userAddressDetails.address_line_1,
          address_line_2: userAddressDetails.address_line_2,
          city: userAddressDetails.city,
          state: userAddressDetails.state,
          pincode: userAddressDetails.pincode,
          gst_number: userAddressDetails.gst_number,
          google_map_link: userAddressDetails.google_map_link,
          site_person: userAddressDetails.site_person,
          site_person_mobile: userAddressDetails.site_person_mobile,
        };
        const body = {
          project_id: userAddressDetails.project_id,
          project_name: userAddressDetails?.project_name,
          city: userAddressDetails?.Projectcity,
          site_delivery: 0,
          address: siteDeliveryData,
          project_image: file?.originFileObj,
        };
        response = await updateProject({
          id: seller_id,
          body: body,
        });

        if (response?.data?.status) {
          setIsModalVisible(false);
          setFile("");       
          setFileSize("");
          setuserAddressDetails({})
          getProjectDetailApi({...(seller_id && { id: seller_id }),params: { page: 1 }  });
        }
      }
    } else {
      setIsSubmitClick(true);
    }
  };
  const handleInputChange = (event) => {
    setOrderInpTxt(event.target.value);
    setOrderSortBy();
  };
  const handleTrigger = (orderInpTxt) => {
    // getProjectDetailApi({ params: { search: orderInpTxt } });
    getProjectDetailApi({...(queryparams?.phone_number && { id: queryparams?.phone_number }),params: { page: currPageToShow ,search: orderInpTxt } });
  };
  const handleChange = (orderInpTxt) => {
    searchTextDebouncedTrigger(orderInpTxt);
  };
   // eslint-disable-next-line react-hooks/exhaustive-deps
   const searchTextDebouncedTrigger = useCallback(
    debounce(handleTrigger, 1000),
    []
  );
 
  useEffect(() => {
    // getProjectDetailApi({params: {search: orderInpTxt}});  
    // getProjectDetailApi({...(queryparams?.phone_number && { id: queryparams?.phone_number }), });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    if (orderInpTxt && orderInpTxt !== "") {
      handleChange(orderInpTxt);
    } else if (orderSortBy && orderSortBy !== "") {
      handleChange(orderSortBy);
    } else {
      // getProjectDetailApi({ params: { page: currPageToShow }  });
      getProjectDetailApi({...(queryparams?.phone_number && { id: queryparams?.phone_number }),params: { page: currPageToShow } });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderInpTxt, orderSortBy, currPageToShow]);
  
  const handleBackNavigate = () => {
    navigate(-1);
  };
  const footer = (
    <Row className="editAddressFooterParent">
      <Button
        className="editAddressFooterButton"
        onClick={() => {
          // dispatch(updateOpenAddAddressModal(false));
          setIsSubmitClick(false);
          setuserAddressDetails({});
          setIsModalVisible(false);
          setFile("");
          setcityDisabled(false);
          setstateDisabled(false);
        }}
      >
        CANCEL
      </Button>

      <Button
        // loading={creatingAddress}
        className="editAddressFooterButton editFooterSaveButton"
        onClick={handleUserAddressCreation}
      >
        SAVE
      </Button>
    </Row>
  );
  return (
    <Row
      className="customerOrderContainer"
      //   className={
      //     selectedKey == "myAccountOrder"
      //       ? "myAccountMainTableContentParentResponsive"
      //       : "myAccountMainTableContentParent"
      //   }
    >
      <Button
        className="btn"
        icon={<ArrowLeftOutlined />}
        onClick={handleBackNavigate}
      >
        BACK
      </Button>
      
    
      <ProjectNavbar
          isFetching={fetchingProjectDetails}
          projectDetailsData={projectDetailsData?.data}        
          orderSortBy={orderSortBy}
          setOrderSortBy={setOrderSortBy}
          setOrderInpTxt={setOrderInpTxt}
          orderInpTxt={orderInpTxt}
          handleInputChange={handleInputChange}
          setViewType={setViewType}
          viewType={viewType}
          getProjectDetailApi={getProjectDetailApi}
          seller_id={seller_id}
          file={file}
        
        />
      
      {viewType === 'table' ? (
        <ProjectGridView
        EditAddressDetails={EditAddressDetails}
        handleCancel={handleCancel}
        projectData={projectDetailsData?.data}
        footer={footer}
        handleUserAddressCreation={handleUserAddressCreation}
        isModalVisible={isModalVisible}
        setIsModalVisible={setIsModalVisible}
        currPageToShow={currPageToShow}
        setCurrPageToShow={setCurrPageToShow}
        userAddressDetails={userAddressDetails}
        setuserAddressDetails={setuserAddressDetails}
        isSubmitClick={isSubmitClick}
        isFetching={fetchingProjectDetails}
        setFileSize={setFileSize}
        setFile={setFile}       
        cityDisabled={cityDisabled}
        setcityDisabled={setcityDisabled}
        stateDisabled={stateDisabled}
        setstateDisabled={setstateDisabled}
        file={file}
          />
      ) : (
        <ProjectListView 
        EditAddressDetails={EditAddressDetails}
          handleCancel={handleCancel}
          projectData={projectDetailsData?.data}
          footer={footer}
          handleUserAddressCreation={handleUserAddressCreation}
          isModalVisible={isModalVisible}
          setIsModalVisible={setIsModalVisible}
          currPageToShow={currPageToShow}
          setCurrPageToShow={setCurrPageToShow}
          userAddressDetails={userAddressDetails}
          setuserAddressDetails={setuserAddressDetails}
          isSubmitClick={isSubmitClick}
          isFetching={fetchingProjectDetails}
          setFileSize={setFileSize}
          setFile={setFile}        
          cityDisabled={cityDisabled}
          setcityDisabled={setcityDisabled}
          stateDisabled={stateDisabled}
          setstateDisabled={setstateDisabled}
          file={file}
       />
      )}

      
    </Row>
  );
};

export default CustomerProjects;
