import React from "react";
import ProjectsUpdateModal from "./ProjectsUpdateModal";

const EditProjectAddressModal = ({
  isMobile,
  IsModalVisible,
  setuserAddressDetails,
  userAddressDetails,
  handleUserAddressCreation,
  addressType,
  setAddressType,
  creatingAddress,
  handleCancel,
  setFile,
  setFileSize,
  addAddressFlag,
  SetaddAddressFlag,
  isSubmitClick,
  cityDisabled,
  setcityDisabled,
  stateDisabled,
  setstateDisabled,
  file
}) => {
  return (
    <>
      <ProjectsUpdateModal
        setUserNewAddress={setuserAddressDetails}
        userNewAddress={userAddressDetails}
        addressType={addressType}
        setAddressType={setAddressType}
        setFile={setFile}
        setFileSize={setFileSize}
        addAddressFlag={addAddressFlag}
        SetaddAddressFlag={SetaddAddressFlag}
        isSubmitClick={isSubmitClick}
        cityDisabled={cityDisabled}
        setcityDisabled={setcityDisabled}
        stateDisabled={stateDisabled}
        setstateDisabled={setstateDisabled}
        file={file}
      />
    </>
  );
};

export default EditProjectAddressModal;
