import React,{useState} from "react";
import { Col, Modal, DatePicker, Row, Typography, Input, Button } from "antd";
import CustomDropdown from "../AddPaymentModal/CustomDropdown";
import { TimeDropdownData } from "../../../../ManageOrders/OrderDetails/OrderConstant/Constant";
import dayjs from "dayjs";
import "dayjs/locale/en";
const { Text } = Typography;
const { TextArea } = Input;

// import "./PaymentSummaryModal.css";
const DeliverySlot = ({
  handleOk,
  isModalOpen,
  setModalStates,
  DeliveryDateValue,
  SelectedTime,
  setDeliveryDateValue,
  setSelectedTime,
  ExistingDate,
  ExistingTime,
  setDeliveryComments,
  DeliveryComments,
  DeliverySubOrderId,
  index,
}) => {


  const [date, setDate] = useState("");

  // useEffect(() => {
  //   console.log("Date",index)
  //   if (ExistingDate) {
  //     const newDateValue = dayjs(ExistingDate);
  //     onChange(newDateValue, ExistingDate);
  //   }
  //   setSelectedTime(SelectedTime);

  // }, []); // dependency array with ExistingDate

  const handleInputChange = (field, value) => {
    // if (field === "comment" && value !== "") {
    //     setHandleError({ ...handleError, ref_no: false });
    //   }
    setDeliveryComments(value);
  };
  const handleCancel = () => {
    setDeliveryComments("");
    setDeliveryDateValue("");
    setSelectedTime("Early morning 6am - 9am");
    setModalStates((prevStates) => ({
      ...prevStates,
      [index]: { isOpen: false },
    }));
  };
  const onChange = (date, dateString) => {
    const currentDate = dayjs(date).format("YYYY-MM-DD");
    setDeliveryDateValue(date);
    setDate(currentDate);
  };
  const handTimeClick = (selectedValue) => {
    setSelectedTime(selectedValue);
  };
  return (
    <>
      <Modal
        title="Edit delivery date and time"
        open={isModalOpen}
        onCancel={handleCancel}
        footer={
          <Col className="addFile-wrapper">
            <Button key="back" className="cancel-btn" onClick={handleCancel}>
              CANCEL
            </Button>
            <Button
              key="submit"
              type="primary"
              className="submit-btn"
              onClick={handleOk}
              //  loading={isFetching}
            >
              SAVE{" "}
            </Button>
          </Col>
        }
        className=""
      >
        <Col className="choose-vehicle-logistics">
          <Col className="payment-link" style={{ display: "block" }}>
            <Col className="link-wrapper1">
              <Row>
                <Col span={10}>
                  <Text>Delivery by: </Text>{" "}
                  <Text>{dayjs(ExistingDate).format("DD MMM YYYY")}</Text>
                </Col>
                <Col span={14} style={{ textAlign: "right" }}>
                  <Text>Delivery by: </Text>{" "}
                  {ExistingTime ? ExistingTime : "N/A"}
                </Col>
              </Row>
            </Col>
          </Col>

          <Row style={{ marginTop: "20px" }}>
            <Col span={24}>
              <Text className="logistics-partner-txt" style={{}}>
                Delivery date and time{" "}
                <span style={{ color: "#bf2600" }}>*</span>
              </Text>
            </Col>
            <Col
              className="dropdown-wrapper1"
              style={{ width: "40%", marginTop: "10px", marginRight: "10px" }}
            >
              <DatePicker
                format={"DD-MMM-YYYY"}
                style={{
                  height: "46px",
                  width: "100%",
                  borderRadius: "8px",
                  border: "solid 1px #bac7d5;",
                  borderColor: date ? "#0a243f" : "#dedede",
                }}
                value={DeliveryDateValue}
                onChange={onChange}
              />
            </Col>
            <Col
              className="dropdown-wrapper"
              style={{ width: "57%", marginTop: "10px" }}
            >
              <CustomDropdown
                options={TimeDropdownData}
                handleOptionClick={handTimeClick}
                selectedText={SelectedTime}
                className={"DeliverySlotClass"}
              />
            </Col>
          </Row>
          <Row style={{ marginTop: "20px" }}>
            <Col style={{ width: "100%" }}>
              <Text>
                Comment <span style={{ color: "#bf2600" }}>*</span>
              </Text>
              <TextArea
                rows={4}
                value={DeliveryComments}
                onChange={(e) => handleInputChange("comment", e.target.value)}
                style={{ marginTop: "10px" }}
              />
            </Col>
          </Row>
        </Col>
      </Modal>
    </>
  );
};

export default DeliverySlot;
