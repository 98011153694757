import React from 'react';
import {Skeleton, Card, Avatar, Dropdown, Menu, Space, Typography,Row,Col } from 'antd';

const { Text } = Typography;

const getNameInitials = (name) => {
if (!name) return '';
const nameParts = name.split(' ');
return nameParts.map(part => part[0].toUpperCase()).join('');
};

const ReportingCardVertical = ({ isLoading,reportingManager, reportingManagers,
open, handleMenuClick, handleOpenChange }) => {

return (
<>
  {isLoading ? (
  <Col className="skeleton-input">
  <Skeleton.Input active size={"small"} block={true} />
  </Col>
  ) : (

  <Card bordered={true} style={{ 
    height:80,marginLeft: 450,padding:0, marginTop: '0px', width: 590 }}>
    <Row gutter={6} align="middle">
      {/* Assigned To */}
      <Col span={4}>
      <Text strong style={{color:"#6c7c8c"}}>Assigned to:</Text>
      </Col>
      <Col span={7}>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        {reportingManager === null ? (
        <Text type="danger">Not assigned</Text>
        ) : (
        <div style={{ 
                maxWidth: '150px', // Set the width you desire 
                whiteSpace: 'nowrap', 
                overflow: 'hidden', 
                textOverflow: 'ellipsis' 
              }}>
          <Avatar style={{ backgroundColor: '#4295a5', marginRight: '8px' }}>
            {getNameInitials(reportingManager?.reporting_person)}
          </Avatar>
          <Text style={{ 
    overflow: 'hidden', 
    textOverflow: 'ellipsis', 
    whiteSpace: 'nowrap', 
    maxWidth: '15px' // Set the desired width
  }}>{reportingManager?.reporting_person}</Text>
        </div>
        )}
        {/* Edit Icon */}
        <Dropdown overlay={<Menu items={reportingManagers} onClick={handleMenuClick} />}
        onOpenChange={handleOpenChange}
        open={open}
        style={{ float: 'right' }}
        >

        <Space onClick={(e)=> e.preventDefault()}

          style={{ marginLeft: '8px', color: 'blue', cursor: 'pointer' }}>✏️</Space>


        </Dropdown>



      </div>
      </Col>


      <Col span={4}>
      <Text strong style={{color:"#6c7c8c"}}>Secondary:</Text>
      </Col>
      {reportingManager === null ? (
      <div style={{ display: 'flex', alignItems: 'center' }}>
      
      <Text type="danger">Not assigned</Text>
    </div>
      ) : (
      <Col span={7}>
      <div style={{ display: 'flex', alignItems: 'center', overflow: 'hidden', 
    textOverflow: 'ellipsis', 
    whiteSpace: 'nowrap', 
    maxWidth: '150px' }}>
        <Avatar style={{ backgroundColor: '#4295a5', marginRight: '8px' }}>
          {getNameInitials(reportingManager?.reporting_to)}
        </Avatar>
        <Text style={{ 
    overflow: 'hidden', 
    textOverflow: 'ellipsis', 
    whiteSpace: 'nowrap', 
    maxWidth: '150px' // Set the desired width
  }}>{reportingManager?.reporting_to}</Text>
      </div>
      </Col>
      )}
    </Row>
  </Card>
  )}
</>
);
};

export default ReportingCardVertical;