import React from 'react';
import { Card, Avatar, Dropdown, Menu,  Typography } from 'antd';
import { Button, } from 'antd';
const { Text } = Typography;

const getNameInitials = (name) => {
if (!name) return '';
const nameParts = name.split(' ');
return nameParts.map(part => part[0].toUpperCase()).join('');
};



const ReportingCard = ({ reportingManager,
   reportingManagers,
open, handleMenuClick, handleOpenChange }) => {

const menu = (
<Menu onClick={handleMenuClick}>
  {reportingManagers.map((manager, index) => (

  <Menu.Item key={manager.key}>
    <Avatar style={{ backgroundColor: '#4295a5' }}>
      {getNameInitials(manager.label)}
    </Avatar>
    <Text style={{ marginLeft: 8 }}> {manager.label}</Text>
  </Menu.Item>
  ))}
</Menu>
);

return (
<Card bordered={true} style={{ marginTop: '10px' }}>
  <div style={{ marginBottom: 16 }}>
    <Text strong style={{color:"#6c7c8c"}}>Assigned to</Text>
    <div style={{ color: 'red' }}>
      <div>
        {reportingManager === null ? (
        <Text type="danger">Not assigned</Text>
        ) : (
        <div style={{ display: 'flex', alignItems: 'center', marginTop: '8px' }}>
          <Avatar style={{ backgroundColor: '#4295a5', marginRight: '8px' }}>
            {getNameInitials(reportingManager?.reporting_person)}
          </Avatar>
          <Text>{reportingManager?.reporting_person}</Text>
        </div>
        )}
      </div>
      <div style={{ float: 'right',marginTop:'-27px' }}>





        <Dropdown overlay={menu} 
        
        trigger={['click']}>
          <Button type="text" style={{padding: 0, border: 'none', color:'#2973f0' }}>
          {reportingManager?.reporting_person ? ( <b> Edit </b>) : (<b>Add</b>)}
          </Button>
        </Dropdown>

        {/* <Dropdown overlay={<Menu items={reportingManagers} onClick={handleMenuClick} />}
        onOpenChange={handleOpenChange}
        open={open}
        style={{ float: 'right' }}
        >
        {reportingManager?.reporting_person ? (
        <Space style={{color:"#2973f0"}} onClick={(e)=> e.preventDefault()}>Edit</Space>

        ) : (
        <Space style={{color:"#2973f0"}} onClick={(e)=> e.preventDefault()}>Add</Space>
        )}

        </Dropdown> */}

      </div>
    </div>
  </div>
  {reportingManager ? (
  <div>
    <Text strong style={{color:"#6c7c8c"}}>Secondary</Text>
    <div style={{ display: 'flex', alignItems: 'center', marginTop: 8 }}>
      <Avatar style={{ backgroundColor: '#4295a5', marginRight: '8px', marginTop: '8px' }}>
        {getNameInitials(reportingManager?.reporting_to)}
      </Avatar>
      <Text style={{ marginLeft: 1 }}>{reportingManager?.reporting_to}</Text>
    </div>
  </div>
  ) : (
  <div>
    <Text strong>Reporting to</Text>
    <div style={{ display: 'flex', alignItems: 'center', marginTop: 8 }}>
      
      <Text type="danger">Not assigned</Text>
    </div>
  </div>
  )}
</Card>
);
};

export default ReportingCard;